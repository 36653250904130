.Admin-chitgroup-main {
    display: flex;
    height: 100vh;
}

.Ad-chitgroup-full {
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    /* overflow: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-chitgroup-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 82vw; 
}

.Ad-chitgroup-heading h2{
    font-size: 30px;
}

.Ad-chitgroup-AC-1{
    margin-top: 20px;
    width:82vw;
    height:40px;
    padding-top: 7px;
    justify-content: flex-end;
    text-align: end;
    align-items: flex-end;
    /* justify-content: space-between; */
}

.create-course{
    background-color: rgb(33, 33, 136);
    width:90px;
    height:35px;
    border:rgb(33, 33, 136);
    border-radius: 5px;
    color:white;
   cursor: pointer;
}

.create-course:hover{
    color:white;
    text-decoration: none;
    transform: scale(1.1);
}

.Ad-chitgroup-course-card{
    margin-top: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.Ad-chitgroup-full-card{
    width: 360px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border: 1px solid rgb(219, 204, 204);
    margin: 20px;
}
.Ad-chitgroup-full-card:hover{
    transform: scale(1.05) translateY(8px);
    transition: 0.3s;
}

.Ad-chitgroup-card-chitgroup{
    width: auto;
    height: 200px;
    display: flex;
    /* border:1px solid linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);  */
 /* border-top: 15px solid #05144c; */
    border-bottom: 20px solid white;
    flex-direction: column;
    cursor: pointer;
    background-image: url("../images/Nameimage.jpg");
    /* background: linear-gradient(to top right, #d5dbde 0%, #efedef 50%); */
   
}

.Ad-chitgroup-logo-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Ad-chitgroup-share{
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
 
}

.Ad-chitgroup-batch{
    align-self: center;
    margin-top: 35px;
    text-decoration: none;
    color: rgb(69, 68, 68);
}

.Ad-chitgroup-batch:hover{
    text-decoration: none;
    color:black;
    transform: scale(1.05) translatex(8px);
    transition: 0.3s;
}

.Ad-chitgroup-batch-details{
    margin-top: 10px;
    font-size: 14px;
    color: grey;
    padding: 0px;
    margin-left: 5px;
}

.Ad-chitgroup-vg-1{
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 10px;
    /* text-align: center; */
}

.Ad-chitgroup-id{
    font-weight: 500;
    width:200px;
    margin:0px;
    color:black;
  
}

.Ad-chitgroup-bottom-icons{
    color: black;
    font-size: 18px;
    display: flex;
    margin: 10px 0;
    justify-content: space-around;
}

.Ad-chitgroup-card-icon{
    cursor: pointer;
}

.Ad-chitgroup-card-icon:hover{
    color: blue;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-chitgroup-heading h2{
        font-size: 20px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-full-card{
        width: 250px;
        margin-left: 7px;
    }
    
    .Ad-chitgroup-course-card{
        padding-left: 0px;
    }

    .Ad-chitgroup-vg-1{
        font-size: 16px;
        font-weight: 600;
    }
}

@media (360px <= width <= 414px) {
    .Ad-chitgroup-heading h2{
        font-size: 20px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-full-card{
        width: 280px;
        margin-left: 10px;
    }
    
    .Ad-chitgroup-course-card{
        padding-left: 0px;
    }

    .Ad-chitgroup-vg-1{
        font-size: 16px;
        font-weight: 600;
    }
}

@media (375px <= width <= 414px) {
    .Ad-chitgroup-heading h2{
        font-size: 20px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-full-card{
        width: 300px;
        margin-left: 10px;
    }
    
    .Ad-chitgroup-course-card{
        padding-left: 0px;
    }

    .Ad-chitgroup-vg-1{
        font-size: 16px;
        font-weight: 600;
    }
}

@media (414px <= width <= 568px) {
    .Ad-chitgroup-heading h2{
        font-size: 20px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-full-card{
        width: 310px;
        margin-left: 25px;
    }
    
    .Ad-chitgroup-course-card{
        padding-left: 0px;
    }

    .Ad-chitgroup-vg-1{
        font-size: 16px;
        font-weight: 600;
    }
}

@media (768px <= width <= 820px) {
}

@media (820px <= width <= 834px) {
    .create-course{
        margin-right: 80px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-head {
        width: 72vw; 
    }

    .Ad-chitgroup-full-card{
        /* width: 310px; */
        margin-left: -50px;
    }
}

@media (834px <= width <= 1194px) {
    .create-course{
        margin-right: 80px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-head {
        width: 72vw; 
    }

    .Ad-chitgroup-full-card{
        /* width: 310px; */
        margin-left: -50px;
    }
}

@media (800px <= width <= 834px) {
    .create-course{
        margin-right: 80px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-head {
        width: 72vw; 
    }

    .Ad-chitgroup-full-card{
        /* width: 310px; */
        margin-left: -50px;
    }
}

@media (884px <= width <= 1024px) {
    .create-course{
        margin-right: 80px;
    }

    .Admin-chitgroup-main {
        overflow-x: hidden;
    }

    .Ad-chitgroup-course-card{
        display: block;
    }
    
    .Ad-chitgroup-head {
        width: 72vw; 
    }

    .Ad-chitgroup-full-card{
        margin-left: 140px;
    }
}









.ttd{
    width:100%;
    height:100vh;
}
.courses1{
    /* width: 100%; */
    padding: 10px;
}
.courses1-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.create-course{
    background-color: rgb(33, 33, 136);
    width:90px;
    height:35px;
    border:rgb(33, 33, 136);
    border-radius: 5px;
    color:white;
   
}
.AC{
    display:flex;
    justify-content: space-between;
    width:82vw;
 
}
.AC-1{
    margin-top: 20px;
    width:82vw;
    height:40px;
    padding-top: 7px;
    justify-content: flex-end;
    text-align: end;
    align-items: flex-end;
    /* justify-content: space-between; */
}
 
.learn{
    color: blue;
    font-weight: bold;
    cursor: pointer;
}
.course-search{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search-bar{
    margin-top: 10px;
    padding: 10px;
    border: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 10px;
}
.search{
    width: 50%;
}
.filter{
    color: white;
    background-color: #05144c;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
}
.filter-icon{
    margin: 9px;
 
}
 
.course-card{
    margin-top: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
/* .card-chitgroup{
    width: auto;
    height: 180px;
    display: flex;
    border:1px solid linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);
    
    flex-direction: column;
    cursor: pointer;
    background: linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);
   
} */

.card-chitgroup{
    width: auto;
    height: 200px;
    display: flex;
    /* border:1px solid linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);  */
 /* border-top: 15px solid #05144c; */
    border-bottom: 20px solid white;
    flex-direction: column;
    cursor: pointer;
    background-image: url("../images/Nameimage.jpg");
    /* background: linear-gradient(to top right, #d5dbde 0%, #efedef 50%); */
   
}
.share{
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
 
}
.logo-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.makenow-logo{
    width: 70px;
    height: 30px;
}
.batch{
    align-self: center;
    margin-top: 35px;
    text-decoration: none;
    color: rgb(69, 68, 68);
}
.batch-details{
    margin-top: 10px;
    font-size: 14px;
    color: grey;
    padding: 0px;
    margin-left: 5px;
}
.id{
   font-weight: 500;
    width:200px;
    margin:0px;
    color:black;
 
}
 
.bottom-icons{
    color: black;
    font-size: 18px;
    display: flex;
    margin: 10px 0;
    justify-content: space-around;
}
.card-icon{
    cursor: pointer;
}
.card-icon:hover{
    color: blue;
}
.full-card{
    width: 360px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border: 1px solid rgb(219, 204, 204);
    margin: 20px;
}
.full-card:hover{
    transform: scale(1.05) translateY(8px);
    transition: 0.3s;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .modal {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
  }
 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .batch:hover{
    text-decoration: none;
    color:black;
    transform: scale(1.05) translatex(8px);
    transition: 0.3s;
  }
  .modal-content {
    margin-top: 10px;
  }
  .vg-1{
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 10px;
    /* text-align: center; */
  }
  .create-course:hover{
    color:white;
    text-decoration: none;
    transform: scale(1.1);
  }
   
 
  /* Additional styles can be added as needed */
 
@media screen and (max-width:769px){
    /* .courses-head{
        flex-direction: column;
        padding-left: 0PX;
    } */
    .full-card{
        margin-left: 10px;
    }
    /* .courses1-head{
        margin-right: 40px;
    } */
    .course-card{
        padding-left: 0px;
    }
    .ttd{
        overflow-x: hidden;
    }

    .vg-1{
        font-size: 16px;
        font-weight: 600;
    }
 
}
 
@media (768px <= width <= 1024px) {
    .AC{
        display: flex;
        justify-content: space-between;
        width: 70vw;
        margin-top: 0px;
    }
 
    .AC-1{
        margin-top: 20px;
        width:72vw;
        height:40px;
        /* justify-: space-between; */
    }
}
 