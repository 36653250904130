

 
.sg-image-container {
  position: relative;
  border:1px solid rgb(203, 199, 199);
  border-radius: 5px;
  box-shadow: 0px 5px 5px 2px rgb(206, 204, 204);
  width:300px;
  
  background-color: rgb(174, 183, 223);
  height:200px;
}

.sg-heading {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
}

.sg-image-text {
  position: absolute;
  top: calc(100% - 3px);
  font-size: 18px;
  margin-left: 70px;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  color:#05144c;
  margin-top: -40px;
}

.sg-image-textcg {
  position: absolute;
  top: calc(100% - 3px);
  font-size: 18px;
  margin-left: 70px;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  color:#05144c;
  margin-top: -40px;
}

.sg-image {
  width:180px;
  height:120px;
  margin-left: 60px;
  margin-top:15px;
}

.sg-col {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 10px;
}

.sg-image-container:hover {
  /* opacity: .7; */
  transform: scale(1.1);
}

.sg-image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sg-container {
  padding-top: 50px;
  /* padding-bottom: 170px; */
  padding-left: 100px;

}

.sg-heading {
  padding-top: 50px;
  padding-left: 50px;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .sg-image-container {
    width:280px;
    height:180px;
    margin-left:-80px;
  }
 
  .sg-col {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    padding: 20px;
  }
  .sg-container {
    padding-left: 100px;
    padding-right: 50px;
  }

  .sg-image {
    margin-left: 50px;
  }

  .sg-image-text {
    font-size: 18px;
    margin-left: 80px;
  }
 
  .sg-heading {
    padding-left: 20px;
    margin-right: 20px;
  }
}

@media (360px <= width <= 414px) {
  .sg-image-container {
    width:280px;
    height:180px;
    margin-left:-60px;
  }
 
  .sg-col {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    padding: 20px;
  }
  .sg-container {
    padding-left: 100px;
    padding-right: 50px;
  }

  .sg-image {
    margin-left: 50px;
  }

  .sg-image-text {
    font-size: 18px;
    margin-left: 80px;
  }
 
  .sg-heading {
    padding-left: 20px;
    margin-right: 20px;
  }
}

@media (375px <= width <= 414px) {
  .sg-image-container {
    width:280px;
    height:180px;
    margin-left:-50px;
  }
 
  .sg-col {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    padding: 20px;
  }
  .sg-container {
    padding-left: 100px;
    padding-right: 50px;
  }

  .sg-image {
    margin-left: 50px;
  }

  .sg-image-text {
    font-size: 18px;
    margin-left: 80px;
  }
 
  .sg-heading {
    padding-left: 20px;
    margin-right: 20px;
  }
}

@media (414px <= width <= 568px) {
  .sg-image-container {
    width:300px;
    height:200px;
    margin-left:-40px;
  }
 
  .sg-col {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    padding: 20px;
  }
  .sg-container {
    padding-left: 100px;
    padding-right: 50px;
  }

  /* .sg-image {
    margin-left: 50px;
  } */

  .sg-image-text {
    font-size: 18px;
    margin-left: 100px;
  }
 
  .sg-heading {
    padding-left: 20px;
    margin-right: 20px;
  }
}

@media (768px <= width <= 820px) {}

@media (820px <= width <= 834px) {}

@media (834px <= width <= 1194px) {}

@media (800px <= width <= 834px) {}

@media (884px <= width <= 1024px) {}



@media (428px <= width <= 926px){
  .sg-image-container {
    width:300px;
    height:200px;
    margin-left:-30px;
  }
}

@media (768px <= width <= 1024px) {
  .sg-container {
    margin-left: -100px;;
  }

  .sg-col {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    padding: 20px;
  }
 
  .sg-heading {
    padding-left: 0;
    margin-top: 100px;
  }
  .sg-image-container {
    /* display: block; */
    width: 220px;
    margin-left: 20px;
    padding: 50px;
    height:230px;
  }
  .sg-image-text{
    text-align: center;
    margin-left: -10px;
    /* margin-top: -30px; */

  }
  .sg-image-textcg{
    text-align: center;
    margin-left: -30px;
    margin-top: -40px;

  }
 
  .sg-image-container img {

    margin-left: -0px;
    margin-top: -20px; 
  }
}
