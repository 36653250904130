.agent-profile {
  display: flex;
}

.agent-profile-content {
  width: 100%;
  height: 100vh;
}

.agent-profile-heading h2 {
  font-size: 30px;
  font-weight: 500;
  margin-top: 10px;
}

.agent-profile-avatar {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}

.agent-profile-edit-link {
  text-decoration: underline;
  font-weight: 500;
}

.agent-profile-main {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 1100px;
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.agent-profile-column {
  width: 48%;
}

.agent-profile-details {
  padding: 10px;
}

.agent-profile-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.agent-profile-label {
  font-weight: bold;
  width: 30%;
}

.agent-profile-input {
  width: 65%;
  height: 38px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  outline: none;
  color: #000;
}

@media screen and (max-width: 1024px) {
  .agent-profile-main {
    flex-direction: column;
   
  }

  .agent-profile-column {
    width: 100%;
    margin-bottom: 00px;
  }

  .agent-profile-input {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .agent-profile-avatar {
    height: 100px;
    width: 100px;
  }

  .agent-profile-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .agent-profile-label {
    width: 100%;
    margin-bottom: 5px;
  }

  .agent-profile-input {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .agent-profile-info {
    flex-direction: column;
    align-items: center;
    /* margin-left: 20%; */
  }
 .agent-profile-input
 {
  width: 250px;
 }

  .agent-profile-column {
    width: 100%;
    margin-bottom: 0px;
  }

  .agent-profile-avatar {
    height: 100px;
    width: 100px;
  }

  .agent-profile-label {
    width: 100%;
  }

  .agent-profile-input {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 359px) {  
  .agent-profile-heading h2
  {
    font-size: 20px;
    text-align: center;
  }
  .agent-profile {
    display: flex;
    overflow-x: hidden;

  }
  .agent-profile-info {
    flex-direction: column;
    align-items: center;
    /* margin-left: 20%; */
    overflow-x: hidden;
    width: 100%;
  }

  .agent-profile-column {
    width: 100%;
    margin-bottom: -20px;
    overflow-x: hidden;
  }

  .agent-profile-avatar {
    height: 100px;
    width: 100px;
    overflow-x: hidden;
  }
  .Pr-p{
 
    font-size: smaller;
    margin-left: 5px;
    overflow-x: hidden;

  }
  .PR{
  width: 100%;
  overflow-x: hidden;
  }
  .agent-profile-label {
    width: 100%;
    overflow-x: hidden;
  }

  .agent-profile-input {
    width: 230px;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 360px) {
  .agent-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
   
}

}
@media only screen and (max-width: 712px) {
  .agent-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
}
  @media only screen and (max-width: 412px) {
    .agent-profile-main {
      flex-direction: column;
      width: 100%;
      overflow-x: hidden;
     
  }
 
}
@media only screen and (max-width: 600px) {
  .agent-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
}

}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .agent-profile-info {
    flex-direction: column;
    align-items: center;
    /* margin-left: 20%; */
  }
 .agent-profile-input
 {
  width: 250px;
 }

  .agent-profile-column {
    width: 100%;
    margin-bottom: -20px;
  }

  .agent-profile-avatar {
    height: 100px;
    width: 100px;
  }

  .agent-profile-label {
    width: 100%;
    /* margin-left: 20%; */
  }

  .agent-profile-input {
    width: 70%;
  }

}
@media screen and (min-width: 800px) and (max-width: 1280px) {
  .agent-profile-info {
    flex-direction: column;
    align-items: center;
    /* margin-left: 20%; */
  }
  .agent-profile-input
  {
   width: 250px;
  }

  .agent-profile-column {
    width: 100%;
    margin-bottom: -20px;
  }

  .agent-profile-avatar {
    height: 100px;
    width: 100px;
  }

  .agent-profile-label {
    width: 100%;
    /* margin-left: 20%; */
  }

  .agent-profile-input {
    width: 70%;
  }

}
