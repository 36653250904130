
.Arrow{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
  }.xc{
    margin-left: 150px;
}
.table-w1{
    max-height:400px;
    overflow-y: scroll;
    width: 900px;
    margin:20px;
    overflow-x: auto; /* Enable horizontal scrolling */


}

.Agent-payout-main {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}



.Ad-payout-display-404 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 300px;
    padding: 5px;
}

.hk-agent{
    display: flex;
    gap: 8rem;
  }
@media screen and (max-width: 769px){
    .xc{
        margin-left: 0px;
    }
    .table-w1{
        width: 150px;
   
    }
}

@media screen and (max-width: 1280px){
    .table-w1{
        width: 350px;
   
    }
}