.collectingagent-editprofile{
    width: 100%;
    height: 100vh;
    display: flex;
}

.CA-totalm{
  width: 100%;
  /* flex-grow: 1; */
  padding: 10px;
  overflow-x: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 97vh;
}

.CA-h {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

.CA-ume{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
}

.CA-AI{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    /* width: 80vw;  */
}

.CA-editprofile-heading h2{
    font-size: 30px;
}

.CA-editprofile-container{
    width:75vw;
    margin-top: 25px;
    /* border:1px solid black; */
  
}

.CA-ab{
    margin: 0px;
}

.CA-profileedit{
    display: flex;
}

.CA-display-0{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}

.CA-input-0{
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

.CA-usavebutton{
    border: none;
    margin-left: 530px;
    width: 90px;
    height: 35px;
    background-color: #19ab58;
    color: white;
    cursor: pointer;
}

.CA-usavebutton:hover{
    transform: scale(1.2);
    border: none;
    outline: none;
}

.CA-usavebutton:active{
    border: none;
    outline: none;
}

.CA-usavebutton:focus{
    border: none;
    outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .CA-editprofile-heading h2{
        font-size: 15px;
    }

    .CA-ume{
        margin-top: -10px;
        margin-left: 0px;
        margin-right: 2px;
        font-size: 15px;
    }

    .CA-profileedit{
        display: block;
    }
    
    .CA-display-0{
        display: flex;
        width: 550px;;
        margin-left: 0px;
    }
    
    .CA-input-0{
        width: 230px;
    }

    .CA-usavebutton{
        margin-left: 80px;
    }
}

@media (360px <= width <= 414px) {
    .CA-editprofile-heading h2{
        font-size: 20px;
    }

    .CA-ume{
        margin-top: 0px;
        font-size: 18px;
    }

    .CA-profileedit{
        display: block;
    }
    
    .CA-display-0{
        margin-left: 30px;
    }
    
    .CA-input-0{
        width: 230px;
    }

    .CA-usavebutton{
        margin-left: 100px;
    }
}

@media (375px <= width <= 414px) {
    .CA-editprofile-heading h2{
        font-size: 20px;
    }

    .CA-ume{
        margin-top: 0px;
        font-size: 18px;
    }

    .CA-profileedit{
        display: block;
    }
    
    .CA-display-0{
        margin-left: 40px;
    }
    
    .CA-input-0{
        width: 230px;
    }

    .CA-usavebutton{
        margin-left: 100px;
    }
}

@media (414px <= width <= 568px) {
    .CA-editprofile-heading h2{
        font-size: 20px;
    }

    .CA-ume{
        margin-top: 0px;
        font-size: 18px;
    }

    .CA-profileedit{
        display: block;
    }
    
    .CA-display-0{
        margin-left: 40px;
    }
    
    .CA-input-0{
        width: 230px;
    }

    .CA-usavebutton{
        margin-left: 100px;
    }
}
