.admin-mecontainer{
    display: flex;
    height: 100vh;
}

/* .Ad-memberentry-p{
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
} */

.Ad-totalm{
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

.Ad-courses-head-me{
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Ad-memberentry-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    /* width: 80vw;  */
}

.Ad-me-heading h2 {
    font-size: 30px;
}

.Ad-member-container{
    width:75vw;
    margin-top: 20px;
}

.Ad-memberentry-container{
    margin-top: 30px;
}

.Ad-member {
    display: flex;
}

.Ad-display-02 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}

.Ad-input-02 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

.Ad-me-sbutton{
    width: 80vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    border: none;
}

.Ad-me-cdf {
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
    /* border: 4px solid black; */
}

.Ad-me-cdf:hover{
    /* border: 4px solid black; */
    transform: scale(1.1);
    border: none;
    outline: none;
}
  
.Ad-me-cdf:active{
    border: none;
    outline: none;
}

.Ad-me-cdf:focus{
    border: none;
    outline: none; 
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-me-heading h2 {
        font-size: 18px;
    }

    .Ad-totalm{
        overflow-x: hidden;
      }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 10px;
    }
    
    .Ad-input-02 {
        width: 220px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-me-heading h2 {
        font-size: 18px;
    }

    .Ad-totalm{
        overflow-x: hidden;
      }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 30px;
    }
    
    .Ad-input-02 {
        width: 220px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-me-heading h2 {
        font-size: 18px;
    }

    .Ad-totalm{
        overflow-x: hidden;
      }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 40px;
    }
    
    .Ad-input-02 {
        width: 220px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-me-heading h2 {
        font-size: 18px;
    }

    .Ad-totalm{
        overflow-x: hidden;
      }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 60px;
    }
    
    .Ad-input-02 {
        width: 220px;
    }
}

@media (768px <= width <= 820px) {

    .Ad-totalm{
        overflow-x: hidden;
      }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 170px;
    }
    
    .Ad-input-02 {
        width: 300px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-totalm{
        overflow-x: hidden;
    }

    .Ad-courses-head-me{
        width: 70vw;
    }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 120px;
    }
    
    .Ad-input-02 {
        width: 300px;
    }

    .Ad-me-cdf {
       margin-right: 140px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-totalm{
        overflow-x: hidden;
    }

    .Ad-courses-head-me{
        width: 70vw;
    }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 120px;
    }
    
    .Ad-input-02 {
        width: 300px;
    }

    .Ad-me-cdf {
       margin-right: 140px;
    }
}

@media (800px <= width <= 834px) {
    .Ad-totalm{
        overflow-x: hidden;
    }

    .Ad-courses-head-me{
        width: 70vw;
    }

    .Ad-member {
        display: block;
    }

    .Ad-display-02 {
        margin-left: 120px;
    }
    
    .Ad-input-02 {
        width: 300px;
    }

    .Ad-me-cdf {
       margin-right: 140px;
    }
}

@media (884px <= width <= 1024px) {}

