.Bidde-search {
  width: 700px;
  height: 40px;
  border-radius: 20px;
  margin-top: 20px;
  margin-left: 20px;
}
.super-Bidde-searchContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.super-Bidde-Action-page {
  width: 100%;  
  overflow-x: hidden;
}
.super-Bidde-courses-head-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px;
}
.super-Bidde-Action-profile-set {
    display: flex;
    margin-right: 40px;
    gap:10px
}
 
.super-Bidde-table-auction{
  max-height:400px;
  overflow-y: scroll;
  width: 980px;
  /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
  box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
  /* border: 1px solid black; */
  margin:20px;
  margin-top: 50px;
  margin-left: 150px;
  overflow-x: auto;
  border: none;
  border-collapse: collapse;

}
th,td{
  border-collapse: collapse;
  /* border:1px solid rgba(82, 80, 80.0.5); */
  padding:10px;
}

.super-Bidde-table-auction th {
  border-collapse: collapse;
  border: none;
}

.super-Bidde-table-auction td{
  border-collapse: collapse;
}

.super-Bidde-table-style {
  border-collapse: collapse;
  border: none;
}
.outer-wrppr{
  /* border:1px solid black; */
  box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
  border-radius: 5px;
  max-width:1020px;
  margin-top: 60px;
  margin-left:150px;
 
}
.gtf{
  display:flex;
  gap:3rem;
}

@media (300px <= width <= 600px){
 
  .super-Bidde-table-auction{
      /* width: 550px; */
      margin:20px;
      margin-top: 40px;
      margin-left: 15px;
  }

}

@media (768px <= width <= 1024px){
 
.super-Bidde-table-auction{
      width: 530px;
      margin:20px;
      margin-top: 50px;
      margin-left: 30px;
  }
  .super-Bidde-searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.Bidde-search {
  width: 50%;
  height: 40px;
  border-radius: 20px;
  margin-top: 20px;
  margin-left: 20px;
}
}


@media only screen and (max-width: 360px) {
.super-Bidde-table-auction{
  width: auto;
  margin:20px;
  margin-top: 40px;
  margin-left: 15px;
}
.super-dash-heading1 h2
{
font-size: 20px;
margin-top: -5px;

}
}
@media only screen and (max-width: 712px) {
.super-Bidde-table-auction{
  width: 300px;
  margin:20px;
  margin-top: 40px;
}
}
@media only screen and (max-width: 412px) {
  .super-Bidde-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
  .super-dash-heading1 h2
{
font-size: 20px;
margin-top: -5px;

}

}
@media only screen and (max-width: 393px) {
.super-Bidde-table-auction{
  width: 300px;
  margin:20px;
  margin-top: 40px;
}
.super-dash-heading1 h2
{
font-size: 20px;
margin-top: -3px;

}

}
@media only screen and (max-width: 600px) {
.super-Bidde-table-auction{
  /* width: 550px; */
  margin:20px;
  margin-top: 40px;
}

}
@media only screen and (max-width: 320px) {
.super-Bidde-table-auction{
  width: 230px;
  margin:20px;
  margin-top: 40px;
}

}
