.Admin-payout-main {
    display: flex;
    height: 100vh;
    width: 100%;
}

.Ad-payoutadmin-full{
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    /* overflow: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-payoutadmin-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-payoutadmin-heading h2{
    font-size: 30px;
}

.Ad-payout-hk{
    display: flex;
}

.Ad-payout-display-44 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}

.Ad-payout-input-44 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

.Ad-payout-button-66 {
    background-color: #19ab58;
    width: 150px;
    padding: 10px 0px 10px 0px;
    color: whitesmoke;
    transition: none; 
    border: none;
    outline: none;
    border-radius: 5px;
    margin-top: 50px;
    margin-left: 500px;
}

.Ad-payout-button-66:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.Ad-payout-button-66:active{
    border: none;
    outline: none;
}

.Ad-payout-button-66:focus{
    border: none;
    outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-payoutadmin-heading h2{
        font-size: 20px;
    }

    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 10px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 230px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 60px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-payoutadmin-heading h2{
        font-size: 20px;
    }

    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 10px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 260px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 70px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-payoutadmin-heading h2{
        font-size: 20px;
    }

    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 10px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 290px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 80px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-payoutadmin-heading h2{
        font-size: 20px;
    }

    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 20px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 310px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 100px;
    }
}

@media (768px <= width <= 820px) {
    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 190px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 310px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 260px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }

    .Ad-payoutadmin-head {
        width: 70vw; 
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 120px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 310px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 190px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }

    .Ad-payoutadmin-head {
        width: 70vw; 
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 120px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 310px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 190px;
    }
}

@media (800px <= width <= 834px) {
    .Ad-payoutadmin-full{
        overflow-x: hidden;
    }

    .Ad-payoutadmin-head {
        width: 70vw; 
    }
    
    .Ad-payout-hk{
        display: block;
    }
    
    .Ad-payout-display-44 {
        margin-left: 120px;
        padding: 5px;
    }
    
    .Ad-payout-input-44 {
        width: 310px;
    }
    
    .Ad-payout-button-66 {
        margin-top: 50px;
        margin-left: 190px;
    }
}

@media (884px <= width <= 1024px) {}