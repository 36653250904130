.lg-footer{
  /* border: 1px solid black; */
  width: 100%;
  background-color: #05144c;
  padding: 20px;
  /* padding-top: 0px; */
  color: whitesmoke;
  font-family: Arial, sans-serif;
}

.lg-footer-section {
  display: flex;
  flex-direction: column;
  margin: 10px;
  /* margin-top: 0px; */
  /* align-items: center; */
  justify-content: center;
}

.lg-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.lg-logo img {
  width: 200px;
  height: 200px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.lg-text-3 {
  font-size: 20px;
  color: whitesmoke;
  margin-left: 40px;
  margin-top: -60px;
  align-items: center;
}

.lg-text-auction {
  display: flex;
  gap: 10rem;
  /* justify-content: space-around; */
  width: 100%;
  margin: 45px;
  /* margin-bottom: 20px; */
  
}

.lg-chit-plan {
  flex: 1;
  text-align: left;
}

.lg-chit-plan h4 {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: whitesmoke;
  /* margin: 10px 0; */
}

.lg-chit-plan p {
  font-size: 18px;
  color: whitesmoke;
  /* margin: 10px 0; */
}

.lg-text-sect {
  text-align: left;
  flex: 1;
}

.lg-wise-1 {
  font-size: 22px;
  color: whitesmoke;
  margin-top: -18px;
  margin-bottom: 20px;
}

.lg-text-plan {
  display: flex;
  flex-direction: column;
  /* margin: 10px 0px; */
}

/* Responsive */

@media (320px <= width <= 375px) {
  .lg-logo {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .lg-logo img {
    width: 150px;
    height: 150px;
    margin-left: 30px;
    margin-top: -20px;
  }
  
  .lg-text-3 {
    margin-left: 50px;
    margin-top: -45px;
    align-items: center;
  }

  .lg-text-auction {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 40px;
    font-size: 18px;
  }
  
  .lg-chit-plan p {
    font-size: 18px;
    margin-right: 80px;
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 20px;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .lg-text-plan p{
    font-size: 18px;
    margin-right: 80px ;
    margin-top: -5px;
    text-align: center;
  }
}

@media (360px <= width <= 414px) {}

@media (375px <= width <= 414px) {
  .lg-logo {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .lg-logo img {
    width: 150px;
    height: 150px;
    margin-left: 30px;
    margin-top: -20px;
  }
  
  .lg-text-3 {
    margin-left: 50px;
    margin-top: -45px;
    align-items: center;
  }

  .lg-text-auction {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  /* .lg-chit-plan {
    flex: 1;
    text-align: center;
  } */

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 70px;
    font-size: 18px;
  }
  
  .lg-chit-plan p {
    font-size: 18px;
    margin-right: 80px;
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 20px;
    margin-top: 10px;
    margin-left: 75px;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .lg-text-plan p{
    font-size: 18px;
    margin-right: 80px ;
    margin-top: -5px;
    text-align: center;
  }
}

@media (414px <= width <= 568px) {
  .lg-logo {
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .lg-logo img {
    width: 150px;
    height: 150px;
    margin-left: 30px;
    margin-top: -20px;
  }
  
  .lg-text-3 {
    margin-left: 50px;
    margin-top: -45px;
    align-items: center;
  }

  .lg-text-auction {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 70px;
    font-size: 18px;
  }
  
  .lg-chit-plan p {
    font-size: 18px;
    margin-right: 80px;
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 20px;
    margin-top: 10px;
    margin-left: 75px;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .lg-text-plan p{
    font-size: 18px;
    margin-right: 80px ;
    margin-top: -5px;
    text-align: center;
  }
}

@media (768px <= width <= 820px) {
  .lg-text-3 {
    margin-left: 80px;
    margin-top: -60px;
    align-items: center;
    margin-bottom: 20px;
  }

  .lg-text-auction {
    display: flex;
    /* flex-direction: column; */
    text-align: center;
    gap: 6rem;
    margin-left: -50px;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 60px;
    margin-top: 5px;
    font-size: 17px;
  }
  
  .lg-chit-plan p {
    font-size: 15px;
    /* margin-left: 10px; */
    /* margin-right: 80px; */
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 17px;
    margin-top: -5px;
    margin-left: -30px;
    /* margin-left: 50px; */
    margin-bottom: 25px;
  }

  .lg-text-plan p{
    font-size: 15px;
    margin-top: 5px;
    margin-left: -180px;
  }
  
  .lg-chit-plannews {
    margin-left: -150px;
  }

  /* .lg-text-sect {
    margin-left: -140px;
  } */
}

@media (820px <= width <= 834px) {
  .lg-text-3 {
    margin-left: 80px;
    margin-top: -60px;
    align-items: center;
    margin-bottom: 20px;
  }

  .lg-text-auction {
    display: flex;
    /* flex-direction: column; */
    text-align: center;
    gap: 6rem;
    margin-left: -50px;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 60px;
    margin-top: 5px;
    font-size: 17px;
  }
  
  .lg-chit-plan p {
    font-size: 15px;
    margin-left: 10px;
    /* margin-right: 80px; */
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 17px;
    margin-top: -5px;
    margin-left: -100px;
    /* margin-left: 50px; */
    margin-bottom: 25px;
  }

  .lg-text-plan p{
    font-size: 15px;
    margin-top: 5px;
    margin-left: -160px;
  }
  
  .lg-chit-plannews {
    margin-left: -150px;
  }

  .lg-text-sect h4{
    margin-left: -155px;
  }
}

@media (834px <= width <= 1194px) {
  .lg-text-3 {
    margin-left: 80px;
    margin-top: -60px;
    align-items: center;
    margin-bottom: 20px;
  }

  .lg-text-auction {
    display: flex;
    /* flex-direction: column; */
    text-align: center;
    gap: 6rem;
    margin-left: -50px;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 60px;
    margin-top: 5px;
    font-size: 17px;
  }
  
  .lg-chit-plan p {
    font-size: 15px;
    margin-left: 10px;
    /* margin-right: 80px; */
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 17px;
    margin-top: 5px;
    margin-left: -100px;
    /* margin-left: 50px; */
    margin-bottom: 25px;
  }

  .lg-text-plan p{
    font-size: 15px;
    margin-top: 5px;
    margin-left: -160px;
  }
  
  .lg-chit-plannews {
    margin-left: -150px;
  }

  .lg-text-sect h4{
    margin-left: -155px;
  }
}

@media (800px <= width <= 834px) {
  .lg-text-3 {
    margin-left: 80px;
    margin-top: -60px;
    align-items: center;
    margin-bottom: 20px;
  }

  .lg-text-auction {
    display: flex;
    /* flex-direction: column; */
    text-align: center;
    gap: 6rem;
    margin-left: -50px;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 60px;
    margin-top: 5px;
    font-size: 17px;
  }
  
  .lg-chit-plan p {
    font-size: 15px;
    margin-left: 10px;
    /* margin-right: 80px; */
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 17px;
    margin-top: 5px;
    margin-left: -100px;
    /* margin-left: 50px; */
    margin-bottom: 25px;
  }

  .lg-text-plan p{
    font-size: 15px;
    margin-top: 5px;
    margin-left: -160px;
  }
  
  .lg-chit-plannews {
    margin-left: -150px;
  }

  .lg-text-sect h4{
    margin-left: -155px;
  }
}

@media (884px <= width <= 1024px) {
  .lg-text-3 {
    margin-left: 80px;
    margin-top: -60px;
    align-items: center;
    margin-bottom: 20px;
  }

  .lg-text-auction {
    display: flex;
    /* flex-direction: column; */
    text-align: center;
    gap: 6rem;
    margin-left: -50px;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 60px;
    margin-top: 5px;
    font-size: 17px;
  }
  
  .lg-chit-plan p {
    font-size: 15px;
    margin-left: 25px;
    /* margin-right: 80px; */
    text-align: center;
  }

  .lg-wise-1 {
    font-size: 17px;
    margin-top: 5px;
    margin-left: -100px;
    /* margin-left: 50px; */
    margin-bottom: 25px;
  }

  .lg-text-plan p{
    font-size: 15px;
    margin-top: 5px;
    margin-left: -140px;
  }
  
  .lg-chit-plannews {
    margin-left: -150px;
  }

  .lg-text-sect h4{
    margin-left: -135px;
  }
}



@media (428px <= width <= 926px) {
  .lg-logo img {
    margin-left: 40px;
  }
  
  .lg-text-3 {
    margin-left: 60px;
  }

  .lg-chit-plan h4 {
    display: flex;
    text-align: center;
    margin-left: 80px;
    font-size: 18px;
  }

  .lg-chit-plan p {
    font-size: 18px;
    margin-right: 165px;
    text-align: center;
  }

  .lg-wise-1 {
    margin-left: 85px;
  }

  .lg-text-plan p{
    font-size: 18px;
    margin-right: 155px ;
    margin-top: -5px;
    text-align: center;
  }

  .lg-text-auction{
    width:100vw;
    /* border:1px solid black; */
  }
  .lg-chit-plan{
    /* border:1px solid red; */
  }
  .lg-text-sect{
    /* border:1px solid yellow; */
  }

}



