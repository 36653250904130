.Admin-allusers-main {
  width: 100%;
  display: flex;
  height: 100vh;
}

.Ad-allusers-full {
  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  /* overflow: hidden; */
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.Ad-allusers-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 95%; 
}

.Ad-allusers-heading h2{
  font-size: 30px;
}

.Ad-table-allusers {
  max-height:400px;
  overflow-y: scroll;
  width: 870px;
  margin:20px;
  margin-top: 100px;
  margin-left: 140px;
  overflow-x: auto;
  box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ad-allusers-heading h2{
    font-size: 20px;
  }
  
  .Ad-table-allusers {
    width: 230px;
    margin-left: 10px;
  }
}

@media (360px <= width <= 414px) {
  .Ad-allusers-heading h2{
    font-size: 20px;
  }
  
  .Ad-table-allusers {
    width: 280px;
    margin-left: 10px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-allusers-heading h2{
    font-size: 20px;
  }
  
  .Ad-table-allusers {
    width: 300px;
    margin-left: 10px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-allusers-heading h2{
    font-size: 20px;
  }
  
  .Ad-table-allusers {
    width: 330px;
    margin-left: 10px;
  }
}

@media (768px <= width <= 820px) {
  .Ad-table-allusers {
    width: 540px;
    margin-left: 80px;
  }
}

@media (820px <= width <= 834px) {
  .Ad-table-allusers {
    width: 440px;
    margin-left: 80px;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-table-allusers {
    width: 460px;
    margin-left: 80px;
  }
}

@media (800px <= width <= 834px) {
  .Ad-table-allusers {
    width: 440px;
    margin-left: 70px;
  }
}

@media (884px <= width <= 1024px) {
  .Ad-table-allusers {
    width: 500px;
    margin-left: 80px;
  }
}









.in-9{
  width:100%;
  height: 100vh;
  /* border: 1px solid black; */
  margin-left: 5px;
  margin-top: 10px;
}
.info-h1{
  display:flex;

}

.alluserscss{
  width: 100%;
  height: 100vh;
}
.table-allusers{
   max-height:400px;
    overflow-y: scroll;
    width: 870px;
    margin:20px;
    margin-top: 100px;
    margin-left: 140px;
    overflow-x: auto;
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
}
.outer-w{
  
    border:1px solid black;
    box-shadow:0px 0px 3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:920px;
    margin-top: 60px;
    margin-left:150px;
  
}
@media (300px <= width <= 600px){
  .outer-w{
    margin-left: 10px;
    max-width: none;
    width: 310px;
    /* border:1px solid black; */
  }
  .table-allusers{
     /* border:1px solid black; */
     max-width: 290px;
     margin-left: 10px;

  }

}
@media (768px <= width <= 1024px){
  .outer-w{
    width:550px;
    margin-left: 30px;
  }
  .table-allusers{
       max-width:500px;
       margin-left: 50px;
  }
}