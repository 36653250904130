.Resp-profile-main {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow-y: hidden;
}

.Resp-profile-full{
    width:100%;
    height: 100vh;
    /* overflow-x: hidden; */
    flex-grow: 1;
    padding: 10px;
}

.Resp-profile-head{}

.Resp-profile-heading h2{
    font-size: 30px;
}

.Resp-profile-full {
    width: 100vh;
    /* margin-left: -30px; */
}

.Resp-display-001 {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-radius: 10px;
    margin-left: 60px;
    margin-top: -10px;
}

.Resp-pro {
    display: flex;
  }

.Resp-display-pro{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 80px;
    padding: 5px;
  }
  
.Resp-input-pro{
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Resp-profile-heading h2{
        font-size: 20px;
    }

    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: 5px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 0px;
      }
      
    .Resp-input-pro{
        width: 230px;
    }
}

@media (360px <= width <= 414px) {
    .Resp-profile-heading h2{
        font-size: 20px;
    }

    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: 20px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 0px;
      }
      
    .Resp-input-pro{
        width: 260px;
    }
}

@media (375px <= width <= 414px) {
    .Resp-profile-heading h2{
        font-size: 20px;
    }

    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: 30px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 0px;
      }
      
    .Resp-input-pro{
        width: 280px;
    }
}

@media (414px <= width <= 568px) {
    .Resp-profile-heading h2{
        font-size: 20px;
    }

    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: 50px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 0px;
      }
      
    .Resp-input-pro{
        width: 300px;
    }
}

@media (768px <= width <= 820px) {

    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: 5px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 210px;
        margin-top: 10px;
    }
      
    .Resp-input-pro{
        width: 300px;
    }
}

@media (820px <= width <= 834px) {
    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: -40px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 130px;
        margin-top: 10px;
    }
      
    .Resp-input-pro{
        width: 300px;
    }
}

@media (834px <= width <= 1194px) {
    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: -40px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 130px;
        margin-top: 10px;
    }
      
    .Resp-input-pro{
        width: 300px;
    }
}

@media (800px <= width <= 834px) {
    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: -40px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 130px;
        margin-top: 10px;
    }
      
    .Resp-input-pro{
        width: 300px;
    }
}

@media (884px <= width <= 1024px) {
    .Resp-profile-main {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .Resp-display-001 {
        margin-left: 20px;
    }
    
    .Resp-pro {
        display: block;
    }
    
    .Resp-display-pro{
        margin-left: 190px;
        margin-top: 10px;
    }
      
    .Resp-input-pro{
        width: 300px;
    }
}