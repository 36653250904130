.supro-profile {
  display: flex;
}

.supro-profile-content {
  width: 100%;
  height: 100vh;
}

.supro-profile-heading h2 {
  font-size: 30px;
  font-weight: 500;
  margin-top: 10px;
}

.supro-profile-avatar-container {
  text-align: center;
  margin-bottom: 20px;
}

.supro-profile-avatar {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.supro-profile-edit-link {
  text-decoration: underline;
  font-weight: 500;
}

.supro-profile-main {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 1100px;
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.supro-profile-column {
  width: 48%;
}

.supro-profile-details {
  padding: 10px;
}

.supro-profile-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.supro-profile-label {
  font-weight: bold;
  width: 30%;
}

.supro-profile-input {
  width: 65%;
  height: 38px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  outline: none;
  color: #000;
}

@media screen and (max-width: 1024px) {
  .supro-profile-main {
    flex-direction: column;
  }

  .supro-profile-column {
    width: 100%;
    margin-bottom: 20px;
  }

  .supro-profile-input {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .supro-profile-avatar {
    height: 100px;
    width: 100px;
  }

  .supro-profile-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .supro-profile-label {
    width: 100%;
    margin-bottom: 5px;
  }

  .supro-profile-input {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .supro-profile-info {
    flex-direction: column;
    align-items: center;
  }

  .supro-profile-column {
    width: 100%;
    margin-bottom: 20px;
  }

  .supro-profile-avatar {
    height: 100px;
    width: 100px;
  }

  .supro-profile-label {
    width: 100%;
  }

  .supro-profile-input {
    width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  .supro-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 712px) {
  .supro-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 412px) {
  .supro-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .supro-profile-main {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
}
