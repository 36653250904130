.Admin-add-existing-main {
  display: flex;
  width: 100%;
  height: 100vh;
}

.Ad-addexisting-full {
  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.Ad-addexisting-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw;
}

.Ad-addexisting-heading h2{
  font-size: 30px;
}

.Ad-addexisting-kh {
  display: flex;
}

.Ad-addexisting-display-003{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* margin-left: 60px; */
  padding: 5px;
}

.Ad-addexisting-input-003{
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ad-addexisting-vdt{
  margin-top: 70px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 80px;
}

.Ad-addexisting-vdt:hover{
  transform: scale(1.1);
}

.Ad-addexisting-ccc{
  width:100px;
  height:40px;
  background-color: #19ab58;
  border: #19ab58;
  border-radius: 5px;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ad-addexisting-heading h2{
    font-size: 20px;
  }

  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }
  
  .Ad-addexisting-input-003{
    width: 230px;
  }

  .Ad-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (360px <= width <= 414px) {
  .Ad-addexisting-heading h2{
    font-size: 20px;
  }

  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }
  
  .Ad-addexisting-input-003{
    width: 230px;
  }

  .Ad-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-addexisting-heading h2{
    font-size: 20px;
  }

  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }
  
  .Ad-addexisting-input-003{
    width: 270px;
  }

  .Ad-addexisting-vdt{
    margin-right: 30px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-addexisting-heading h2{
    font-size: 20px;
  }

  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }
  
  .Ad-addexisting-input-003{
    width: 300px;
  }

  .Ad-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (768px <= width <= 820px) {

  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }

  .Ad-addexisting-display-003{
    margin-left: 160px;
  }
  
  .Ad-addexisting-input-003{
    width: 320px;
  }

  .Ad-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (820px <= width <= 834px) {
  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-addexisting-head {
    width: 70vw;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }

  .Ad-addexisting-display-003{
    margin-left: 100px;
  }
  
  .Ad-addexisting-input-003{
    width: 320px;
  }

  .Ad-addexisting-vdt{
    margin-right: 60px;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-addexisting-head {
    width: 70vw;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }

  .Ad-addexisting-display-003{
    margin-left: 100px;
  }
  
  .Ad-addexisting-input-003{
    width: 320px;
  }

  .Ad-addexisting-vdt{
    margin-right: 50px;
  }
}

@media (800px <= width <= 834px) {
  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-addexisting-head {
    width: 70vw;
  }
  
  .Ad-addexisting-kh {
    display: block;
  }

  .Ad-addexisting-display-003{
    margin-left: 80px;
  }
  
  .Ad-addexisting-input-003{
    width: 320px;
  }

  .Ad-addexisting-vdt{
    margin-right: 70px;
  }
}

@media (884px <= width <= 1024px) {}









* {
  margin: 0px;
}
.h {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}

.add-existcss{
  width: 100%;
  height: 100vh;
  margin-left: 20px;
  overflow-x: hidden;
}
.std-add {
  width: 100%;
  height: 100vh;
  margin-left: -15px;
  overflow-x: hidden;
  /* border: 1px solid black; */
}

.addexist-heading h2{
  font-size: 30px;
  font-weight: 500;
}


.cx{
  margin-top: 10px;
  color: black;
  margin-right: 5px;
  /* font-size: 22px; */
}
 
.vdt{
    margin-top: 70px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.vdt:hover{
  transform: scale(1.1);
}
.ccc{
    width:100px;
    height:40px;
    background-color: #19ab58;
    border: #19ab58;
    border-radius: 5px;
}

.kh{
  display: flex;
}

.display-003{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* margin-left: 60px; */
    padding: 5px;
}

.display-030{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* margin-left: 60px; */
  padding: 5px;
}

.input-003{
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.display-008{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  /* margin-left: 480px; */
  padding: 5px;
}

.input-008{
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
  /* margin-left: 2px; */
}

@media screen and (max-width: 769px){
  
  .kh{
    display: block;
    margin-left: -50px;
  }

  .addexist-heading h2{
    font-size: 20px;
    margin-top: 7px;
  }

  .display-008{
    margin-left: 60px;
  }

  .display-030{
    margin-left: 10px;
  }
  
  .input-008{
    width: 240px;
    margin-left: 2px;
  }

  .input-003{
    width: 240px;
  }

  .std {
    width: 35vw;
    height: 100vh;
    margin-left: 0px;
    /* border: 1px solid black; */
  }
  /* .input-003{
    margin-left: 30px;
  } */
  .ae-msd{
    margin-left: 65px;

  }
}

@media (768px <= width <= 1024px) {
  .AU {
    display: flex;
    justify-content: space-between;
    width: 65vw;
    margin-top: 10px;
  }

  .kh{
    display: block;
    margin-left: 100px;
  }

  .display-030{
    margin-left: 100px;
  }

  .vdt{
    margin-right: 100px;
  }
}


