.admin-container-main{
    display: flex;
    height: 100vh;
}

.Ad-information-full{
  flex-grow: 1;
  padding: 10px;
  /* overflow: hidden; */
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

.Ad-information{
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.Ad-courses-head-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 80vw;
    margin-left: -20px;
    margin-top: -20px;
}

.Ad-h{
    /* margin-bottom: 1rem; */
    display: flex;
    flex-direction: row;
}

.Ad-ume{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
}

.Ad-information-heading h2{
    font-size: 30px;
    font-weight: 500;
}

.Ad-delete{
  justify-content: right;
  align-items: right;
  text-align: right;
  margin-right: 80px;
}

.Ad-delete-1{
    width:100px;
    height:30px;
    border-radius: 10px;
    background-color:rgb(243, 63, 63) ;
    border:none;
    color:White;
    margin-right: -35px;
}

.Ad-info-container{
  width:75vw;
  margin-top: 25px;
}

.Ad-info{
    display:flex;
    gap:3rem;
}

.Ad-display-01{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px;
}

.Ad-input-01{
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-information-heading h2{
        font-size: 20px;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: -10px;
    }
    
    .Ad-input-01{
        width: 230px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-information-heading h2{
        font-size: 20px;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: -10px;
    }
    
    .Ad-input-01{
        width: 250px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-information-heading h2{
        font-size: 20px;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: -10px;
    }
    
    .Ad-input-01{
        width: 280px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-information-heading h2{
        font-size: 20px;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: -10px;
    }
    
    .Ad-input-01{
        width: 310px;
    }
}

@media (768px <= width <= 820px) {

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: 180px;
    }
    
    .Ad-input-01{
        width: 300px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-courses-head-1{
        width: 70vw;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: 120px;
    }
    
    .Ad-input-01{
        width: 300px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-courses-head-1{
        width: 70vw;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: 120px;
    }
    
    .Ad-input-01{
        width: 300px;
    }
}

@media (800px <= width <= 834px) {
    .Ad-courses-head-1{
        width: 70vw;
    }

    .Ad-delete{
        margin-right: 20px;
    }

    .Ad-info{
        display:block;
    }

    .Ad-display-01{
        margin-left: 120px;
    }
    
    .Ad-input-01{
        width: 300px;
    }
}

@media (884px <= width <= 1024px) {}