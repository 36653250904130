.Ad-moreinfo-main{
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-moreinfo-full{
    width: 100%;
    /* flex-grow: 1; */
    padding: 10px;
    /* overflow-x: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
}

.Ad-moreinfo-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    /* width: 80vw;  */
}

.Ad-moreinfo-heading h2{
    font-size: 30px;
}

.Ad-boxes{
    /* border:1px solid black; */
    display:flex;
    margin:10px;
    padding: 10px;
    gap:3rem;
    justify-content: center;
    text-align: center;
   align-items: center;
}

.Ad-bnd{
    margin-top: 60px;
    width:350px;
    height:180px;
    /* border:1px solid black; */
    justify-content: center;
    text-align: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    /* background-color: rgba(237, 23, 23, 0.27); */
    /* border: rgba(237, 23, 23, 0.27); */
    box-shadow:3px 2px 2px 0px rgb(148, 184, 238);
    background-image: url("../images/newimagecard.jpg");
}

.Ad-po:hover{
    text-decoration: none;
}

.Ad-kok{
    color: black;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-moreinfo-head {
        width: 75vw; 
    }

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 20px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:250px;
        margin-left: -20px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-moreinfo-head {
        width: 75vw; 
    }

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 20px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:250px;
        margin-left: 0px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-moreinfo-head {
        width: 75vw; 
    }

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 20px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:260px;
        margin-left: 0px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-moreinfo-head {
        width: 75vw; 
    }

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 20px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:250px;
        margin-left: 30px;
    }
}

@media (768px <= width <= 820px) {

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 20px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:350px;
        margin-left: 150px;
    }
}

@media (820px <= width <= 834px) {

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 30px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:350px;
        margin-left: 100px;
    }
}

@media (834px <= width <= 1194px) {

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 30px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:350px;
        margin-left: 100px;
    }
}

@media (820px <= width <= 834px) {

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 30px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:350px;
        margin-left: 100px;
    }
}

@media (800px <= width <= 834px) {

    .Ad-moreinfo-full{
        overflow-x: hidden;
    }

    .Ad-moreinfo-heading h2{
        font-size: 30px;
    }

    .Ad-boxes{
        display:block;
    }

    .Ad-bnd{
        margin-top: 30px;
        width:350px;
        margin-left: 90px;
    }
}








.nb{
    margin: 10px;
    margin-left: 10px;
    width:100%;
}
/* .bnd{
    margin-top: 60px;
    width:350px;
    height:180px;
    border:1px solid black;
    justify-content: center;
    text-align: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    background-color: rgba(237, 23, 23, 0.27);
    border: rgba(237, 23, 23, 0.27);
    box-shadow:5px 5px 5px 3px seashell;
 
} */

.bnd{
    margin-top: 60px;
    width:350px;
    height:180px;
    /* border:1px solid black; */
    justify-content: center;
    text-align: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    /* background-color: rgba(237, 23, 23, 0.27); */
    /* border: rgba(237, 23, 23, 0.27); */
    box-shadow:3px 2px 2px 0px rgb(148, 184, 238);
    background-image: url("../images/newimagecard.jpg");
 
}
.boxes{
    /* border:1px solid black; */
    display:flex;
    margin:10px;
    padding: 10px;
    gap:3rem;
    justify-content: center;
    text-align: center;
    align-items: center;
 
}
.vg{
    margin-top: 10px;
}
.kok{
    color: black;
}
.po:hover{
    text-decoration: none;
   
}
 
@media (300px <= width <= 600px) {
    .boxes{
        /* border:1px solid black; */
        display:block;
        margin:10px;
        gap:3rem;
        justify-content: center;
        text-align: center;
        align-items: center;
   
    }
 
    .bnd{
        margin-top: 60px;
        width:300px;
        height:180px;
        border:1px solid black;
        justify-content: center;
        text-align: center;
        align-items: center;
        display:flex;
        flex-direction: column;
        background-color: rgba(237, 23, 23, 0.27);
        border: rgba(237, 23, 23, 0.27);
        box-shadow:5px 5px 5px 3px seashell;
   
    }
}
 
@media (768px <= width <= 1024px) {
    .boxes{
        /* border:1px solid black; */
        display:block;
   
    }
 
    .bnd{
        width:400px;
        height:230px;
        margin-left: 60px;
        /* border:1px solid black; */
   
    }
}