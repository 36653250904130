.book-pure {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.book-vgtdf {
  display: flex;
  flex-direction: column;
  width:345px;
  margin-top: 10px;
}
.book-courses-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 80vw;
    /* margin-top: 10px; */
    /* border: 1px solid black; */
}

.mpcdf{
  width: 90px;
  height: 35px;
  border-radius: 5px;
  margin-top: 50px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* transform: scale(1.1); */
}

.mpcdf:hover{
  transform: scale(1.1);
  border: none;
  outline: none;
}
.mpcdf:active{
  border: none;
  outline: none;
}
.mpcdf:focus{
  border: none;
  outline: none;
}
.M-i-2{
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.table-member{
  max-height:400px;
    overflow-y: scroll;
    width: 1100px;
    margin:20px;
    overflow-x: auto;
    margin-left: 80px;
}

@media screen and (max-width: 768px){
  .book-vgtdf {
    display: flex;
    flex-direction: column;
    width:300px;
    margin-top: 20px;
  }
 
  .M-i-2{
    margin-left: 20%;
    width: 220px;
  }

  .table-member{
    margin-left: 10px;
    width: 300px;
  }
  .book-info-23 {
   
    width: 100%;
}
}
@media (769px <= width <= 1024px){
  .book-vgtdf {
    display: flex;
    flex-direction: column;
    width:350px;
    margin-top: 20px;
    margin-right: 50px;
  }
  

  .table-member{
    width: 550px;
    margin-left: 20px;
  }

  .mpcdf{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
  }
  .book-info-23 {
   
    width: 100%;
}

}
 
.book-info-23 {
    margin-left: 10px;
    margin-top: 5px;
    width: 100%;
}
@media only screen and (max-width: 360px) {
    .book-info-23 {
   margin-right: 50%;
        width: 100%;
       
    }
    .book-pure{
        width: 100%;
    }
}
@media only screen and (max-width: 360px) {
  .bg-table{
    width: 300px;
    margin-left: 5%;
   
}
}
@media only screen and (max-width: 712px) {
  .bg-table{
    width: 550px;
    margin-left:25%;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .bg-table{
      width: 300px;
      margin-left: 5%;
     
  }
  
}
@media only screen and (max-width: 600px) {
  .bin-table-auction{
    width: 550px;
    margin:20px;
    margin-top: 40px;
  }

}
@media only screen and (max-width: 768px) {
  .bg-table{
    width: 550px;
    margin-left:25%;
    margin-top: 40px;
  }
  .book-vgtdf {
    display: flex;
    flex-direction: column;
    width:350px;
    margin-top: 20px;
    margin-right: 50px;
  }
  .i-2{
    margin-left: 10px;
  }

  .table-member{
    width: 550px;
    margin-left: 20px;
  }

  .mpcdf{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
  }
  .book-info-23 {
   
    width: 100%;
}
}