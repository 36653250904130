/* Member edit details CSS */
.super-memberedit-container{
    display: flex;
    height: 100vh;
    width: 100%;
}




























/*-------------------------------Member Details CSS -----------------------*/

.Super-Member-search {
    width: 700px;
    height: 40px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 20px;
}
.Super-Member-search-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Super-Member-Action-page {
    width: 100%;  
    overflow-x: hidden;
  }
  .Super-Member{
    width: 100%;
    overflow-x: hidden;
  }
  .Super-Member-courses-head{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px;
  }
  .Super-Member-profile-set {
      display: flex;
      margin-right: 40px;
      gap:10px
  }
   
  .Super-Member-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
 
}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}
 
.Super-Member-table-auction th {
    border-collapse: collapse;
    border: none;
}
 
.Super-Member-table-auction td{
    border-collapse: collapse;
}
 
.Super-Member-table-style {
    border-collapse: collapse;
    border: none;
  }
.outer-wrppr{
    /* border:1px solid black; */
    box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:1020px;
    margin-top: 60px;
    margin-left:150px;
   
}
.gtf{
    display:flex;
    gap:3rem;
}
 
  @media (300px <= width <= 600px){
   
    .Super-Member-table-auction{
        /* width: 550px; */
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }
    .super-dash-heading h2{
        margin-top: 5px;
        font-size: 20px;
    }
    .sm-info-container
    {
        margin-left: -20px;
    }
}
 
@media (768px <= width <= 1024px){
   
    .Super-Member-table-auction{
        width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
 
 
@media only screen and (max-width: 360px) {
    .Super-Member-table-auction{
    /* width: 250px; */
    margin:20px;
    margin-top: 40px;
    margin-left: 15px;
}
.super-dash-heading h2{
    margin-top: -6px;
}
}
@media only screen and (max-width: 712px) {
    .Super-Member-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .Super-Member-table-auction{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
 
}
@media only screen and (max-width: 600px) {
    .Super-Member-table-auction{
    /* width: 550px; */
    margin:20px;
    margin-top: 40px;
  }
 
}
@media only screen and (max-width: 320px) {
    .Super-Member-table-auction{
      width: 230px;
      margin:20px;
      margin-top: 40px;
    }
    
 
}