.Admin-biding-main{
  display: flex;
  width: 100%;
  height: 100vh;
}

.Ad-biding-full {
  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.Ad-biding-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 95%; 
}

.Ad-biding-heading h2{
  font-size: 30px;
}

.Ad-info-container{
  width:75vw;
  margin-top: 25px;
  /* border:1px solid black; */
}

.Ad-biding-display-full-44 {
  margin: 0px;
  margin-left: 0px;
}

.Ad-biding-hk {
  display: flex;
}

.Ad-biding-class {
  display: flex;
  flex-direction: column;
}

.Ad-biding-display-44 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 60px;
  padding: 5px;
}

.Ad-biding-input-55{
  width: 230px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ad-biding-mis-cd{
  display:flex;
  flex-direction: row;
  margin-left: -43px;
  margin-top: 15px;
  padding-left: 0px;
}

.Ad-biding-mis-cd-1{
  display:flex;
  flex-direction: row;
  margin-left: -35px;
  margin-top: 15px;
  padding-left: 0px;
}

.Ad-biding-mis-label{
  margin-top: 7px;
  margin-right: 5px;
}

.Ad-biding-input-440{
  width: 230px;
  padding: 10px;
  margin-left: px;
  /* border: 1px solid black; */
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ad-biding-input-444{
  width: 230px;
  padding: 12px;
  margin-left: 17px;
  /* border: 1px solid black; */
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ad-biding-display-440{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: -25px;
  margin-bottom: 0px;
  margin-left: 50px;
  /* margin-right: 130px; */
  padding: 5px;
}

.Ad-biding-input-55{
  width: 230px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ad-biding-rg{
  margin-right: 10px;
}


.Ad-biding-hum{
  /* width: 80vw; */
  /* justify-content: center; */
  margin-left: 340px;  
  /* border: 1px solid black; */
  /* align-items: center; */
  /* text-align: center; */
  margin-top: 35px;
}

.ew{
  /* margin-top: 20px; */
  margin-left: 100px;
 
}

.Ad-biding-cdf {
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* border: 4px solid black; */
}

.Ad-biding-cdf:hover{
  /* border: 4px solid black; */
  transform: scale(1.1);
  border: none;
  outline: none;
}

.Ad-biding-cdf:active{
  border: none;
  outline: none;
}

.Ad-biding-cdf:focus{
  border: none;
  outline: none;
}

/*  Responsive */

@media (320px <= width <= 375px) {
  .Ad-biding-heading h2{
    font-size: 20px;
  }

  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 15px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 19px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -90px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: -20px;  
    margin-top: 35px;
  }
}

@media (360px <= width <= 414px) {
  .Ad-biding-heading h2{
    font-size: 20px;
  }

  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 15px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 25px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: -20px;  
    margin-top: 35px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-biding-heading h2{
    font-size: 20px;
  }

  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 15px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 25px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: -20px;  
    margin-top: 35px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-biding-heading h2{
    font-size: 20px;
  }

  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 15px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 25px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 10px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: -20px;  
    margin-top: 35px;
  }
}

@media (768px <= width <= 820px) {

  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 200px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 210px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 220px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 200px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: 170px;  
    margin-top: 35px;
  }
}

@media (820px <= width <= 834px) {
  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 150px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 160px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 170px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 150px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: 120px;  
    margin-top: 35px;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 150px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 160px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 170px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 150px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: 120px;  
    margin-top: 35px;
  }
}

@media (800px <= width <= 834px) {
  .Ad-biding-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-biding-hk {
    display: block;
  }

  .Ad-biding-display-44 {
    margin-left: 150px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-mis-cd{
    margin-left: 160px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: 170px;
    
  }

  .Ad-biding-input-440{
    width: 230px;
    margin-top: 30px;
    margin-left: -100px;
  }
  
  .Ad-biding-input-444{
    width: 230px;
    margin-top: 30px;
    margin-left: -85px;
  }

  .Ad-biding-display-440{
    margin-top: 0px;
    margin-left: 150px;
  }
  
  .Ad-biding-input-55{
    width: 230px;
  }

  .Ad-biding-hum{
    margin-left: 120px;  
    margin-top: 35px;
  }
}

@media (1024px <= width <= 1280px) {
  .Ad-biding-display-full-44 {
    margin: 0px;
    margin-left: 0px;
  }

  .Ad-biding-display-44 {
    width: 450px;
    margin-left: 20px;
    padding: 5px;
  }

  .Ad-biding-mis-cd{
    margin-left: -73px;
  }
  
  .Ad-biding-mis-cd-1{
    margin-left: -65px;
  }

  .Ad-biding-hum{
    margin-left: 250px;  
  }
}











.input-44 {
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.input-55{
  width: 230px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.infoshead {
  display: flex;
}
.display-44 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 60px;
  padding: 5px;
}

.display-40{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 60px;
  padding: 5px;
}

.display-440{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: -25px;
  margin-bottom: 0px;
  margin-left: 50px;
  /* margin-right: 130px; */
  padding: 5px;
}

.mis-cd{
  display:flex;
  flex-direction: row;
  margin-left: -40px;
  margin-top: 15px;
  padding-left: 0px;
}

.input-440{
  width: 230px;
  padding: 12px;
  margin-left: 5px;
  /* border: 1px solid black; */
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.input-444{
  width: 230px;
  padding: 12px;
  margin-left: 17px;
  /* border: 1px solid black; */
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}


.hk-22{
  display: flex;
 
}
.mis-pa{
    border:'1px solid black'
}
.mis-label{
  margin-top: 7px;
  margin-right: 5px;
}
 
.rg{
  margin-right: 10px;
}
 
.input-44-ff {
  width: 300px;
  padding: 12px;
  border: none;
  /* margin-right: 100px; */
  border-radius: 4px;
  margin-left: 13px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.label-ff{
  margin-left: 72px;
}
 
.hk {
  display: flex;
}

.hum{
  /* width: 80vw; */
  /* justify-content: center; */
  margin-left: 340px;  
  /* border: 1px solid black; */
  /* align-items: center; */
  /* text-align: center; */
  margin-top: 35px;
}

 
.display-full-44 {
  margin: 0px;
  margin-left: 0px;
}
.gr{
  margin-left: 370px;
 
}
 
.ff{
  margin-left: 370px;
}
.button-44 {
  background-color: royalblue;
  width: 150px;
  padding: 10px 0px 10px 0px;
  transition: none;
  border-radius: 5px;
  margin-left: 450px;
  margin-top: 10px; /* Remove transition effect */
}
 
.button-44:hover {
  /* Remove hover effect */
  background-color: royalblue;
}
 
.cmm {
  width: 100%;
  overflow-x: hidden;

  /* border: 1px solid black; */
  margin-left: 10px;
}
.ew{
  /* margin-top: 20px; */
  margin-left: 100px;
 
}
 
 
@media (300px <= width <= 600px) {
  .input-44 {
    width: 250px;
 
    padding: 12px;
  }

  .display-440{
    width: 450px;
    margin-top: 10px;
    margin-left: 0px;
  }
 
  .hk{
    display: block;
    /* width: 100%; */
  }
 
  .display-44 {
    width: 350px;
    margin-left: 0px;
  }
 
  .display-full-44 {
    margin: 0px;
    margin-left: 5px;
  }
 
  .ew {
    margin-right: 130px;
  }
  .mis-cd{
    display:flex;
    flex-direction: column;
    margin-left: 0px;
  }
  .input-44-ff {
    width: 250px;
    margin-left: 0px;
  }
  .hum{
   
    margin-left: 0px;  
    
    margin-top: 35px;
  }
}

@media (768px <= width <= 1024px) {
  .mis-cd{
    display:flex;
    flex-direction: column;
    margin-left: 55px;
  }

  .hk{
    margin-left: 80px;
  }

  .display-440{
    width: 450px;
    margin-top: 10px;
    margin-left: 50px;
  }

  .display-44 {
    margin-left: 60px;
  }

  .display-40{
    margin-left: 130px;
  }

  .hum{
   
    margin-left: 50px;  
    margin-bottom: 20px;
    margin-top: 35px;
  }
  
}
 