.lg-card {
  position: relative;
  width: 300px;
  height: 250px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 48px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.lg-card__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}
.ab-about {
  /* font-size: 30px; */
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
  display: flex;
  gap:3rem;
  margin-top:40px;
  margin-left: 15%;
}
.lg-card__title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}

/* Responsive */

@media (320px <= width <= 375px) {
  .lg-card-container{
    display:block;
    margin-top: 30px;
    margin-left: 20px;
  }

  .lg-card__description {
    font-size: 12px;
  }
  
  .lg-card{
  margin-top: 40px;
  }

  .lg-card {
    width: 280px;
    height: 250px;
  }
}

@media (360px <= width <= 414px) {
  .lg-card-container{
    display:block;
    margin-top: 30px;
    margin-left: 35px;
  }

  .lg-card__description {
    font-size: 13px;
  }
  
  .lg-card{
  margin-top: 40px;
  }

  .lg-card {
    width: 290px;
    height: 250px;
  }
}

@media (375px <= width <= 414px) {
  .lg-card-container{
    display:block;
    margin-top: 30px;
    margin-left: 35px;
  }

  .lg-card__description {
    font-size: 13px;
  }
  
  .lg-card{
  margin-top: 40px;
  }

  .lg-card {
    width: 300px;
    height: 250px;
  }
}

@media (414px <= width <= 568px) {
  .lg-card-container{
    display:block;
    margin-top: 30px;
    margin-left: 40px;
  }

  .lg-card__description {
    font-size: 14px;
  }
  
  .lg-card{
  margin-top: 40px;
  }

  .lg-card {
    width: 330px;
    height: 250px;
  }
}

@media (768px <= width <= 820px) {}

@media (820px <= width <= 834px) {}

@media (834px <= width <= 1194px) {}

@media (800px <= width <= 834px) {}

@media (884px <= width <= 1024px) {}




@media (428px <= width <= 926px){
  .lg-card{
    margin-top: 40px;
    margin-left: 20px;
  }
}

@media (768px <= width <= 1024px) {
  .lg-card-container{
    margin-left: 0px;
    display: grid;
    margin-left: 175px;
  }
  .lg-card{
    width: 400px;
    height: 300px;
    margin-top: 50px;
  }

  .ab-about {
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    text-align: center;
  }
}

@media (834px <= width <= 1194px){
  .lg-card-container{
    margin-left: 0px;
    display: inline-table;
    margin-left: 25px;
  }
  .lg-card{
    width: 350px;
    height: 250px;
    margin-top: 50px;
  }
}