.Agent-createcourse-main {
  width: 100%;
  display: flex;
  height: 100vh;
}

.Ag-cc-full {
  width: 100%;
  margin-left: 0px;
  overflow-x: hidden;
}

.Ch-ngo {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}
.Ch-h {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}

@media (min-width: 300px) and (max-width: 600px) {
  .Ch-ngo {
      display: block;
  }

  .Ad-cc-cdf {
    margin-right: 20px;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .Ch-ngo{
      display: block;
  }
}

.C-bd {
  width: 99%;
  /* height: 550px; */
  padding: 15px;
  /* border: 2px solid black; */
}
.Ag-Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}.kk {
    width: 100%;
  
    margin-left: 10px;
    overflow-x: hidden;
  }
  .heading-2 h2{
    font-size: 30px;
    font-weight: 500;
  }
  .Ch-mug {
    padding-left: 10px;
    font-weight: 500;
  }
  
  .jug {
    padding-left: 10px;
    font-weight: 500;
    margin-top: -80px;
    /* margin-left: 800px; */
  }
  
  .hgs {
    display: flex;
  }
  .vt {
    color: black;
  }
  .bg {
    margin-top: 10px;
    margin-right: 5px;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .header span {
    margin-left: auto;
  }
  .createCourse {
    padding: 10px;
  }
  .course-title {
    width: 70%;
    padding: 9px;
    margin: 7px 0px;
  }
  .all {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
  }
  .Ch-input-11 {
    width: 300px;
  
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }
  
  .input-01 {
    width: 300px;
    /* margin-top: 10px; */
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }
  
  .Ch-display-11 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
    margin-left: 15px;
  }
  
  .display-01 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
    margin-left: 840px;
  }
  .Ch-hus {
    width: 80vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    border: none;
  }
  .Ch-hus:active {
    border: none;
    outline: none;
    /* transform: scale(1.1); */
  }
  .Ch-hus:focus{
    border: none;
    outline: none;
  }
  .Ch-bd {
    width: 99%;
    /* height: 550px; */
    padding: 15px;
    /* border: 2px solid black; */
  }
  
  .display-full-11 {
    margin: 0px;
    margin-left: 0px;
  }
  
  .button-11 {
    background-color: royalblue;
    width: 150px;
    padding: 10px 0px 10px 0px;
    transition: none;
    border-radius: 5px;
    margin-left: 450px;
    margin-top: 5px; /* Remove transition effect */
  }
  
  .Ch-cdf {
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
    /* border: 4px solid black; */
  }
  
  .mb-save{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
  }
  
  .mb-save:hover{
    transform: scale(1.1);
  }
  
  .mb-save:active{
    border: none;
    outline: none;
  }
  
  .mb-save:focus{
    border: none;
    outline: none;
  }

  .Ag-cc-hus {
    width: 80vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    border: none;
  }
  
  .Ag-cc-hus:active {
    border: none;
    outline: none;
    /* transform: scale(1.1); */
  }
  
  .Ag-cc-hus:focus{
    border: none;
    outline: none;
  }
  
  .Ag-cc-cdf {
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
    /* border: 4px solid black; */
  }
  
  .Ag-cc-cdf:hover{
    /* border: 4px solid black; */
    transform: scale(1.1);
    border: none;
    outline: none;
  }
  
  .Ag-cc-cdf:active{
    border: none;
    outline: none;
  }
  
  .Ag-cc-cdf:focus{
    border: none;
    outline: none;
  }
  
  .cdf:hover{
    /* border: 4px solid black; */
    transform: scale(1.1);
    border: none;
    outline: none;
  }
  
  .Ch-cdf:active{
    border: none;
    outline: none;
  }
  .Ch-cdf:focus{
    border: none;
    outline: none;
  }
  .button-11:hover {
    /* Remove hover effect */
    background-color: royalblue;
  }
  .Ch-ngo {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
  }
  
  @media (300px <= width <= 600px){
    .heading-2 h2{
      font-size: 20px;
      margin-top: 8px;
      font-weight: 550;
    }
    .kk {
      margin-left: 0px;
    }
    .Ch-display-11 {
      margin-left: 0px;
    }
    .display-01 {
      margin-top: 85px;
      margin-left: 0px;
    }
    .Ch-ngo {
      display: block;
    }
    .Ch-input-11 {
      width: 250px;
    }
    .input-01 {
      width: 250px;
      
    }
    .Ch-cdf{
      margin-bottom: 20px;
    }
    .Ch-cdf:active{
      border: none;
      outline: none;
    }
    
  }
  
  
  @media (768px <= width <= 1024px){
    .display-01 {
      margin-top: 85px;
      margin-left: 100px;
    }
    .Ch-ngo {
      display: block;
      
    }
    .Ch-display-11 {
      margin-left: 90px;
    }
    .kk {
      margin-left: 20px;
    }
    .Ch-cdf{
      margin-right: 150px;
      margin-bottom: 30px;
    }
  
    .mb-save{
      margin-right: 20px;
    }
  
    .Ch-cdf:active{
      border: none;
      outline: none;
    }
  }
  @media (820px <= width <= 1180px){
    .display-01 {
      margin-top: 85px;
      margin-left: 100px;
    }
    .Ch-ngo {
      display: block;
      
    }
    .Ch-display-11 {
      margin-left: 90px;
    }
    .kk {
      margin-left: 20px;
    }
    .Ch-cdf{
      margin-right: 150px;
      margin-bottom: 30px;
    }
  
    .mb-save{
      margin-right: 20px;
    }
  
    .Ch-cdf:active{
      border: none;
      outline: none;
    }
  }