/* .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .ch-1{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: 20px;
  }
  
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup-inner h3 {
    margin-top: 0;
  }
  
  .popup-inner button {
    margin-top: 20px;
  } */



  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
 
  .ch-1{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: 20px;
  }
 
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
 
  .popup-inner h3 {
    margin-top: 0;
  }
 
  .popup-inner button {
    margin-top: 20px;
  }
  .super-Admin-chit-info{
    width: 100%;  
    overflow-x: hidden;
  }
  .super-Chit-head .courses-head-nav-1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px;
  }
  .Action-profile-set-1 {
      display: flex;
      margin-right: 40px;
      gap:10px
  }
   
  .super-Chit-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
 
}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}
 
.super-Chit-table-auction th {
    border-collapse: collapse;
    border: none;
}
 
.super-Chit-table-auction td{
    border-collapse: collapse;
}
 
.super-Chit-tables-style {
    border-collapse: collapse;
    border: none;
  }
.outer-wrppr{
    /* border:1px solid black; */
    box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:1020px;
    margin-top: 60px;
    margin-left:150px;
   
}
.gtf{
    display:flex;
    gap:3rem;
}
 
  @media (300px <= width <= 600px){
   
    .super-Chit-table-auction{
        /* width: 550px; */
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }
}
 
@media (768px <= width <= 1024px){
   
  .super-Chit-table-auction{
    width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
 
 
@media only screen and (max-width: 360px) {
  .super-Chit-table-auction{
    /* width: 250px; */
    margin:20px;
    margin-top: 40px;
    margin-left: 15px;
}
}
@media only screen and (max-width: 712px) {
  .super-Chit-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .super-Chit-table-auction{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
 
}
@media only screen and (max-width: 600px) {
  .super-Chit-table-auction{
    width: auto;
    margin:20px;
    margin-top: 40px;
  }
 
}
