.super-auction-main {
  width: 100%;
  display: flex;
  height: 100vh;
}

.super-auction-full {
  flex-grow: 1;
  padding: 10px;
  /* overflow: hidden; */
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

.super-auction-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw;
}

.super-auction-heading h2{
  font-size: 30px;
}

.super-auction-info-container {
  width:75vw;
  margin-top: 25px;
  /* border:1px solid black; */
}

.super-auction-getdata{
  border:1px solid white;
  margin-bottom:20px;
  padding:30px;
  box-shadow: 3px 10px 10px 10px white;
  display: flex;
  flex-direction: column;
  gap:2rem;
  border-radius: 10px;
}

.super-auction-mse{
  background-color: whitesmoke;
  margin-left: 10px;

}

.super-auction-display-33 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  margin-left: 15px;
}

.super-auction-input-33 {
  width: 300px; 
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.super-auction-mag{
  padding-left: 10px;
  font-weight: 500;
}

.super-auction-trs{

  display:flex;
  justify-content: space-between;
  gap:22rem;

}

.super-auction-tr-1{
  display:flex;
}


.super-auction-tr{
  display:flex;
  margin-left: 55px;
}

.super-auction-hus {
  width: 80vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  border: none;
}

.super-auction-hus:active {
  border: none;
  outline: none;
  /* transform: scale(1.1); */
}

.super-auction-hus:focus{
  border: none;
  outline: none;
}

.super-auction-cdf {
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* border: 4px solid black; */
}

.super-auction-cdf:hover{
  /* border: 4px solid black; */
  transform: scale(1.1);
  border: none;
  outline: none;
}

.super-auction-cdf:active{
  border: none;
  outline: none;
}

.super-auction-cdf:focus{
  border: none;
  outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .super-auction-heading h2{
    font-size: 20px;
  }

  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-getdata{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: -40px;
    /* width: 250; */
    /* border: 1px solid black; */
  }

  .super-auction-input-33 {
    width: 200px; 
  }

  .super-auction-cdf {
    margin-right: 20px;
  }
}

@media (360px <= width <= 414px) {
  .super-auction-heading h2{
    font-size: 20px;
  }

  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-getdata{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: -40px;
  }

  .super-auction-input-33 {
    width: 230px; 
  }

  .super-auction-cdf {
    margin-right: 20px;
  }
}

@media (375px <= width <= 414px) {
  .super-auction-heading h2{
    font-size: 20px;
  }

  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-getdata{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: -40px;
  }

  .super-auction-input-33 {
    width: 250px; 
  }

  .super-auction-cdf {
    margin-right: 20px;
  }
}

@media (414px <= width <= 568px) {
  .super-auction-heading h2{
    font-size: 20px;
  }

  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-getdata{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: -40px;
  }

  .super-auction-input-33 {
    width: 290px; 
  }

  .super-auction-cdf {
    margin-right: 10px;
  }
}

@media (768px <= width <= 820px) {

  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-mse{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: 100px;
  }

  .super-auction-input-33 {
    width: 300px; 
  }

  .super-auction-cdf {
    margin-right: 20px;
  }
}

@media (820px <= width <= 834px) {
  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-head {
    width: 70vw;
  }

  .super-auction-mse{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: 50px;
  }

  .super-auction-input-33 {
    width: 300px; 
  }

  .super-auction-cdf {
    margin-right: 160px;
  }
}

@media (834px <= width <= 1194px) {
  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-head {
    width: 70vw;
  }

  .super-auction-mse{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: 50px;
  }

  .super-auction-input-33 {
    width: 300px; 
  }

  .super-auction-cdf {
    margin-right: 160px;
  }
}

@media (800px <= width <= 834px) {
  .super-auction-full {
    overflow-x: hidden;
  }

  .super-auction-head {
    width: 70vw;
  }

  .super-auction-mse{
    background-color: white;
  }

  .super-auction-tr-1{
    display:block;
  }

  .super-auction-trs{
    display:block;
  }

  .super-auction-tr{
    display:block;
  }
  
  .super-auction-display-33 {
    margin-left: 50px;
  }

  .super-auction-input-33 {
    width: 300px; 
  }

  .super-auction-cdf {
    margin-right: 160px;
  }
}

@media (884px <= width <= 1024px) {}

@media (1024px <= width <= 1280px) {
  .super-auction-input-33 {
    width: 220px; 
  }

}