.Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}
.Payment-courses-head{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}
.mem-container {
  display: flex;
  width: 100%;

 
}
.mem-courses-head{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  }


/* Your existing styles */
.mem-ab-1 {
  display: flex;
  width: 100%;
  
} 

.mem-totalm {
  width: 100%;
}
.pure {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .vgtdf {
    display: flex;
    flex-direction: column;
    /* width:345px; */
  }
  
  .mpcdf{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    margin-top: 50px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
    /* transform: scale(1.1); */
  }
  
  .mpcdf:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
  }
  .mpcdf:active{
    border: none;
    outline: none;
  }
  .mpcdf:focus{
    border: none;
    outline: none;
  }
  
  .table-member{
    max-height:400px;
      overflow-y: scroll;
      width: 1100px;
      margin:20px;
      overflow-x: auto;
      margin-left: 80px;
  }
  
  @media screen and (max-width: 769px){
    .vgtdf {
      display: flex;
      flex-direction: column;
      width:300px;
      margin-top: 20px;
    }
   
    .i-2{
      margin-left: 45px;
      width: 220px;
    }
  
    .table-member{
      margin-left: 10px;
      width: 300px;
    }
  }
  @media (768px <= width <= 1024px){
    .vgtdf {
      display: flex;
      flex-direction: column;
      width:350px;
      margin-top: 20px;
      margin-right: 50px;
    }
    .i-2{
      margin-left: 10px;
    }
  
    .table-member{
      width: 550px;
      margin-left: 20px;
    }
  
    .mpcdf{
      width: 90px;
      height: 35px;
      border-radius: 5px;
      border: none;
      outline: none;
      background-color: #19ab58;
      color: white;
    }
  }
   
 