.sm-ab-1 {
    display: flex;
}
 
.sm-infoshead{
    margin-left: 12px;
}
.sm-totalm{
    width:100%;
}
 
.sm-ab{
    /* margin-right: 10px; */
    margin: 0px;
}
.sm-AI{
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-top: 10px;
}
 
.sm-display-0 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}
 
.sm-display-110 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 440px;
    padding: 5px;
}
 
.sm-input-110 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}
 
.sm-ab-10{
    display: flex;
}
 
.sm-input-0 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }

  .sm-umedash-heading h2{
    font-size: 30px;
    font-weight: 500;
  }

  .sm-samedash-heading h2{
    font-size: 30px;
    font-weight: 500;
  }

  .sm-ume{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
  }

  .sm-respbutton{
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .sm-ubutton{
    /* border: 1px solid black; */
    margin-top: -40px;
  }

  .sm-ubutton:active{
    border: none;
    outline: none;
  }

  .sm-usavebutton{
    border: none;
    margin-left: 530px;
    width: 90px;
    height: 35px;
    background-color: #19ab58;
    color: white;
    cursor: pointer;
  }

  .sm-me-save{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
  }

  .sm-me-save:hover{
    transform: scale(1.1);
  }

  .sm-me-save:active{
    border: none;
    outline: none;
  }

  .sm-me-save:focus{
    border: none;
    outline: none;
  }

  .sm-usavebutton:hover{
    transform: scale(1.1);
  }

  .sm-usavebutton:active{
    border: none;
    outline: none;
  }
 
 
@media screen and (max-width: 769px){
 
    .sm-display-0 {
        display: block;
        width: 250px;
    }

    .sm-ume{
        margin-top: 35px;
    }

    .sm-me-save{
        margin-left: 70px;
        margin-bottom: 30px;    
    }

    .sm-umedash-heading h2{
        font-size: 20px;
        margin-top: 30px;
    }

    .sm-samedash-heading h2{
        font-size: 20px;
        margin-top: 25px;
    }
   
    .sm-display-110 {
        display: block;
        width: 250px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 60px;
    }
 
    .sm-ab{
        /* margin-left: -30px; */
    }
     .sm-npxxcd{
        margin-left: 60px;
     }
    .sm-ab-1 {
        display: block;
    }
 
    .sm-ab-10{
        display: block;
    }
 
    .sm-input-0 {
        width: 250px;
        padding: 12px;
        border: none;
        border-radius: 4px;
        box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
        outline: none;
        color: rgb(0, 0, 0);
    }
 
    .sm-input-110 {
        width: 250px;
        padding: 12px;
        border: none;
        border-radius: 4px;
        box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
        outline: none;
        color: rgb(0, 0, 0);
    }

    .sm-totalm{
        overflow-x: hidden;
    }

    .sm-usavebutton{
        border: none;
        margin-left: 150px;
    }

    .sm-ubutton{
        margin-bottom: 10px;
        margin-top: -120px;
        margin-bottom: 20px;
    }
   
}
 
@media (768px <= width <= 1024px) {
    .sm-ab-1 {
        display: block;
    }
 
    .sm-display-0{
        margin-left: 120px;
    }
 
    .sm-input-0 {
        width: 350px;
    }

    .sm-totalm{
        overflow-x: hidden;
    }

    .sm-ume{
        margin-top: 30px;
    }

    .sm-me-save{
        margin-left: -140px;
        margin-bottom: 20px;
    }

    .sm-umedash-heading h2{
        margin-top: 20px;
    }

    .sm-samedash-heading h2{
        margin-top: 25px;
    }

    .sm-usavebutton{
        border: none;
        margin-left: 220px;
    }

    .sm-ubutton{
        margin-bottom: 20px;
        margin-top: -120px;
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 320px){
    .sm-input-0
    {
        display: block;
        width:190px;
    }
    .sm-ab {
        display: block;
        margin-left: -6%;
    }

}