.Resp-byuser-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Resp-byuser-full{
    margin: 10px;
    width:100%;
    height:auto;
    /* overflow: hidden; */
}

.Resp-byuser-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Resp-byuser-heading h2{
    font-size: 30px;
}

.Resp-byuser-hk{
    display: flex;
    /* border: 1px solid black; */
}

.Resp-byuser-display-44{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}
 
.Resp-byuser-input-44{
    width: 300px;
    padding: 12px;
    height:55px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

.Resp-byuser-button-10{
    color: whitesmoke;
    width: 100px;
    height: 35px;
    margin-left: 550px;
    margin-top: 40px;
    background-color: #19ab58;
    border: none;
    outline: none;
    /* border:1px solid #19ab58; */
    border-radius: 5px;
}

.Resp-byuser-button-10:active{
    border: none;
    outline: none;
}

.Resp-byuser-button-10:focus{
    border: none;
    outline: none;
}

/* Rsponsive */

@media (320px <= width <= 375px) {
    .Resp-byuser-heading h2{
        font-size: 16px;
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 10px;
    }
     
    .Resp-byuser-input-44{
        width: 220px;
    }

    .Resp-byuser-button-10{
        margin-left: 70px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (360px <= width <= 414px) {
    .Resp-byuser-heading h2{
        font-size: 18px;
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 10px;
    }
     
    .Resp-byuser-input-44{
        width: 260px;
    }

    .Resp-byuser-button-10{
        margin-left: 90px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (375px <= width <= 414px) {
    .Resp-byuser-heading h2{
        font-size: 20px;
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 10px;
    }
     
    .Resp-byuser-input-44{
        width: 270px;
    }

    .Resp-byuser-button-10{
        margin-left: 100px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (414px <= width <= 568px) {
    .Resp-byuser-heading h2{
        font-size: 20px;
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 10px;
    }
     
    .Resp-byuser-input-44{
        width: 320px;
    }

    .Resp-byuser-button-10{
        margin-left: 120px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (768px <= width <= 820px) {
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 150px;
    }
     
    .Resp-byuser-input-44{
        width: 320px;
    }
    .Resp-byuser-button-10{
        margin-left: 260px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (820px <= width <= 834px) {
    .Resp-byuser-head{
        width: 70vw; 
    }

    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 100px;
    }
     
    .Resp-byuser-input-44{
        width: 320px;
    }

    .Resp-byuser-button-10{
        margin-left: 200px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (834px <= width <= 1194px) {
    .Resp-byuser-head{
        width: 70vw; 
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 100px;
    }
     
    .Resp-byuser-input-44{
        width: 320px;
    }

    .Resp-byuser-button-10{
        margin-left: 200px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (800px <= width <= 834px) {
    .Resp-byuser-head{
        width: 70vw; 
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 100px;
    }
     
    .Resp-byuser-input-44{
        width: 320px;
    }

    .Resp-byuser-button-10{
        margin-left: 200px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}

@media (884px <= width <= 1024px) {
    .Resp-byuser-head{
        width: 70vw; 
    }
    
    .Resp-byuser-hk{
        display: block;
    }
    
    .Resp-byuser-display-44{
        margin-left: 150px;
    }
     
    .Resp-byuser-input-44{
        width: 320px;
    }

    .Resp-byuser-button-10{
        margin-left: 250px;
        margin-top: 40px;
    }

    .Resp-byuser-full{
        overflow-x: hidden;
    }
}