.Ad-memberpassbook-main {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Ad-memberpassbook-full {
  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  /* overflow-x: hidden; */
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.Ad-memberpassbook-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw; 
}

.Ad-memberpassbook-heading h2{
  font-size: 30px;
}

.Ad-MP-pure {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.Ad-memberpassbook-vgtdf {
  display: flex;
  flex-direction: column;
  width:345px;
}

.Ad-Mp-i-2{
  width: 350px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
  height:40px;

}

.Ad-mp-cdf{
  width: 90px;
  height: 35px;
  border-radius: 5px;
  margin-top: 50px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* transform: scale(1.1); */
}

.Ad-mp-cdf:hover{
  transform: scale(1.1);
  border: none;
  outline: none;
}
.Ad-mp-cdf:active{
  border: none;
  outline: none;
}
.Ad-mp-cdf:focus{
  border: none;
  outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ad-memberpassbook-heading h2{
    font-size: 20px;
  }
  
  .Ad-memberpassbook-vgtdf {
    width:225px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (360px <= width <= 414px) {
  .Ad-memberpassbook-heading h2{
    font-size: 20px;
  }
  
  .Ad-memberpassbook-vgtdf {
    width:225px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-memberpassbook-heading h2{
    font-size: 20px;
  }
  
  .Ad-memberpassbook-vgtdf {
    width:225px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-memberpassbook-heading h2{
    font-size: 20px;
  }
  
  .Ad-memberpassbook-vgtdf {
    width:225px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (768px <= width <= 820px) {
  .Ad-memberpassbook-heading h2{
    font-size: 20px;
  }
  
  .Ad-memberpassbook-vgtdf {
    width:225px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (820px <= width <= 834px) {
  .Ad-memberpassbook-heading h2{
    font-size: 30px;
  }

  .Ad-memberpassbook-head {
    width: 70vw; 
  }
  
  .Ad-memberpassbook-vgtdf {
    width:245px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-memberpassbook-heading h2{
    font-size: 30px;
  }

  .Ad-memberpassbook-head {
    width: 70vw; 
  }
  
  .Ad-memberpassbook-vgtdf {
    width:245px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (800px <= width <= 834px) {
  .Ad-memberpassbook-heading h2{
    font-size: 30px;
  }

  .Ad-memberpassbook-head {
    width: 70vw; 
  }
  
  .Ad-memberpassbook-vgtdf {
    width:245px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}

@media (884px <= width <= 1024px) {
  .Ad-memberpassbook-heading h2{
    font-size: 30px;
  }

  .Ad-memberpassbook-head {
    width: 70vw; 
  }
  
  .Ad-memberpassbook-vgtdf {
    width:245px;
  }
  
  .Ad-Mp-i-2{
    width: 230px;
  }
  
  .Ad-mp-cdf{
    margin-top: 50px;
  }
}



.pure {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.vgtdf {
  display: flex;
  flex-direction: column;
  width:345px;
}

.mpcdf{
  width: 90px;
  height: 35px;
  border-radius: 5px;
  margin-top: 50px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* transform: scale(1.1); */
}

.mpcdf:hover{
  transform: scale(1.1);
  border: none;
  outline: none;
}
.mpcdf:active{
  border: none;
  outline: none;
}
.mpcdf:focus{
  border: none;
  outline: none;
}

.table-member{
  max-height:400px;
    overflow-y: scroll;
    width: 1100px;
    margin:20px;
    overflow-x: auto;
    margin-left: 80px;
}

@media screen and (max-width: 769px){
  .vgtdf {
    display: flex;
    flex-direction: column;
    width:300px;
    margin-top: 20px;
  }
 
  .i-2{
    margin-left: 45px;
    width: 220px;
  }

  .table-member{
    margin-left: 10px;
    width: 300px;
  }
}
@media (768px <= width <= 1024px){
  .vgtdf {
    display: flex;
    flex-direction: column;
    width:350px;
    margin-top: 20px;
    margin-right: 50px;
  }
  .i-2{
    margin-left: 10px;
  }

  .table-member{
    width: 550px;
    margin-left: 20px;
  }

  .mpcdf{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
  }
}
 
