.lg-main-container {
  overflow: hidden;
  width: 100%;
  /* margin: 0 auto;
  padding: 0px; */
}

.lg-carousel {
  width: 100%;
  height: 600px;
  margin-top: 100px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transition: background-image 0.3s ease; /* Add transition effect */
}

.lg-carousel button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background: none; */
  font-size: 24px;
  border: none;
  /* color: white; */
  cursor: pointer;
}

.lg-carousel button{
  border: none;
  outline: none;
}

.lg-carousel .prev {
  left: 10px;
  background: none;
  color: whitesmoke;
}

.lg-carousel .prev:active{
  border: none;
  outline: none;
}

.lg-carousel .next {
  right: 10px;
  background: none;
  color: whitesmoke;
}

.lg-carousel .next:active{
  border: none;
  outline: none;
}

.lg-carousel .lg-text-10 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-left:0px;
  color: black;
}

.lg-know {
  border: darkgray;
  width: 150px;
  color: whitesmoke;
  background-color: #750109;
  height: 50px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  margin-top: 100px;
  margin-left: -100px;
}

.lg-custom-text{
  font-size: 40px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
}

.no-1{
  font-weight: 600;
}

.lg-custom-content {
  width:850px;
  height:300px;
  padding-left:50px;
  margin-top: 120px; /* Adjust as needed */
}

.lg-custom-text-wiseinvest {
  margin-top: 220px;
}

.join-now-btn-1{
  margin-top: 200px;
}

.lg-custom-text-house {
  /* Styles for text related to the 'house' image */
  /* Add your styles here */
  margin-left: 660px;
  margin-top: 100px;
  width:400px;

}

.join-now-btn-2{
  margin-left: 260px;
  margin-top: 130px;
}

.lg-custom-text-chitmarrige1 {
  margin-left: 50px;
  margin-top: 200px;
  /* margin-right: -250px; */
}

.join-now-btn-3{
  margin-top: 200px;
  margin-left: -50px;
}

.lg-custom-text-books{
  /* width: 400px; */
  margin-left: 100px;
  margin-top: -290px;
}

.join-now-btn-4{
  margin-top: -150px;
  margin-left: -10px;
}

.lg-custom-textt{
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
}

.lg-flex{
  display:flex;
}

.lg-row{
  width:100%;
  display:flex;
}

.lg-custom-content {
  /* border: 1px solid black; */
  width:850px;
  height:300px;
  /* text-align: center; */
  padding-left:50px;
  /* padding-top:20px; */
  margin-top: 120px; /* Adjust as needed */
}

.no-1{
  font-weight: 600;
  margin-left: 0px;
}

.lg-custom-textt{
  /* border: 1px solid black; */
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
}

.lg-chit-logoo{
  width:80%;
  margin-right:300px;
  height:300px;
  margin-top:70px;;
  border-radius: 5px;
}

.lg-about{
  padding-top:110px;
}

.lg-ourservices{
  padding-top: 100px;
  /* border: 1px solid black; */
}

.lg-why-section{
  width:100%;
  height:550px;
  padding-top:150px;
}

.lg-contact-us {
 border: 1px solid white; 
}

.lg-last-border{
  margin-top: -20px;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .lg-carousel {
    width: 320px;
    height: 230px; /* Adjust height for tablets */
  }
 
  .lg-carousel .prev {
    margin-top: 0px;
  }
  
  .lg-carousel .next {
    margin-top: 0px;
  }

  .lg-carousel button {
    font-size: 16px;
  }

  .lg-know {
    width: 80px;
    height: 27px;
  }

  .lg-custom-text{
    font-size: 22px;
  }

  .lg-custom-text-wiseinvest {
    font-size: 15px;
    margin-top: 80px;
    
  }

  .join-now-btn-1{
    margin-left: -40px;
    margin-top: 95px;
  }

  .lg-custom-text-house {
    margin-top: -90px;
    margin-left: 10px;
    font-size: 15px;
    width:380px;  
  }

  .join-now-btn-2{
    margin-left: -40px;
    margin-top: -50px;
  }

  .lg-custom-text-chitmarrige1 {
    margin-left: -80px;
    margin-right: -55px;
    margin-top: 100px;
  }
  
  .join-now-btn-3{
    margin-top: 94px;
    margin-left: -50px;
  }

  .lg-custom-text-books{
    margin-left: 0px;
    margin-top: -90px;
    font-size: 20px;
    width:300px;
  }
  
  .join-now-btn-4{
    margin-top: -20px;
    margin-left: -40px;
  }

  .lg-flex{
    display:block;
  }

  .lg-text-10 {
    top: 40%;
  }

  .lg-custom-content{
    width:100%;
    margin-left: -22px;
    margin-top: 30px;

  }
  .lg-chit-logoo{
    margin-bottom: 80px;
    margin-left: 30px;
    margin-top: 120px;
  }

  .lg-about{
    margin-top: -100px;
  }

  .lg-ourservices{
    margin-top: 260px;
    margin-bottom: -120px;
  }
}

@media (360px <= width <= 390px) {
  .lg-carousel {
    width: 365px;
    height: 230px; /* Adjust height for tablets */
  }
 
  .lg-carousel .prev {
    margin-top: 0px;
  }
  
  .lg-carousel .next {
    margin-top: 0px;
  }

  .lg-carousel button {
    font-size: 16px;
  }

  .lg-know {
    width: 80px;
    height: 27px;
  }

  .lg-custom-text{
    font-size: 22px;
  }

  .lg-custom-text-wiseinvest {
    font-size: 16px;
    margin-top: 100px;
    
  }

  .join-now-btn-1{
    margin-left: -40px;
    margin-top: 95px;
  }

  .lg-custom-text-house {
    margin-top: -90px;
    margin-left: 10px;
    font-size: 20px;
    width:380px;  
  }

  .join-now-btn-2{
    margin-left: -40px;
    margin-top: -50px;
  }

  .lg-custom-text-chitmarrige1 {
    margin-left: -80px;
    margin-right: -55px;
    margin-top: 100px;
  }
  
  .join-now-btn-3{
    margin-top: 94px;
    margin-left: -50px;
  }

  .lg-custom-text-books{
    margin-left: 0px;
    margin-top: -90px;
    font-size: 20px;
    width:300px;
  }
  
  .join-now-btn-4{
    margin-top: -20px;
    margin-left: -40px;
  }

  .lg-flex{
    display:block;
  }

  .lg-text-10 {
    top: 40%;
  }

  .lg-custom-content{
    width:100%;
    margin-left: -22px;
    margin-top: 30px;

  }
  .lg-chit-logoo{
    margin-bottom: 80px;
    margin-left: 40px;
  }

  .lg-about{
    margin-top: -100px;
  }

  .lg-ourservices{
    margin-top: 260px;
    margin-bottom: -120px;
  }
}

@media (390px <= width <= 414px) {
  .lg-carousel {
    width: 390px;
    margin-left: 10px;
    height: 230px; /* Adjust height for tablets */
  }
 
  .lg-carousel .prev {
    margin-top: 0px;
  }
  
  .lg-carousel .next {
    margin-top: 0px;
  }

  .lg-carousel button {
    font-size: 16px;
  }

  .lg-know {
    width: 80px;
    height: 27px;
  }

  .lg-custom-text{
    font-size: 22px;
  }

  .lg-custom-text-wiseinvest {
    font-size: 16px;
    margin-top: 100px;
    
  }

  .join-now-btn-1{
    margin-left: -40px;
    margin-top: 95px;
  }

  .lg-custom-text-house {
    margin-top: -90px;
    margin-left: 10px;
    font-size: 20px;
    width:380px;  
  }

  .join-now-btn-2{
    margin-left: -40px;
    margin-top: -50px;
  }

  .lg-custom-text-chitmarrige1 {
    margin-left: -80px;
    margin-right: -55px;
    margin-top: 100px;
  }
  
  .join-now-btn-3{
    margin-top: 94px;
    margin-left: -50px;
  }

  .lg-custom-text-books{
    margin-left: 0px;
    margin-top: -90px;
    font-size: 20px;
    width:300px;
  }
  
  .join-now-btn-4{
    margin-top: -20px;
    margin-left: -40px;
  }

  .lg-flex{
    display:block;
  }

  .lg-text-10 {
    top: 40%;
  }

  .lg-custom-content{
    width:100%;
    margin-left: -22px;
    margin-top: 30px;

  }
  .lg-chit-logoo{
    margin-bottom: 80px;
    margin-left: 40px;
  }

  .lg-about{
    margin-top: -100px;
  }

  .lg-ourservices{
    margin-top: 260px;
    margin-bottom: -120px;
  }
}

@media (375px <= width <= 414px) {
  .lg-carousel {
    width: 375px;
    height: 230px; /* Adjust height for tablets */
  }
 
  .lg-carousel .prev {
    margin-top: 0px;
  }
  
  .lg-carousel .next {
    margin-top: 0px;
  }

  .lg-carousel button {
    font-size: 16px;
  }

  .lg-know {
    width: 80px;
    height: 27px;
  }

  .lg-custom-text{
    font-size: 22px;
  }

  .lg-custom-text-wiseinvest {
    font-size: 16px;
    margin-top: 100px;
    
  }

  .join-now-btn-1{
    margin-left: -40px;
    margin-top: 95px;
  }

  .lg-custom-text-house {
    margin-top: -90px;
    margin-left: 10px;
    font-size: 20px;
    width:380px;  
  }

  .join-now-btn-2{
    margin-left: -40px;
    margin-top: -50px;
  }

  .lg-custom-text-chitmarrige1 {
    margin-left: -80px;
    margin-right: -55px;
    margin-top: 100px;
  }
  
  .join-now-btn-3{
    margin-top: 94px;
    margin-left: -50px;
  }

  .lg-custom-text-books{
    margin-left: 0px;
    margin-top: -90px;
    font-size: 20px;
    width:300px;
  }
  
  .join-now-btn-4{
    margin-top: -20px;
    margin-left: -40px;
  }

  .lg-flex{
    display:block;
  }

  .lg-text-10 {
    top: 40%;
  }

  .lg-custom-content{
    width:100%;
    margin-left: -22px;
    margin-top: 30px;

  }
  .lg-chit-logoo{
    margin-bottom: 80px;
    margin-left: 40px;
  }

  .lg-about{
    margin-top: -100px;
  }

  .lg-ourservices{
    margin-top: 260px;
    margin-bottom: -120px;
  }
}

@media (414px <= width <= 568px) {
  .lg-carousel {
    width: 414px;
    height: 230px; /* Adjust height for tablets */
  }
 
  .lg-carousel .prev {
    margin-top: 0px;
  }
  
  .lg-carousel .next {
    margin-top: 0px;
  }

  .lg-carousel button {
    font-size: 16px;
  }

  .lg-know {
    width: 80px;
    height: 27px;
  }

  .lg-custom-text{
    font-size: 22px;
  }

  .lg-custom-text-wiseinvest {
    font-size: 18px;
    margin-top: 100px;
    
  }

  .join-now-btn-1{
    margin-left: -40px;
    margin-top: 95px;
  }

  .lg-custom-text-house {
    margin-top: -90px;
    margin-left: 10px;
    font-size: 20px;
    width:380px;  
  }

  .join-now-btn-2{
    margin-left: -40px;
    margin-top: -50px;
  }

  .lg-custom-text-chitmarrige1 {
    margin-left: -80px;
    margin-right: -55px;
    margin-top: 100px;
  }
  
  .join-now-btn-3{
    margin-top: 94px;
    margin-left: -50px;
  }

  .lg-custom-text-books{
    margin-left: 0px;
    margin-top: -90px;
    font-size: 20px;
    width:300px;
  }
  
  .join-now-btn-4{
    margin-top: -20px;
    margin-left: -40px;
  }

  .lg-flex{
    display:block;
  }

  .lg-text-10 {
    top: 40%;
  }

  .lg-custom-content{
    width:100%;
    margin-left: -22px;
    margin-top: 30px;

  }
  .lg-chit-logoo{
    margin-bottom: 80px;
    margin-left: 40px;
  }

  .lg-about{
    margin-top: -100px;
  }

  .lg-ourservices{
    margin-top: 260px;
    margin-bottom: -120px;
  }
}

@media (768px <= width <= 820px) {
}

@media (820px <= width <= 834px) {}

@media (834px <= width <= 1194px) {}

@media (800px <= width <= 834px) {}

@media (884px <= width <= 1024px) {}



@media (428px <= width <= 926px){
  .lg-carousel {
    width: 430px;
    height: 250px; /* Adjust height for tablets */
  }
}

@media (768px <= width <= 1024px){
  .lg-carousel {
    width: 100%;
    height: 500px;
    margin-top: 100px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease; /* Add transition effect */
  }
 
  .lg-carousel .prev {
    margin-top: 0px;
  }
  
  .lg-carousel .next {
    margin-top: 0px;
  }

  .lg-carousel button {
    font-size: 24px;
  }

  .lg-know {
    width: 120px;
    height: 35px;
  }

  .lg-custom-text{
    font-size: 22px;
  }

  .lg-custom-text-wiseinvest {
    font-size: 30px;
    margin-top: 270px;
    
  }

  .join-now-btn-1{
    margin-left: -60px;
    margin-top: 200px;
  }

  .lg-custom-text-house {
    margin-top: 50px;
    margin-left: 360px;
    font-size: 30px;
    width:360px;  
  }

  .join-now-btn-2{
    margin-left: 130px;
    margin-top: 80px;
  }

  .lg-custom-text-chitmarrige1 {
    margin-left: -30px;
    /* margin-right: -55px; */
    margin-top: 180px;
    font-size: 30px;
  }
  
  .join-now-btn-3{
    margin-top: 150px;
    margin-left: -80px;
  }

  .lg-custom-text-books{
    margin-left: 80px;
    margin-top: -180px;
    font-size: 28px;
    width:380px;
  }
  
  .join-now-btn-4{
    margin-top: -80px;
    margin-left: -30px;
  }

  .lg-flex{
    display:block;
  }

  .lg-custom-content{
 
    width:100%;
    margin-left: -25px;
  }
  
  .lg-chit-logoo{
    margin-left: 80px;
    margin-top: -80px;
  }
}