.Resp-groupuser-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Resp-groupuser-full {
    margin: 10px;
    width:100%;
    height:auto;
}

.Resp-groupuser-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Resp-groupuser-heading h2{
    font-size: 30px;
}

.Resp-searchcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Resp-quizSearch{
    margin: 10px 0px;
    padding: 7px;
    margin-right: auto;
    width:700px;
    height:40px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 20px;
}

.Resp-table-groupusers {
    max-height:400px;
    overflow-y: scroll;
    width: 950px;
    margin:20px;
    margin-top: 40px;
    margin-left: 140px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
}

.Resp-table-style-Gu{
    width:63vw;
    border: none;
    border-style: none;
    /* border:1px solid black; */
}

.Resp-table-style-Gu .tahe{
    padding: 8px;
    text-align: center;
    border:1px solid white;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Resp-groupuser-heading h2{
        font-size: 20px;
    }

    .Resp-quizSearch{
        width:200px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 230px;
        margin-top: 20px;
        margin-left: 10px;
    }
}

@media (360px <= width <= 414px) {
    .Resp-groupuser-heading h2{
        font-size: 20px;
    }

    .Resp-quizSearch{
        width:250px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 270px;
        margin-top: 20px;
        margin-left: 10px;
    }
}

@media (375px <= width <= 414px) {
    .Resp-groupuser-heading h2{
        font-size: 20px;
    }

    .Resp-quizSearch{
        width:250px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 270px;
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (414px <= width <= 568px) {
    .Resp-groupuser-heading h2{
        font-size: 20px;
    }

    .Resp-quizSearch{
        width:280px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 320px;
        margin-top: 20px;
        margin-left: 10px;
    }
}

@media (768px <= width <= 820px) {
    .Resp-quizSearch{
        width:450px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 580px;
        margin-top: 20px;
        margin-left: 60px;
    }
}

@media (820px <= width <= 834px) {
    .Resp-quizSearch{
        width:450px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 480px;
        margin-top: 20px;
        margin-left: 60px;
    }

    .Resp-groupuser-head {
        width: 70vw; 
        overflow-x: hidden;
    }
}

@media (834px <= width <= 1194px) {
    .Resp-quizSearch{
        width:450px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 480px;
        margin-top: 20px;
        margin-left: 60px;
    }

    .Resp-groupuser-head {
        width: 70vw; 
        overflow-x: hidden;
    }
}

@media (800px <= width <= 834px) {
    .Resp-quizSearch{
        width:450px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 480px;
        margin-top: 20px;
        margin-left: 50px;
    }

    .Resp-groupuser-head {
        width: 70vw; 
        overflow-x: hidden;
    }
}

@media (884px <= width <= 1024px) {
    .Resp-quizSearch{
        width:450px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Resp-table-groupusers {
        width: 540px;
        margin-top: 20px;
        margin-left: 60px;
    }

    .Resp-groupuser-head {
        width: 70vw; 
        overflow-x: hidden;
    }
}