.Ad-byuser-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-byuser-full {
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    /* overflow-x: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-byuser-mm {
    /* border:1px solid black;  */
   width:100%;
   height: 100vh;
   /* overflow-y: hidden; */
   /* margin-left: 200px; */
}

.Ad-byuser-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-byuser-heading h2{
    font-size: 30px;
}

/* .Ad-dos{
    margin-left: 10px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
}

.Ad-doos{
    margin-top: 36px;
    width: 150px;
    height: 44px;
    margin-left: 30px;
    border: 1px solid white;
    background-color: #212188;
    color: whitesmoke;
} */

.Ad-doos{
    margin-top: 36px;
    width: 150px;
    height: 44px;
    margin-left: 30px;
    /* background-color: white; */
    border: 1px solid white;
    background-color: #212188;
    color: whitesmoke;
}
 
.Ad-dos{
    margin-left: 30px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
}

.Ad-byuser-hk {
    display: flex;
    margin-top: 30px;
}

.Ad-byuser-display-44 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}

.Ad-byuser-src{
    width: 100px;
    height: 30px;
    margin-left: 550px;
    border-radius: 5px;
    margin-top: 80px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: whitesmoke;
}

.Ad-byuser-src:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.Ad-byuser-src:active{
    border: none;
    outline: none;
}

.Ad-byuser-src:focus{
    border: none;
    outline: none;
}

/* .Ad-table-byuser{
    max-height:400px;
    overflow-y: scroll;
    width: 1190px;
    margin:20px;
    overflow-x: auto;
} */

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }
    
    .Ad-doos{
        margin-top: 44px;
        width: 100px;
        height: 36px;
        font-size: 12px;
        margin-left: 10px;
    }
     
    .Ad-dos{
        margin-left: 10px;
        width: 100px;
        font-size: 11px;
        height: 36px;
        margin-top: 44px;
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 20px;
    }

    .Ad-byuser-src{
        margin-left: 70px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }
    
    .Ad-doos{
        margin-top:36px;
        width: 130px;
        height: 40px;
        font-size: 13px;
        margin-left: 10px;
    }
     
    .Ad-dos{
        margin-left: 10px;
        width: 130px;
        font-size: 14px;
        height: 40px;
        margin-top: 40px;
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 40px;
    }

    .Ad-byuser-src{
        margin-left: 90px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }
    
    .Ad-doos{
        margin-top: 44px;
        width: 125px;
        height: 36px;
        font-size: 13px;
        margin-left: 10px;
    }
     
    .Ad-dos{
        margin-left: 20px;
        width: 125px;
        font-size: 13px;
        height: 36px;
        margin-top: 44px;
    }

    .Ad-byuser-hk {
        display: block;
    }

    .Ad-byuser-display-44 {
        margin-left: 50px;
    }

    .Ad-byuser-src{
        margin-left: 100px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }
    
    .Ad-doos{
        margin-top:36px;
        width: 150px;
        height: 40px;
        font-size: 13px;
        margin-left: 10px;
    }
     
    .Ad-dos{
        margin-left: 10px;
        width: 150px;
        font-size: 14px;
        height: 40px;
        margin-top: 40px;
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 70px;
    }

    .Ad-byuser-src{
        margin-left: 120px;
    }
}

@media (768px <= width <= 820px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 170px;
    }

    .Ad-byuser-src{
        margin-left: 270px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }

    .Ad-byuser-head {
        width: 70vw; 
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 130px;
    }

    .Ad-byuser-src{
        margin-left: 230px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }

    .Ad-byuser-head {
        width: 70vw; 
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 130px;
    }

    .Ad-byuser-src{
        margin-left: 230px;
    }
}

@media (800px <= width <= 834px) {
    .Ad-byuser-heading h2{
        font-size: 20px;
    }

    .Ad-byuser-mm {
       overflow-x: hidden;
    }

    .Ad-byuser-head {
        width: 70vw; 
    }

    .Ad-byuser-hk {
        display: block;
        margin-top: 30px;
    }

    .Ad-byuser-display-44 {
        margin-left: 130px;
    }

    .Ad-byuser-src{
        margin-left: 230px;
    }
}

@media (884px <= width <= 1024px) {}