.Agent-add-existing-main {
  display: flex;
  width: 100%;
  height: 100vh;
}

.Ag-addexisting-full {
  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.Ag-addexisting-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw;
}

.Ag-addexisting-heading h2{
  font-size: 30px;
}

.Ag-addexisting-kh {
  display: flex;
}

.Ag-addexisting-display-003{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* margin-left: 60px; */
  padding: 5px;
}

.Ag-addexisting-input-003{
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ag-addexisting-vdt{
  margin-top: 70px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 80px;
}

.Ag-addexisting-vdt:hover{
  transform: scale(1.1);
}

.Ag-addexisting-ccc{
  width:100px;
  height:40px;
  background-color: #19ab58;
  border: #19ab58;
  border-radius: 5px;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ag-addexisting-heading h2{
    font-size: 20px;
  }

  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }
  
  .Ag-addexisting-input-003{
    width: 230px;
  }

  .Ag-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (360px <= width <= 414px) {
  .Ag-addexisting-heading h2{
    font-size: 20px;
  }

  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }
  
  .Ag-addexisting-input-003{
    width: 230px;
  }

  .Ag-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-addexisting-heading h2{
    font-size: 20px;
  }

  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }
  
  .Ag-addexisting-input-003{
    width: 270px;
  }

  .Ag-addexisting-vdt{
    margin-right: 30px;
  }
}

@media (414px <= width <= 568px) {
  .Ag-addexisting-heading h2{
    font-size: 20px;
  }

  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }
  
  .Ag-addexisting-input-003{
    width: 300px;
  }

  .Ad-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (768px <= width <= 820px) {

  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }

  .Ag-addexisting-display-003{
    margin-left: 160px;
  }
  
  .Ag-addexisting-input-003{
    width: 320px;
  }

  .Ag-addexisting-vdt{
    margin-right: 40px;
  }
}

@media (820px <= width <= 834px) {
  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ag-addexisting-head {
    width: 70vw;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }

  .Ag-addexisting-display-003{
    margin-left: 100px;
  }
  
  .Ag-addexisting-input-003{
    width: 320px;
  }

  .Ag-addexisting-vdt{
    margin-right: 60px;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ag-addexisting-head {
    width: 70vw;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }

  .Ag-addexisting-display-003{
    margin-left: 100px;
  }
  
  .Ag-addexisting-input-003{
    width: 320px;
  }

  .Ag-addexisting-vdt{
    margin-right: 50px;
  }
}

@media (800px <= width <= 834px) {
  .Ag-addexisting-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ag-addexisting-head {
    width: 70vw;
  }
  
  .Ag-addexisting-kh {
    display: block;
  }

  .Ag-addexisting-display-003{
    margin-left: 80px;
  }
  
  .Ag-addexisting-input-003{
    width: 320px;
  }

  .Ag-addexisting-vdt{
    margin-right: 70px;
  }
}

@media (884px <= width <= 1024px) {}