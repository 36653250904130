.Resp-paymentcollection-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Resp-paymentcollection-full{
    margin: 10px;
    width:100%;
    height:auto;
    /* overflow: hidden; */
}

.Resp-paymentcollection-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Resp-paymentcollection-heading h2{
    font-size: 30px;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Resp-paymentcollection-heading h2{
        font-size: 20px;
    }
}

@media (360px <= width <= 414px) {
    .Resp-paymentcollection-heading h2{
        font-size: 20px;
    }
}

@media (375px <= width <= 414px) {
    .Resp-paymentcollection-heading h2{
        font-size: 20px;
    }
}

@media (414px <= width <= 568px) {
    .Resp-paymentcollection-heading h2{
        font-size: 20px;
    }
}

@media (768px <= width <= 820px) {}

@media (820px <= width <= 834px) {
    .Resp-paymentcollection-head{
        width: 70vw; 
    }
}

@media (834px <= width <= 1194px) {
    .Resp-paymentcollection-head{
        width: 70vw; 
    }
}

@media (800px <= width <= 834px) {
    .Resp-paymentcollection-head{
        width: 70vw; 
    }
}

@media (884px <= width <= 1024px) {
    .Resp-paymentcollection-head{
        width: 70vw; 
    }
}