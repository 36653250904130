.Admin-payin-main{
    display: flex;
    height: 100vh;
}

.Ad-payin-full{
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-payin-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-payin-heading h2{
    font-size: 30px;
}

.Ad-payin-9{
    display: flex;
    gap: 0.8rem;
    margin-top: -25px;
    margin-left: 930px;
}
   
.Ad-cpayin-10{
    margin-top: 25px;
}

.Ad-cpayin-9{
    margin-top: 20px;
    margin-right: 20px;
    /* margin-left: 25px; */
    width: 250px;
    height: 40px;
    /* padding: 12px; */
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
    /* overflow-x: scroll; */
}

.Ad-info-container{
    width:75vw;
    margin-top: 25px;
    /* border:1px solid black; */
  
}

.Ad-payin-hk {
    display: flex;
}

.Ad-payin-display-44 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}

.Ad-payin-input-44 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

.Ad-payin-button-99 {
    background-color: #19ab58;
    width: 150px;
    padding: 10px 0px 10px 0px;
    color: whitesmoke;
    transition: none; 
    border: none;
    outline: none;
    border-radius: 5px;
    margin-left: 500px;
}

.Ad-payin-button-99:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.Ad-payin-button-99:active{
    border: none;
    outline: none;
}

.Ad-payin-button-99:focus{
    border: none;
    outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-payin-heading h2{
        font-size: 20px;
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 10px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 10px;
    }
    
    .Ad-payin-input-44 {
        width: 230px;
    }

    .Ad-payin-button-99 {
        margin-left: 50px;
        margin-top: 20px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-payin-heading h2{
        font-size: 20px;
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 10px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 20px;
    }
    
    .Ad-payin-input-44 {
        width: 260px;
    }

    .Ad-payin-button-99 {
        margin-left: 50px;
        margin-top: 20px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-payin-heading h2{
        font-size: 20px;
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 10px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 10px;
    }
    
    .Ad-payin-input-44 {
        width: 280px;
    }

    .Ad-payin-button-99 {
        margin-left: 80px;
        margin-top: 20px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-payin-heading h2{
        font-size: 20px;
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 10px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 20px;
    }
    
    .Ad-payin-input-44 {
        width: 310px;
    }

    .Ad-payin-button-99 {
        margin-left: 100px;
        margin-top: 20px;
    }
}

@media (768px <= width <= 820px) {
    .Ad-payin-heading h2{
        font-size: 20px;
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 400px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 170px;
    }
    
    .Ad-payin-input-44 {
        width: 310px;
    }

    .Ad-payin-button-99 {
        margin-left: 250px;
        margin-top: 20px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-payin-heading h2{
        font-size: 25px;
    }

    .Ad-payin-head{
        width: 70vw; 
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 290px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 120px;
    }
    
    .Ad-payin-input-44 {
        width: 310px;
    }

    .Ad-payin-button-99 {
        margin-left: 190px;
        margin-top: 20px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-payin-heading h2{
        font-size: 25px;
    }

    .Ad-payin-head{
        width: 70vw; 
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 290px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 120px;
    }
    
    .Ad-payin-input-44 {
        width: 310px;
    }

    .Ad-payin-button-99 {
        margin-left: 190px;
        margin-top: 20px;
    }
}

@media (800px <= width <= 834px) {
    .Ad-payin-heading h2{
        font-size: 25px;
    }

    .Ad-payin-head{
        width: 70vw; 
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 290px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 120px;
    }
    
    .Ad-payin-input-44 {
        width: 310px;
    }

    .Ad-payin-button-99 {
        margin-left: 190px;
        margin-top: 20px;
    }
}

@media (884px <= width <= 1024px) {
    .Ad-payin-heading h2{
        font-size: 25px;
    }

    .Ad-payin-head{
        width: 70vw; 
    }

    .Ad-payin-full{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .Ad-payin-9{
        margin-left: 380px;
    }

    .Ad-payin-hk {
        display: block;
    }
    
    .Ad-payin-display-44 {
        margin-left: 150px;
    }
    
    .Ad-payin-input-44 {
        width: 310px;
    }

    .Ad-payin-button-99 {
        margin-left: 240px;
        margin-top: 20px;
    }
}









.button-99 {
    background-color: #19ab58;
    width: 150px;
    padding: 10px 0px 10px 0px;
    color: whitesmoke;
    transition: none; 
    border: none;
    outline: none;
    border-radius: 5px;
    margin-left: 500px;
}

.button-99:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.button-99:active{
    border: none;
    outline: none;
}

.button-99:focus{
    border: none;
    outline: none;
}

.display-404{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 435px;
    padding: 5px;
}

@media (300px <= width <= 600px) {
    .button-99 {
        background-color: #19ab58;
        width: 150px;
        padding: 10px 0px 10px 0px;
        color: whitesmoke;
        transition: none; 
        border-radius: 5px;
        margin-left: 80px;
        margin-top: 10px;/* Remove transition effect */
    }
}

@media (768px <= width <= 1024px) {
    .button-99 {
        margin-left: 200px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .display-404{
        margin-left: 50px;
    }
}