
/* .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .ch-1{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: 20px;
  }
  
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup-inner h3 {
    margin-top: 0;
  }
  
  .popup-inner button {
    margin-top: 20px;
  } */



  /* .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
 
  .ch-1{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: 20px;
  }
 
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
 
  .popup-inner h3 {
    margin-top: 0;
  }
 
  .popup-inner button {
    margin-top: 20px;
  } */
  /* @media (min-width: 768px) and (max-width: 1024px) {
    .uspr {
        overflow-x: hidden;
    }
} */
.User-container {
  display: flex;
  height: 100vh;
  
}



.User-profile {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  transition: all 0.3s ease;
  width: calc(100% - 250px); /* Adjusted width when sidebar is open */
}
.User-courses-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.User-uspr {
    width: 100%;
    height: 100vh;
}
  .User-info{
    width: 100%;  
    overflow-x: hidden;
  }
  .User-head .courses-head{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px;
  }
  
  .User-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
 
}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}
 
.User-table-auction th {
    border-collapse: collapse;
    border: none;
}
 
.User-table-auction td{
    border-collapse: collapse;
}
 
.User-tables-style {
    border-collapse: collapse;
    border: none;
  }
/*  */
 
  
/* .mp{
    margin-top: 20px;
    margin-left: 1100px;
} */
.UD{
  width: 85vw;
  display: flex;
  /* border: 1px solid black; */
  gap: 62rem;
  margin-top: 10px;
  /* text-align: center; */
}

.userdash-heading h2{
  font-size: 30px;
  font-family: 500;
  margin-top: 15px;
}

.no-data {
  text-align: center;
  /* align-items: center; */
  margin-top: 250px;
  font-size: 20px;
}
 
.table-w{
    max-height:300px;
    overflow-y: scroll;
    width: 1000px;
    margin:20px;
    font-size: 15px;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  .outer-wr{
    border:1px solid white;
    box-shadow:0px 0px 3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:none;
    margin-top: 100px;
    margin-left:120px;
    margin-right: 80px;
   
  }
 
  @media only screen and (max-width: 677px){
    .outer-wr{
        box-shadow:0px 0px 3px white;
        margin-top: 100px;
        margin-left:0px;
        width: 200px;
       
      }
   
      .table-w{
        max-height:300px;
        width: 300px;
        margin:20px;
        font-size: 20px;
      }

      .userdash-heading h2{
        font-size: 20px;
        font-weight: 500;
      }
  }
 
  @media (768px <= width <= 1024px) {
    .table-w{
        width: 700px;
        font-size: 20px;
        overflow-x: auto; /* Enable horizontal scrolling */
    }
    .outer-wr{
        margin-left:40px;
    }
  }
  @media (300px <= width <= 600px){
   
    .User-table-auction{
        /* width: 550px; */
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }

}
 
@media (768px <= width <= 1024px){
   
  .User-table-auction{
        width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
    .super-Bidde-searchContainer{
      display: flex;
      flex-direction: row;
      align-items: center;
      
  }
  .Bidde-search {
    width: 50%;
    height: 40px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 20px;
}
}
 
 
@media only screen and (max-width: 360px) {
  .User-table-auction{
    width: auto;
    margin:20px;
    margin-top: 40px;
    margin-left: 15px;
}
.super-dash-heading1 h2
{
  font-size: 20px;
  margin-top: -5px;

}
}
@media only screen and (max-width: 712px) {
  .User-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .User-table-auction{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
    .super-dash-heading1 h2
{
  font-size: 20px;
  margin-top: -5px;

}
 
}
@media only screen and (max-width: 393px) {
  .User-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
  .super-dash-heading1 h2
{
font-size: 20px;
margin-top: -3px;

}

}
@media only screen and (max-width: 600px) {
  .User-table-auction{
    /* width: 550px; */
    margin:20px;
    margin-top: 40px;
  }
 
}
@media only screen and (max-width: 320px) {
  .User-table-auction{
    width: 230px;
    margin:20px;
    margin-top: 40px;
  }
 
}
