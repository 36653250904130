.Admin-createcourse-main {
  width: 100%;
  display: flex;
  height: 100vh;
}

.Ad-create-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 95%; 
}

.Ad-cc-full {
  width: 100%;
  margin-left: 0px;
  overflow-x: hidden;
}

.Ad-carrow{
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}

.Ad-fa{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}

.Ad-cc-heading h2{
  font-size: 30px;
}

.Ad-cc-image{
  display: flex;
  gap: 10px;
}

.Ad-cc-images{
  height: 50px;
  border-radius: 30px;
  width: 50px;
}

.Ad-bd{
  padding: 15px;
}

.Ad-ngo{
  display: flex;
  flex-direction: row;
}

.Ad-cc-input-11 {
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Ad-cc-display-11 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  margin-left: 15px;
}

.Ad-cc-display-01 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* padding: 5px; */
  margin-left: 855px;
}

.Ad-cc-hus {
  width: 80vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  border: none;
}

.Ad-cc-hus:active {
  border: none;
  outline: none;
  /* transform: scale(1.1); */
}

.Ad-cc-hus:focus{
  border: none;
  outline: none;
}

.Ad-cc-cdf {
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* border: 4px solid black; */
}

.Ad-cc-cdf:hover{
  /* border: 4px solid black; */
  transform: scale(1.1);
  border: none;
  outline: none;
}

.Ad-cc-cdf:active{
  border: none;
  outline: none;
}

.Ad-cc-cdf:focus{
  border: none;
  outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ad-cc-heading h2{
    font-size: 20px;
  }

  .Ad-fa{
    margin-top: -3px;
  }

  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 230px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 0px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 5px;
  }

  .Ad-cc-cdf {
    margin-right: 20px;
  }
}

@media (360px <= width <= 414px) {
  .Ad-cc-heading h2{
    font-size: 20px;
  }

  .Ad-fa{
    margin-top: -3px;
  }

  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 270px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 0px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 5px;
  }

  .Ad-cc-cdf {
    margin-right: 10px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-cc-heading h2{
    font-size: 20px;
  }

  .Ad-fa{
    margin-top: -3px;
  }

  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 290px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 0px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 5px;
  }

  .Ad-cc-cdf {
    margin-right: 10px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-cc-heading h2{
    font-size: 20px;
  }

  .Ad-fa{
    margin-top: -3px;
  }

  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 320px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 0px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 5px;
  }

  .Ad-cc-cdf {
    margin-right: 0px;
  }
}

@media (768px <= width <= 820px) {

  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 320px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 160px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 165px;
  }

  .Ad-cc-cdf {
    margin-right: 10px;
  }
}

@media (820px <= width <= 834px) {
  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 320px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 100px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 100px;
  }

  .Ad-cc-cdf {
    margin-right: 130px;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 320px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 100px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 100px;
  }

  .Ad-cc-cdf {
    margin-right: 130px;
  }
}

@media (800px <= width <= 834px) {
  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 320px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 100px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 100px;
  }

  .Ad-cc-cdf {
    margin-right: 130px;
  }
}

@media (884px <= width <= 1024px) {
  .Ad-ngo{
    display: block;
  }

  .Ad-cc-input-11 {
    width: 320px;
  }
  
  .Ad-cc-display-11 {
    margin-left: 130px;
  }

  .Ad-cc-display-01 {
    margin-top: 90px;
    margin-left: 130px;
  }

  .Ad-cc-cdf {
    margin-right: 150px;
  }
}

@media (1024px <= width <= 1280px) {
  .Ad-cc-display-01 {
    margin-left: 720px;
  }
}








.kk {
  width: 100%;

  margin-left: 10px;
  overflow-x: hidden;
}
.heading-2 h2{
  font-size: 30px;
  font-weight: 500;
}
.mug {
  padding-left: 10px;
  font-weight: 500;
}

.jug {
  padding-left: 10px;
  font-weight: 500;
  margin-top: -80px;
  /* margin-left: 800px; */
}

.hgs {
  display: flex;
}
.vt {
  color: black;
}
.bg {
  margin-top: 10px;
  margin-right: 5px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.header span {
  margin-left: auto;
}
.createCourse {
  padding: 10px;
}
.course-title {
  width: 70%;
  padding: 9px;
  margin: 7px 0px;
}
.all {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
}
.input-11 {
  width: 300px;

  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.input-01 {
  width: 300px;
  /* margin-top: 10px; */
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.display-11 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  margin-left: 15px;
}

.display-01 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  margin-left: 840px;
}
.hus {
  width: 80vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  border: none;
}
.hus:active {
  border: none;
  outline: none;
  /* transform: scale(1.1); */
}
.hus:focus{
  border: none;
  outline: none;
}
.bd {
  width: 99%;
  /* height: 550px; */
  padding: 15px;
  /* border: 2px solid black; */
}

.display-full-11 {
  margin: 0px;
  margin-left: 0px;
}

.button-11 {
  background-color: royalblue;
  width: 150px;
  padding: 10px 0px 10px 0px;
  transition: none;
  border-radius: 5px;
  margin-left: 450px;
  margin-top: 5px; /* Remove transition effect */
}

.cdf {
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* border: 4px solid black; */
}

.mb-save{
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
}

.mb-save:hover{
  transform: scale(1.1);
}

.mb-save:active{
  border: none;
  outline: none;
}

.mb-save:focus{
  border: none;
  outline: none;
}

.cdf:hover{
  /* border: 4px solid black; */
  transform: scale(1.1);
  border: none;
  outline: none;
}

.cdf:active{
  border: none;
  outline: none;
}
.cdf:focus{
  border: none;
  outline: none;
}
.button-11:hover {
  /* Remove hover effect */
  background-color: royalblue;
}
.ngo {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

@media (300px <= width <= 600px){
  .heading-2 h2{
    font-size: 20px;
    margin-top: 8px;
    font-weight: 550;
  }
  .kk {
    margin-left: 0px;
  }
  .display-11 {
    margin-left: 0px;
  }
  .display-01 {
    margin-top: 85px;
    margin-left: 0px;
  }
  .ngo {
    display: block;
  }
  .input-11 {
    width: 250px;
  }
  .input-01 {
    width: 250px;
    
  }
  .cdf{
    margin-bottom: 20px;
  }
  .cdf:active{
    border: none;
    outline: none;
  }
}

/* @media screen  and (max-width: 769px) {
  .heading-2{
    font-size: 20px;
    margin-top: 8px;
    font-weight: 550;
  }
  .kk {
    margin-left: 0px;
  }
  .display-11 {
    margin-left: 0px;
  }
  .display-01 {
    margin-top: 85px;
    margin-left: 10px;
  }
  .ngo {
    display: block;
  }
  .input-11 {
    width: 250px;
  }
} */
@media (768px <= width <= 1024px){
  .display-01 {
    margin-top: 85px;
    margin-left: 100px;
  }
  .ngo {
    display: block;
    
  }
  .display-11 {
    margin-left: 90px;
  }
  .kk {
    margin-left: 20px;
  }
  .cdf{
    margin-right: 150px;
    margin-bottom: 30px;
  }

  .mb-save{
    margin-right: 20px;
  }

  .cdf:active{
    border: none;
    outline: none;
  }
}