.MP{
    display: flex;
    justify-content: space-between;
    width: 80vw;
    margin-top: 20px;
}
 
.images{
    height: 50px;
    border-radius: 30px;
    width: 50px;
}
.image{
    display: flex;
    gap: 10px;
}

.mpdash-heading h2{
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
}

.umpbutton{
    margin-top: 50px;
    border: none;
    outline: none;
}

.umpbutton:active{
    border: none;
    outline: none;
}

.umpbutton:focus{
    border: none;
    outline: none;
}

.ump-button{
    border: none;
    margin-left: 530px;
    width: 90px;
    height: 35px;
    background-color: #19ab58;
    color: white;
    cursor: pointer;
}

.ump-button:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.ump-button:active{
    border: none;
    outline: none;
}

.ump-button:focus{
    border: none;
    outline: none;
}
 
.user-hk{
    display: flex;
    /* border: 1px solid black; */
}

.display-full-4{}
 
.user-display-44{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}
 
.user-input-44{
    width: 300px;
    padding: 12px;
    height:55px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}
 
.cft{
    margin-top: 50px;
    outline: none;
    border: none;
}

.cft:hover{
    transform: scale(1.1);
}

.cft:active{
    border: none;
    outline: none;
}
.cft:focus{
    border: none;
    outline: none;
}

 
.button-10{
    color: whitesmoke;
    width: 100px;
    height: 35px;
    margin-left: 550px;
    margin-top: 40px;
    background-color: #19ab58;
    border: none;
    outline: none;
    /* border:1px solid #19ab58; */
    border-radius: 5px;
}
.button-10:active{
    border: none;
    outline: none;
}
.button-10:focus{
    border: none;
    outline: none;
}
 
/*******************************Table CSS*************************/
 
.user-bgfs{
    height:auto;
    text-align: center;
    margin-top: 10px;
}
 
.table-wrap{
    max-height:400px;
    overflow-y: scroll;
    width: 1240px;
    margin:20px;
    overflow-x: auto;
}
 
.tables-style{}
 
.user-sasq{
    font-size: larger;
    background-color: rgba(5, 20, 76,0.5);
}
 
 
@media only screen and (max-width: 677px){
    .image{
        display: block;
    }
   
    .user-hk{
        display: block;
    }
 
    .user-display-44{
        margin-left: 0px;
    }
 
    .button-10{
        margin-left:100px ;
    }
 
    .table-wrap{
        width: 280px;
    }

    .mpdash-heading h2{
        font-size: 20px;
    }

    .ump-button{
        border: none;
        margin-left: 110px;
    }
}
 
@media (768px <= width <= 1024px){
    .MP{
        display: flex;
        justify-content: space-between;
        width: 70vw;
        margin-top: 20px;
    }

    .mpdash-heading h2{
        margin-top: 7px;
    }
 
    .image{
        display: block;
    }
   
    .user-hk{
        display: block;
    }
 
    .user-display-44{
        margin-left: 50px;
    }
 
    .user-input-44{
        width: 300px;
    }
 
    .button-10{
        margin-left: 220px;
    }
 
    .table-wrap{
        width: 715px;
    }

    .ump-button{
        border: none;
        margin-left: 180px;
    }
}



@media only screen and (max-width: 677px)
{
.user-input-44 {
    width: 220px;
    padding: 12px;
    height: 55px;
    margin-left: 2%;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}
}