.CA-payin-main {
  display: flex;
  height: 100vh;
  width: 100%;
}

.CA-payin-full {
  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.CA-payin-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw; 
}

.CA-payin-heading h2{
  font-size: 30px;
}

.CA-cpayin-1{
  display: flex;
  gap: 3rem;
  margin-top: -20px;
}

.CA-cpayin-2{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 60px;
  padding: 5px;
}
 
.CA-cpayin-3{
  width: 300px;
  height: 55px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.CA-cpay{
  margin-top: 10px;
}

.CA-cpay-1{
  margin-left: -65px;
}
  
.CA-cpayin-4{
  display: block;
  margin-top: -2px;
}
  
.CA-cpayin-11{
    margin-left: 20px;
    width: 300px;
    height: 40px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

.CA-num{
  display: flex;
  margin-top: -5px;
}

.CA-num-words{
  margin-top: 10px;
  margin-left: -128px;
}

.CA-numwords{
  margin-top: 10px;
  margin-left: -65px;
}
.CA-numwords-1{
  margin-left: 65px;
  margin-top: 9px;
}

.CA-cpayin-5{
  margin-top: -20px;
  margin-left: 15px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.CA-cpayin-6{
  margin-top: -10px;
  margin-left: 55px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.CA-cpayin-7{
  margin-top: -10px;
  margin-left: 70px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.CA-cpayin-8{
  margin-top: 10px;
  margin-left: 35px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.CA-cpayin-12{
  margin-top: -10px;
  margin-left: 85px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.CA-cpayin-13{
  margin-top: 10px;
  margin-left: 37px;
  width: 300px;
  height: 42px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.CA-button-20{
  background-color: #19ab58;
  width: 150px;
  height: 35px;
  /* padding: 10px 0px 10px 0px; */
  color: whitesmoke;
  transition: none;
  /* border: 1px solid #19ab58; */
  border-radius: 5px;
  border: none;
  margin-left: -350px;
  margin-top: -50px;
}

.CA-button-20:hover{
  transform: scale(1.1);
  border: none;
  outline: none;
}

.CA-button-20:active{
  border: none;
  outline: none;
}

.CA-button-20:focus{
  border: none;
  outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .CA-payin-heading h2{
    font-size: 15px;
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:0px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 150px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: -20px;
    }

    .CA-button-20:active{
      margin-top: 20px;
      margin-left: 16px;
      width: 200px;
      height: 40px;
    }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 130px;
  }
 
  .CA-cpayin-6{
    width: 130px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 130px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 130px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 60px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 5px;
  }
  .CA-numwords-1{
    margin-left: 40px;
    margin-top: 9px;
    font-size: 12px;
  }

  .CA-cpayin-11{
    width: 130px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 130px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 130px;
  }
}

@media (360px <= width <= 414px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .CA-payin-heading h2{
    font-size: 15px;
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:0px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 175px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: -20px;
    }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 170px;
  }
 
  .CA-cpayin-6{
    width: 170px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 170px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 170px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 60px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 5px;
  }
  .CA-numwords-1{
    margin-left: 40px;
    margin-top: 9px;
    font-size: 12px;
  }

  .CA-cpayin-11{
    width: 170px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 170px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 170px;
  }
}

@media (375px <= width <= 414px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .CA-payin-heading h2{
    font-size: 15px;
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:0px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 180px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: -20px;
    }

    .CA-button-20:active{
      margin-top: 20px;
      margin-left: 16px;
      width: 200px;
      height: 40px;
    }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 170px;
  }
 
  .CA-cpayin-6{
    width: 170px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 170px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 170px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 80px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 5px;
  }
  .CA-numwords-1{
    margin-left: 40px;
    margin-top: 9px;
    font-size: 12px;
  }

  .CA-cpayin-11{
    width: 170px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 170px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 170px;
  }
}

@media (414px <= width <= 568px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .CA-payin-heading h2{
    font-size: 15px;
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:0px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 220px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: -20px;
    }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 215px;
  }
 
  .CA-cpayin-6{
    width: 215px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 215px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 215px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 100px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 5px;
  }
  .CA-numwords-1{
    margin-left: 40px;
    margin-top: 9px;
    font-size: 12px;
  }

  .CA-cpayin-11{
    width: 215px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 215px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 215px;
  }
}

@media (768px <= width <= 820px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:140px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 250px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: 120px;
  }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 245px;
  }
 
  .CA-cpayin-6{
    width: 245px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 245px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 245px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 260px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 120px;
  }
  .CA-numwords-1{
    margin-left: 70px;
    margin-top: 9px;
    font-size: 14px;
  }

  .CA-cpayin-11{
    width: 245px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 245px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 245px;
  }
}

@media (820px <= width <= 834px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .CA-payin-head {
    width: 70vw; 
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:60px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 250px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: 40px;
  }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 245px;
  }
 
  .CA-cpayin-6{
    width: 245px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 245px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 245px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 190px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 40px;
  }
  .CA-numwords-1{
    margin-left: 70px;
    margin-top: 9px;
    font-size: 14px;
  }

  .CA-cpayin-11{
    width: 245px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 245px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 245px;
  }
}

@media (834px <= width <= 1194px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .CA-payin-head {
    width: 70vw; 
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:60px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 250px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: 40px;
  }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 245px;
  }
 
  .CA-cpayin-6{
    width: 245px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 245px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 245px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 190px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 40px;
  }
  .CA-numwords-1{
    margin-left: 70px;
    margin-top: 9px;
    font-size: 14px;
  }

  .CA-cpayin-11{
    width: 245px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 245px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 245px;
  }
}

@media (800px <= width <= 834px) {
  .CA-payin-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .CA-payin-head {
    width: 70vw; 
  }

  .CA-cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .CA-cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:60px;
    margin-top: 20px;
  }

  .CA-cpayin-3{
    width: 250px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .CA-cpay{
    display: block;
  }

   
  .CA-cpayin-4{
    display: block;
    margin-top: 20px;
  }
 
  .CA-cpay-1{
    margin-left: 40px;
  }

    .CA-cpayin-4{
      margin-left: 20px;
    }
 
  .CA-cpayin-5{
    margin-left: 10px;
    width: 245px;
  }
 
  .CA-cpayin-6{
    width: 245px;
    margin-left: 45px;
  }
 
  .CA-cpayin-7{
    width: 245px;
    margin-left: 60px;
  }
 
  .CA-cpayin-8{
    margin-top: 10px;
    width: 245px;
    margin-left: 22px;
  }
 
  .CA-button-20{
    margin-top: 30px;
    margin-left: 190px;
  }

  .CA-numwords{
    margin-top: 10px;
    margin-left: 40px;
  }
  .CA-numwords-1{
    margin-left: 70px;
    margin-top: 9px;
    font-size: 14px;
  }

  .CA-cpayin-11{
    width: 245px;
  }

  .CA-cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 245px;
  }
  
  .CA-cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 245px;
  }
}

@media (884px <= width <= 1024px) {}










.CA{
  display: flex;
  flex-direction: row;
  gap: 53rem;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 1rem;
  width: 80vw;

}

.cpayin-1{
    display: flex;
    gap: 3rem;
    margin-top: -20px;
}
 
.cpayin-2{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 450px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 60px;
  padding: 5px;
}
 
.cpayin-3{
  width: 300px;
  height: 55px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.cpay{
  margin-top: 10px;
}
 
.cpay-1{
margin-left: -65px;
}

.cpayin-4{
  display: block;
  margin-top: -2px;
}

.cpayin-11{
  margin-left: 20px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.cpayin-5{
  margin-top: -20px;
  margin-left: 15px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.cpayin-6{
  margin-top: -10px;
  margin-left: 55px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.cpayin-7{
  margin-top: -10px;
  margin-left: 70px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.cpayin-8{
  margin-top: 10px;
  margin-left: 35px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.cpayin-12{
  margin-top: -10px;
  margin-left: 85px;
  width: 300px;
  height: 40px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.cpayin-13{
  margin-top: 10px;
  margin-left: 37px;
  width: 300px;
  height: 42px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
 
.payin-9{
  display: flex;
  gap: 0.8rem;
  margin-top: -25px;
  margin-left: 930px;
}
 
.cpayin-10{
  margin-top: 25px;
}

.num{
  display: flex;
  margin-top: -5px;
}

.num-words{
  margin-top: 10px;
  margin-left: -128px;
}

.numwords{
  margin-top: 10px;
  margin-left: -65px;
}
.numwords-1{
  margin-left: 65px;
  margin-top: 9px;
}
 
.button-20{
  background-color: #19ab58;
  width: 150px;
  height: 35px;
  /* padding: 10px 0px 10px 0px; */
  color: whitesmoke;
  transition: none;
  /* border: 1px solid #19ab58; */
  border-radius: 5px;
  border: none;
  margin-left: -350px;
  margin-top: -50px;
}

.button-20:hover{
  transform: scale(1.1);
  border: none;
  outline: none;
}

.button-20:active{
  border: none;
  outline: none;
}

.button-20:focus{
  border: none;
  outline: none;
}
 
@media only screen and ( 300px <= width <= 600px){
  .CA{
    width: 50vw;
  }

  .payin-9{
    margin-left: 10px;
  }

  .cpayin-1{
    display: block;
    margin-top: 0px;
  }

  /* .cpayin-1{
    display: flex;
    gap: 3rem;
    margin-top: -20px;
} */
 
  .cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:0px;
    margin-top: 20px;
  }

  .cpayin-3{
    width: 200px;
    margin-left: 120px;
    margin-top: -60px;
  }

  .cpay{
    display: block;
  }

   
  .cpayin-4{
    display: block;
  }
 
  .cpay-1{
    margin-left: -20px;
    }

    .cpayin-11{
      margin-top: 20px;
      margin-left: 16px;
      width: 200px;
      height: 40px;
    }

    .cpayin-4{
      margin-left: 20px;
    }
 
  .cpayin-5{
    margin-left: 10px;
    width: 200px;
  }
 
  .cpayin-6{
    width: 200px;
    margin-left: 45px;
  }
 
  .cpayin-7{
    width: 200px;
    margin-left: 60px;
  }
 
  .cpayin-8{
    margin-top: 10px;
    width: 200px;
    margin-left: 22px;
  }
 
  .button-20{
    margin-top: 30px;
    margin-left: 100px;
  }
 
  .payin-9{
    display: flex;
    gap: 0.8rem;
    margin-top: 25px;
  }

  /* .num{
    display: block;
  } */

  .numwords{
    margin-top: 10px;
    margin-left: 5px;
  }
  .numwords-1{
    margin-left: 40px;
    margin-top: 9px;
    font-size: 12px;
  }

  .cpayin-12{
    margin-top: 0px;
    margin-left: 70px;
    width: 200px;
  }
  
  .cpayin-13{
    margin-top: 10px;
    margin-left: 25px;
    width: 200px;
  }
}
 
@media (768px <= width <= 1024px) {
  .CA{
    gap: 20rem;
    width: 73vw;
    /* border: 1px solid black; */
  }

  .cpayin-1{
    display: block;
    margin-top: 0px;
  }
 
  .cpayin-2{
    display: flex;
    gap: 1rem;
    margin-left:50px;
    margin-top: 20px;
  }

  .cpayin-3{
    width: 300px;
    margin-left: 125px;
    margin-top: -60px;
  }
   
  .cpayin-4{
    display: block;
  }
 
  .cpay-1{
    margin-left: 50px;
    }

    .cpayin-11{
      margin-left: 20px;
      width: 300px;
    }
 
  .cpayin-5{
    margin-left: 14px;
    width: 300px;
  }
 
  .cpayin-6{
    width: 300px;
    margin-left: 48px;
  }
 
  .cpayin-7{
    width: 300px;
    margin-left: 62px;
  }
 
  .cpayin-8{
    width: 300px;
    margin-left: 25px;
  }

  .cpayin-12{
    margin-top: 0px;
    margin-left: 75px;
    width: 300px;

  }
  
  .cpayin-13{
    margin-top: 10px;
    margin-left: 30px;
    width: 300px;
  }
 
  .button-20{
    margin-left: 200px;
    margin-top: 30px;
  }
 
  /* .payin-9{
    display: flex;
    gap: 0.8rem;
    margin-top: 25px;
  } */

  .payin-9{
    margin-left: 270px;
  }

  .numwords{
    margin-top: 10px;
    margin-left: 50px;
  }
}