* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}

/*
main {
  padding:10px;
}*/

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}
.closed{
  /* margin-left:45px; */
  width: 45px;
  z-index: 1;
}
.open{
  /* margin-left:200px; */
  width: 200px;
}
/* Sidebar */
.sidebar {
  background: #05144c;
  color: white;
  height:100vh;
  position: fixed;
  /* transition: width 0.5s; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 2;
  text-decoration: none;
}

.chit-logo-side {
  width: 100px;
  height: 100px;
  margin-top: -10px;
  margin-left: -100px;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.imcross{
  display: none;
}
.fabars{
  display:none ;
}
.bars {
  width: 30px;
  margin-top: -30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  /* padding: 10px 10px; */
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
  color:white;

}
.routes
.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.m-active {
 
  background-color:#ece5d9;
}

.menu {
  display: flex;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

@media screen and (max-width: 768px) {
  .imcross{
    display: flex;
  }
  .fabars{
    display: flex;
  }
  .fabars1{
    display: none;
  }
  .sidebar {
      width: 45px;
      /* position: static; */
  }
  .sidebar {
    height:100vh;
    
  }

  /* .main {
    margin-left: 45px;
  } */

  /* .main.open {
    margin-left: 200px;
  } */

  /* .main.closed {
    margin-left: 45px;
  } */

  /* .fabars1 {
    display: block;
  }

  .fabars, .imcross {
    display: none;
  } */
 
  .link_text {
      display: none;
  }
  .closed {
      /* margin-left: 45px; */
      width: 45px;
  }
 
 .open {
      /* margin-left: 45px; */
      width: 50px;
  }
}  


