
.Arrow{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
  }
  .C-ttd{
    width:100%;
    height:100vh;
}
.C-courses1{
    /* width: 100%; */
    padding: 10px;
}
.C-courses1-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 30px;
}
.C-create-course{
    background-color: rgb(33, 33, 136);
    width:120px;
    height:75px;
    
    border-radius: 5px;
    color:white;
   
}
.AC{
    display:flex;
    justify-content: space-between;
    width:82vw;
 
}
.Ch-AC-1{
    margin-top: 20px;
    width:82vw;
    height:60px;
    padding-top: 7px;
    justify-content: flex-end;
    text-align: end;
    align-items: flex-end;
    /* justify-content: space-between; */
}
 
.learn{
    color: blue;
    font-weight: bold;
    cursor: pointer;
}
/* .course-search{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search-bar{
    margin-top: 10px;
    padding: 10px;
    border: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 10px;
}
.search{
    width: 50%;
}
.filter{
    color: white;
    background-color: #05144c;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
}
.filter-icon{
    margin: 9px;
 
}
 
 
/* .card-chitgroup{
    width: auto;
    height: 180px;
    display: flex;
    border:1px solid linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);
    
    flex-direction: column;
    cursor: pointer;
    background: linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);
   
} */
.C-course-card{
    margin-top: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
} 
.card-chitgroup{
    width: auto;
    height: 200px;
    display: flex;
    /* border:1px solid linear-gradient(to top right, #33ccff 0%, #dd88c0 50%);  */
 /* border-top: 15px solid #05144c; */
    border-bottom: 20px solid white;
    flex-direction: column;
    cursor: pointer;
    background-image: url("../images/Nameimage.jpg");
    /* background: linear-gradient(to top right, #d5dbde 0%, #efedef 50%); */
   
}
.share{
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
 
}
.logo-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.makenow-logo{
    width: 70px;
    height: 30px;
}
.batch{
    align-self: center;
    margin-top: 35px;
    text-decoration: none;
    color: rgb(69, 68, 68);
}
.batch-details{
    margin-top: 10px;
    font-size: 14px;
    color: grey;
    padding: 0px;
    margin-left: 5px;
}
.id{
   font-weight: 500;
    width:200px;
    margin:0px;
    color:black;
 
}
 
.bottom-icons{
    color: black;
    font-size: 18px;
    display: flex;
    margin: 10px 0;
    justify-content: space-around;
}
.card-icon{
    cursor: pointer;
}
.card-icon:hover{
    color: blue;
}
.full-card{
    width: 360px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border: 1px solid rgb(219, 204, 204);
    margin: 20px;
}
.full-card:hover{
    transform: scale(1.05) translateY(8px);
    transition: 0.3s;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .modal {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    width: 50%;
  }
 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .batch:hover{
    text-decoration: none;
    color:black;
    transform: scale(1.05) translatex(8px);
    transition: 0.3s;
  }
  .modal-content {
    margin-top: 10px;
  }
  .vg-1{
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 10px;
    /* text-align: center; */
  }
  .create-course:hover{
    color:white;
    text-decoration: none;
    transform: scale(1.1);
  }
   
 
  /* Additional styles can be added as needed */
 
@media screen and (max-width:769px){
    /* .courses-head{
        flex-direction: column;
        padding-left: 0PX;
    } */
    .full-card{
        margin-left: 10px;
    }
    /* .courses1-head{
        margin-right: 40px;
    } */
    .course-card{
        padding-left: 0px;
    }
    .C-ttd{
        overflow-x: hidden;
    }

    .vg-1{
        font-size: 16px;
        font-weight: 600;
    }
 
}
 
@media (768px <= width <= 1024px) {
    .AC{
        display: flex;
        justify-content: space-between;
        width: 70vw;
        margin-top: 0px;
    }
 
    .Ch-AC-1{
        margin-top: 20px;
        width:72vw;
        height:40px;
        /* justify-: space-between; */
    }
}
 
.images {
    height: 50px;
    border-radius: 30px;
    width: 50px;
}
img {
    vertical-align: middle;
    border-style: none;
}
@media only screen and (max-width: 1024px) {
    .image {
        display: block;
    }
}
@media only screen and (max-width: 820px) {
    .image {
        display: block;
    }
}
@media only screen and (max-width: 800px) {
    .image {
        display: block;
    }
}
@media only screen and (max-width: 677px) {
    .image {
        display: block;
    }
}
@media only screen and (max-width: 677px) {
    .image {
        display: block;
    }
}

.image {
    display: flex;
    gap: 10px;
}
.dash-heading h2 {
    font-size: 30px;
    font-family: 500;
}
@media (min-width: 300px) and (max-width: 600px) {
    .dash-heading h2 {
        font-size: 20px;
        font-family: 500;
    }
}