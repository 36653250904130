

    
  .A-alluserscss{
    width: 100%;
    height: 100vh;
  }
  
  .U-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;

}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}

.U-table-auction th {
    border-collapse: collapse;
    border: none;
}

.U-table-auction td{
    border-collapse: collapse;
}

.U-tables-style {
    border-collapse: collapse;
    border: none;
  }
.outer-wrppr{
    /* border:1px solid black; */
    box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:1020px;
    margin-top: 60px;
    margin-left:150px;
    
}
.gtf{
    display:flex;
    gap:3rem;
}

  @media (300px <= width <= 600px){
    
    .U-table-auction{
        /* width: 350px; */
        width: auto;
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }
}

@media (768px <= width <= 1024px){
    
    .U-table-auction{
     width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
  

@media only screen and (max-width: 360px) {
  .U-table-auction{
    width: auto;
    
    margin-top: 40px;
    margin-left: -30px;
}
}
@media only screen and (max-width: 712px) {
  .U-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}

  @media only screen and (max-width: 412px) {
    .U-table-auction{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
  
}
@media only screen and (max-width: 600px) {
  .U-table-auction{
    /* width: 550px; */
    width: auto;
    margin:20px;
    margin-top: 40px;
  }

}
