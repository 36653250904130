.Admin-dashboard-main {
  width: 100%;
  display: flex;
  height: 100vh;
}

.Admin-dashboard-full {

  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.Ad-dashboard-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw;
}

.Ad-dashboard-heading h2{
  font-size: 30px;
}

.Ad-dashboard-cards-1 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 50px;
  width:75vw;
  /* border:1px solid black; */
}

.Ad-dashboard-cards-1 .Ad-dashboard-card {
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 130px;
  width: 250px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
  margin-left: 20px;
}

.Ad-dashboard-cards-1 .Ad-dashboard-card p .tip {
  font-size: 1em;
  font-weight: 700;
}

.Ad-dashboard-cards-1 .red {
  background-color: #f43f5e;
}

.Ad-dashboard-cards-1 .blue {
  background-color: #3b82f6;
}

.Ad-dashboard-cards-1 .green {
  background-color: #22c55e;
}

.Ad-dashboard-cards-1 .purple {
  background-color: #9722c5;
}

.Ad-dashboard-second-part{
  /* border:1px solid black; */
  margin: 20px;
  margin-left: 0px;
  display: flex;
  gap:1rem;
  width:75vw;
}

.Ad-dashboard-chartdata{
  margin-top: 10px;
  width:600px;
}

.Ad-dashboard-chartdata h4{
  margin-left:50px;
}

.Ad-dashboard-container-chart{
  width:500;
  height:350;
 
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ad-dashboard-heading h2{
    font-size: 20px;
  }

  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 240px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .Ad-dashboard-second-part{
    margin-left: -30px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (360px <= width <= 414px) {}

@media (375px <= width <= 414px) {
  .Ad-dashboard-heading h2{
    font-size: 20px;
  }

  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 240px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .Ad-dashboard-second-part{
    margin-left: -30px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-dashboard-heading h2{
    font-size: 20px;
  }

  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 35px;
  }

  .Ad-dashboard-second-part{
    margin-left: 0px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (768px <= width <= 820px) {

  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 200px;
  }

  .Ad-dashboard-second-part{
    margin-left: 160px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (820px <= width <= 834px) {
  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-dashboard-head {
    width: 70vw;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 140px;
  }

  .Ad-dashboard-second-part{
    margin-left: 100px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (834px <= width <= 1194px) {
  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-dashboard-head {
    width: 70vw;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 140px;
  }

  .Ad-dashboard-second-part{
    margin-left: 100px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (800px <= width <= 834px) {
  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-dashboard-head {
    width: 70vw;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 140px;
  }

  .Ad-dashboard-second-part{
    margin-left: 100px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (884px <= width <= 1024px) {
  .Admin-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .Ad-dashboard-head {
    width: 70vw;
  }
  
  .Ad-dashboard-cards-1 {
    display: block;
  }

  .Ad-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 180px;
  }

  .Ad-dashboard-second-part{
    margin-left: 140px;
    display:'flex';
    flex-direction: column;
  }

  .Ad-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .Ad-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (1024px <= width <= 1280px) {
  .Ad-dashboard-second-part{
    margin-left: -25px;
  }
}









.AdminDash{
  display: flex;
}

.dash-heading h2{
  font-size: 30px;
  font-family: 500;
}
 
.mm{
  /* border:1px solid black;  */
   width:100%;
   height: 100vh;
   overflow-y: hidden;
   /* margin-left: 200px; */

}  

.courses{
    width: 100%;
    height: 100vh;
    padding: 10px;
  
}
 
.AD{
  display: flex;
  justify-content: space-between;
  width: 85vw;
  /* border:1px solid black; */

}
.courses-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 80vw;
    /* margin-top: 10px; */

    /* border:1px solid black; */
}
.create-course{
    background-color: rgb(33, 33, 136);
    color: white;
    padding: 8px 11px;
    cursor: pointer;
}
.learn{
    color: blue;
    font-weight: bold;
    cursor: pointer;
}
.inpuut123 {
    width: 380px;
    padding: 5px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: dimgray;
  }
  .displaay1 {
    margin: 5px;
   
  }
.filter{
    color: white;
    background-color: #05144c;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
}
.filter-icon{
    margin: 9px;
 
}
 
.cards-1 {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 50px;
    width:75vw;
    /* border:1px solid black; */
  }
 
 
  .cards-1 .red {
    background-color: #f43f5e;
  }
 
  .cards-1 .blue {
    background-color: #3b82f6;
  }
 
  .cards-1 .green {
    background-color: #22c55e;
  }
  .cards-1 .purple {
    background-color: #9722c5;
  }
 
  .cards-1 .card {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 130px;
    width: 250px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
    margin-left: 20px;
  }
 
  .cards-1 .card p.tip {
    font-size: 1em;
    font-weight: 700;
  }
  .chartdata{
    margin-top: 10px;
    width:600px;
  }
 
  .chartdata h4{
    margin-left:50px;
  }
  .second-part{
    /* border:1px solid black; */
    margin: 20px;
    display: flex;
    gap:1rem;
    width:75vw;
  }
  .cal{
    margin-top: 20px;
    font-size: 17px;
    /* border:1px solid black; */
}
.container-chart{
  width:500;
  height:350;
 
}
 
  @media (300px <= width <= 600px){
    .cards-1 {
      display: flex;
      flex-direction: column;
    }

    .dash-heading h2{
      font-size: 20px;
      font-family: 500;
    }
 
    .AdminDash{
      overflow: hidden;
    }
 
    .mm{
      width: 60%;
      overflow-x: hidden;
      /* overflow: hidden; */
    }
    .cards-1 .card {
      display: flex;
      flex-direction: column;
      height: 150px;
      width: 250px;
    }
    .cards-1 .card p.tip {
      font-size: 1em;
      font-weight: 700;
    }
    .second-part{
      display:'flex';
      flex-direction: column;
    }
 
    .container-chart{
      width:500;
      height:350;
      margin-left: -70px;
    }
 
    .chartdata h4{
      margin-left:0px;
    }

    .courses-head{
      width: 80vw;
      /* margin-top: 10px; */
  
      /* border:1px solid black; */
  }
 
    .AD{
      margin-left: 10px;
      width: 75vw;
    }
 
  }
 
  @media (768px <= width <= 1024px) {
    .cards-1 {
      display: flex;
      flex-direction: column;
      margin-left: 120px;
    }
 
    .cards-1 .card {
      height: 150px;
      width: 300px;
    }
 
    .second-part{
      display:'flex';
      flex-direction: column;
      margin-left: 100px;
    }
 
    .AD{
      display: flex;
      justify-content: space-between;
      margin-left: 0px;
      width: 70vw;
    }

    .courses-head{
      width: 70vw;
      /* margin-top: 10px; */
  
      /* border:1px solid black; */
  }
 
    .AdminDash{
      overflow: hidden;
    }

    .mm{
      overflow-x: hidden;
    }
  }
 