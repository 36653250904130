.Ad-auctiondata-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-auctiondata-full {
    margin: 10px;
    width:100%;
    height:auto;
}

.Ad-auctiondata-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-auctiondata-h {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

.Ad-auctiondata-ume{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
}

.Ad-auctiondata-heading h2{
    font-size: 30px;
}

.Ad-table-auctiondata{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
}

th{
    position:sticky;
    top:0px;
    background-color: #394368;
    color:white;
}

th,td{
    border-collapse: collapse;
    padding:10px;
}

.Ad-gtf-Ad {
    display:flex;
    gap:3rem;
}

.Ad-home-edit {
    background-color: #212188;
    color: whitesmoke;
    border-radius: 10px;
    width:90px;
    height:35px;
    border-radius: 5px;
    color:white;
    border: none;
}

.Ad-home-edit:hover {
    transform: scale(1.1);
}

.Ad-home-edit:active{
    border: none;
    outline: none;
}

.Ad-home-edit:focus{
    border: none;
    outline: none;
}

.Ad-home-edit-1{
    background-color: #212188;
    color: whitesmoke;
    border-radius: 10px;
    width:90px;
    height:35px;
    border-radius: 5px;
    color:white;
    border: none;
    margin-left: -30px;
}

.Ad-home-edit-1:hover{
    transform: scale(1.1);
}

.Ad-home-edit-1:active{
    border: none;
    outline: none;
}

.Ad-home-edit-1:focus{
    border: none;
    outline: none;
}

.Ad-auctiondata-ch{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: -30px;
}

.Ad-auctiondata-ch:hover {
    transform: scale(1.1);
}
.Ad-auctiondata-ch:active{
    border: none;
    outline: none;
}
.Ad-auctiondata-ch:focus{
    border: none;
    outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-auctiondata-heading h2{
        font-size: 20px;
    }

    .Ad-auctiondata-ume{
        margin-top: -2px;
        font-size: 15px;
    }

    .Ad-table-auctiondata{
        width: 230px;
        margin-left: 10px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-auctiondata-heading h2{
        font-size: 20px;
    }

    .Ad-auctiondata-ume{
        margin-top: -2px;
        font-size: 15px;
    }

    .Ad-table-auctiondata{
        width: 270px;
        margin-left: 10px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-auctiondata-heading h2{
        font-size: 20px;
    }

    .Ad-auctiondata-ume{
        margin-top: -2px;
        font-size: 15px;
    }

    .Ad-table-auctiondata{
        width: 290px;
        margin-left: 10px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-auctiondata-heading h2{
        font-size: 20px;
    }

    .Ad-auctiondata-ume{
        margin-top: -2px;
        font-size: 15px;
    }

    .Ad-table-auctiondata{
        width: 320px;
        margin-left: 10px;
    }
}

@media (768px <= width <= 820px) {
    .Ad-auctiondata-heading h2{
        font-size: 20px;
    }

    .Ad-auctiondata-ume{
        margin-top: -2px;
        font-size: 15px;
    }

    .Ad-table-auctiondata{
        width: 580px;
        margin-left: 50px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-auctiondata-heading h2{
        font-size: 30px;
    }

    .Ad-auctiondata-head {
        width: 70vw; 
    }

    .Ad-auctiondata-ume{
        margin-top: 5px;
    }

    .Ad-table-auctiondata{
        width: 520px;
        margin-left: 40px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-auctiondata-heading h2{
        font-size: 30px;
    }

    .Ad-auctiondata-head {
        width: 70vw; 
    }

    .Ad-auctiondata-ume{
        margin-top: 5px;
    }

    .Ad-table-auctiondata{
        width: 520px;
        margin-left: 40px;
    }
}


@media (800px <= width <= 834px) {
    .Ad-auctiondata-heading h2{
        font-size: 30px;
    }

    .Ad-auctiondata-head {
        width: 70vw; 
    }

    .Ad-auctiondata-ume{
        margin-top: 5px;
    }

    .Ad-table-auctiondata{
        width: 520px;
        margin-left: 40px;
    }
}

@media (884px <= width <= 1024px) {
    .Ad-auctiondata-heading h2{
        font-size: 30px;
    }

    .Ad-auctiondata-head {
        width: 70vw; 
    }

    .Ad-auctiondata-ume{
        margin-top: 5px;
    }

    .Ad-table-auctiondata{
        width: 580px;
        margin-left: 40px;
    }
}








.fy{
    margin: 10px;
    width:100%;
    height:auto;
    /* border:1px solid black; */
    /* justify-content: center;
    text-align: center;
    align-items: center; */
}
table{
     /* border:1px solid black; */
     border-collapse:separate;
     border-spacing: 0px;
     min-width: max-content;
     
}

.ADy{
    display:flex;
    justify-content: space-between;
    /* border:1px solid black; */
    width:100%;
    margin:10px;
}

/* th{
    position:sticky;
    top:0px;
    background-color: #394368;
    color:white;
} */
.table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;

}
/* th,td{
    border-collapse: collapse;
    padding:10px;
} */

/* .table-auction th {
    border-collapse: collapse;
    border: none;
}

.table-auction td{
    border-collapse: collapse;
} */

.tables-style {
    border-collapse: collapse;
    border: none;
  }
.outer-wrppr{
    /* border:1px solid black; */
    box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:1020px;
    margin-top: 60px;
    margin-left:150px;
    
}
.gtf{
    display:flex;
    gap:3rem;
}
.bob{
    /* width:500px; */
    /* height:500px; */
    /* border:1px solid grey; */
    /* margin:40px; */
    
    /* box-shadow: 3px 3px 3px 3px gray; */
   
}
/* .tables-style{
    width:60vw;
}
.bob{
    width:600px;
} */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .popup-inner h3 {
    margin-top: 0;
  }
  
  .popup-inner button {
    margin-top: 20px;
  }

  @media (300px <= width <= 600px){
    
    .table-auction{
        width: 290px;
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }
}

@media (768px <= width <= 1024px){
    
    .table-auction{
        width: 550px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
  

       