.Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}


.ag-bin{
    width: 100%;
}


/* .input-44 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }
  
  .input-55{
    width: 230px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }
  
  .infoshead {
    display: flex;
  }
  .display-44 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
  }
   */
  /* .display-40{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
  } */
  
  /*  */
  /* .mis-cd{
    display:flex;
    flex-direction: row;
    margin-left: -40px;
    margin-top: 15px;
    padding-left: 0px;
  } */
  
  
  
  .bin-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;

}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}

.bin-table-auction th {
    border-collapse: collapse;
    border: none;
}

.bin-table-auction td{
    border-collapse: collapse;
}

.bin-tables-style {
    border-collapse: collapse;
    border: none;
  }
.outer-wrppr{
    /* border:1px solid black; */
    box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:1020px;
    margin-top: 60px;
    margin-left:150px;
    
}
.gtf{
    display:flex;
    gap:3rem;
}

  @media (300px <= width <= 600px){
    
    .bin-table-auction{
        width: 550px;
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }
}

@media (768px <= width <= 1024px){
    
    .bin-table-auction{
        width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
  

@media only screen and (max-width: 360px) {
  .bin-table-auction{
    width: 250px;
    margin:20px;
    margin-top: 40px;
    margin-left: 15px;
}
}
@media only screen and (max-width: 712px) {
  .bin-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .bin-table-auction{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
  
}
@media only screen and (max-width: 600px) {
  .bin-table-auction{
    /* width: 550px; */
    margin:20px;
    margin-top: 40px;
  }

}
@media only screen and (max-width: 320px) {
  .bin-table-auction{
    width: 250px;
    margin:20px;
    margin-top: 40px;
  }

}