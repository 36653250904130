.Super-kk {
  width: 100%;

  margin-left: 10px;
  overflow-x: hidden;
}
.Super-heading-2 h2{
  font-size: 30px;
  font-weight: 500;
}
.Super-mug {
  padding-left: 10px;
  font-weight: 500;
}

.Super-jug {
  padding-left: 10px;
  font-weight: 500;
  margin-top: -80px;
  /* margin-left: 800px; */
}

.Super-hgs {
  display: flex;
}
.Super-vt {
  color: black;
}
.Super-bg {
  margin-top: 10px;
  margin-right: 5px;
}

.Super-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.Super-header span {
  margin-left: auto;
}
.Super-createCourse {
  padding: 10px;
}
.Super-course-title {
  width: 70%;
  padding: 9px;
  margin: 7px 0px;
}
.Super-all {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
}
.Super-input-11 {
  width: 300px;

  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Super-input-01 {
  width: 300px;
  /* margin-top: 10px; */
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

.Super-display-11 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  margin-left: 15px;
}

.Super-display-01 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  margin-left: 840px;
}
.Super-hus {
  width: 80vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  border: none;
}
.Super-hus:active {
  border: none;
  outline: none;
  /* transform: scale(1.1); */
}
.Super-hus:focus{
  border: none;
  outline: none;
}
.Super-bd {
  width: 99%;
  /* height: 550px; */
  padding: 15px;
  /* border: 2px solid black; */
}

.Super-display-full-11 {
  margin: 0px;
  margin-left: 0px;
}

.Super-button-11 {
  background-color: royalblue;
  width: 150px;
  padding: 10px 0px 10px 0px;
  transition: none;
  border-radius: 5px;
  margin-left: 450px;
  margin-top: 5px; /* Remove transition effect */
}

.Super-cdf {
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  /* border: 4px solid black; */
}

.Super-mb-save{
  width: 90px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
}

.Super-mb-save:hover{
  transform: scale(1.1);
}

.Super-mb-save:active{
  border: none;
  outline: none;
}

.Super-mb-save:focus{
  border: none;
  outline: none;
}

.Super-cdf:hover{
  /* border: 4px solid black; */
  transform: scale(1.1);
  border: none;
  outline: none;
}

.Super-cdf:active{
  border: none;
  outline: none;
}
.Super-cdf:focus{
  border: none;
  outline: none;
}
.Super-button-11:hover {
  /* Remove hover effect */
  background-color: royalblue;
}
.Super-ngo {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

@media (300px <= width <= 600px){
  .Super-heading-2 h2{
    font-size: 20px;
    margin-top: 8px;
    font-weight: 550;
  }
  .super-dash-heading h2
{
  margin-top:32px;
  font-size: 20px;
}
  .Super-kk {
    margin-left: 0px;
  }
  .Super-display-11 {
    margin-left: 0px;
  }
  .Super-display-01 {
    margin-top: 85px;
    margin-left: 0px;
  }
  .Super-ngo {
    display: block;
  }
  .Super-input-11 {
    width: 250px;
  }
  .Super-input-01 {
    width: 250px;
    
  }
  .Super-cdf{
    margin-bottom: 20px;
  }
  .Super-cdf:active{
    border: none;
    outline: none;
  }
}

/* @media screen  and (max-width: 769px) {
  .heading-2{
    font-size: 20px;
    margin-top: 8px;
    font-weight: 550;
  }
  .kk {
    margin-left: 0px;
  }
  .display-11 {
    margin-left: 0px;
  }
  .display-01 {
    margin-top: 85px;
    margin-left: 10px;
  }
  .ngo {
    display: block;
  }
  .input-11 {
    width: 250px;
  }
} */
@media (768px <= width <= 1024px){
  .Super-display-01 {
    margin-top: 85px;
    margin-left: 100px;
  }
  .super-dash-heading h2
{
  margin-top:23px;
  font-size: 25px;
}
  .Super-ngo {
    display: block;
    
  }
  .Super-display-11 {
    margin-left: 90px;
  }
  .Super-kk {
    margin-left: 20px;
  }
  .Super-cdf{
    margin-right: 150px;
    margin-bottom: 30px;
  }

  .Super-mb-save{
    margin-right: 20px;
  }

  .Super-cdf:active{
    border: none;
    outline: none;
  }
}
