.Ad-userpayment-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-userpayment-full {
    width: 100%;
    /* flex-grow: 1; */
    padding: 10px;
    /* overflow-x: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-userpayment-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-userpayment-heading h2{
    font-size: 30px;
}

.Ad-display-002{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 350px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 420px;
    padding: 5px;
}

.Ad-up-button-10 {
    color: whitesmoke;
    width: 100px;
    height: 35px;
    margin-left: 530px;
    margin-top: 40px;
    border: none;
    outline: none;
    transform: scale(1.1);
    background-color: #19ab58;
}

.Ad-up-button-10:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.Ad-up-button-10:active{
    border: none;
    outline: none;
}

.Ad-up-button-10:focus{
    border: none;
    outline: none;
}

.Ad-up-bgfs{
    /* border:1px solid black; */
    height:auto;
    text-align: center;
    margin-top: 10px;
}

.Ad-table-userpayreport {
    max-height:400px;
    overflow-y: scroll;
    width: 1100px;
    margin:20px;
    overflow-x: auto;
    margin-left: 80px;
}

.Ad-sasq{
    font-size: larger;
    background-color: rgba(5, 20, 76,0.5);
}

.Ad-up-home-edit{
    background-color: hsl(240, 61%, 33%);
    color: whitesmoke;
    border-radius: 10px;
    width:90px;
    height:35px;
    border-radius: 5px;
    color:white;
    border: none;

}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-userpayment-heading h2{
        font-size: 16px;
    }
    
    .Ad-display-002{
        width: 230px;
        margin-bottom: 0px;
        margin-left: 10px;
    }
    
    .Ad-up-button-10 {
        margin-left: 60px;
       
    }

    .Ad-table-userpayreport {
        width: 220px;
        margin-left: 20px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-userpayment-heading h2{
        font-size: 18px;
    }
    
    .Ad-display-002{
        width: 230px;
        margin-bottom: 0px;
        margin-left: 30px;
    }
    
    .Ad-up-button-10 {
        margin-left: 90px;
       
    }

    .Ad-table-userpayreport {
        width: 270px;
        margin-left: 10px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-userpayment-heading h2{
        font-size: 20px;
    }
    
    .Ad-display-002{
        width: 230px;
        margin-bottom: 0px;
        margin-left: 40px;
    }
    
    .Ad-up-button-10 {
        margin-left: 100px;
       
    }

    .Ad-table-userpayreport {
        width: 280px;
        margin-left: 20px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-userpayment-heading h2{
        font-size: 20px;
    }
    
    .Ad-display-002{
        width: 250px;
        margin-bottom: 0px;
        margin-left: 50px;
    }
    
    .Ad-up-button-10 {
        margin-left: 120px;
       
    }

    .Ad-table-userpayreport {
        width: 320px;
        margin-left: 10px;
    }
}

@media (768px <= width <= 820px) {
    .Ad-userpayment-heading h2{
        font-size: 20px;
    }
    
    .Ad-display-002{
        width: 300px;
        margin-bottom: 0px;
        margin-left: 200px;
    }
    
    .Ad-up-button-10 {
        margin-left: 280px;
       
    }

    .Ad-table-userpayreport {
        width: 580px;
        margin-left: 60px;
    }
}

@media (820px <= width <= 834px) {

    .Ad-userpayment-head {
        width: 70vw; 
    }
    
    .Ad-display-002{
        width: 300px;
        margin-bottom: 0px;
        margin-left: 130px;
    }
    
    .Ad-up-button-10 {
        margin-left: 220px;
       
    }

    .Ad-table-userpayreport {
        width: 550px;
        margin-left: 20px;
    }
}

@media (834px <= width <= 1194px) {
   
    .Ad-userpayment-head {
        width: 70vw; 
    }
    
    .Ad-display-002{
        width: 300px;
        margin-bottom: 0px;
        margin-left: 130px;
    }
    
    .Ad-up-button-10 {
        margin-left: 220px;
       
    }

    .Ad-table-userpayreport {
        width: 550px;
        margin-left: 20px;
    }
}

@media (800px <= width <= 834px) {

    .Ad-userpayment-head {
        width: 70vw; 
    }
    
    .Ad-display-002{
        width: 300px;
        margin-bottom: 0px;
        margin-left: 130px;
    }
    
    .Ad-up-button-10 {
        margin-left: 220px;
       
    }

    .Ad-table-userpayreport {
        width: 550px;
        margin-left: 20px;
    }
}

@media (884px <= width <= 1024px) {
    .Ad-userpayment-head {
        width: 70vw; 
    }
    
    .Ad-display-002{
        width: 300px;
        margin-bottom: 0px;
        margin-left: 130px;
    }
    
    .Ad-up-button-10 {
        margin-left: 220px;
       
    }

    .Ad-table-userpayreport {
        width: 600px;
        margin-left: 20px;
    }
}












/* .button-10 button{
    color: whitesmoke;
    width: 100px;
    height: 35px;
    margin-left: 500px;
    margin-top: 20px;
    border: none;
    outline: none;
    background-color: #19ab58;
} */

.button-10:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.button-10 button:active{
    border: none;
    outline: none;
}
 


.table-payreport{
    max-height:400px;
    overflow-y: scroll;
    width: 1230px;
    margin:20px;
    overflow-x: auto;
}
 
@media (300px <= width <= 600px) {
    .button-10{
        color: whitesmoke;
        width: 100px;
        height: 30px;
        margin-left: 110px;
        margin-top: 20px;
        background-color: #19ab58;
    }
 
    .display-002{
        display: flex;
        flex-direction: column;
        width: 250px;
        margin-left: 20px;
    }

    .table-payreport{
        width: 280px;
    }
}
 
@media (768px <= width <= 1024px) {
    .display-002{
        width: 350px;
        margin-left: 100px;
        padding: 5px;
    }

    .table-payreport{
        width: 280px;
    }
}
