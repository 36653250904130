.Admin-allauction-main {
    width: 100%;
    display: flex;
    height: 100vh;
}

.Ad-allauction-full{
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    /* overflow: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-allauction-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 95%; 
}

.Ad-allauction-heading h2{
    font-size: 30px;
}

.Ad-allauction-table-allusers {
    max-height:400px;
    overflow-y: scroll;
    width: 870px;
    margin:20px;
    margin-top: 100px;
    margin-left: 140px;
    overflow-x: auto;
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
}

.Ad-allauction-gtf{
    display:flex;
    gap:3rem;
}

.Ad-allauction-create-course{
    background-color: rgb(33, 33, 136);
    width:90px;
    height:35px;
    border:rgb(33, 33, 136);
    border-radius: 5px;
    color:white;
   cursor: pointer;
}

.Ad-allauction-create-course:hover{
    color:white;
    text-decoration: none;
    transform: scale(1.1);
}

/* Responsive */

@media (320px <= width <= 375px) {
    /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-heading h2{
        font-size: 20px;
    }

    .Ad-allauction-table-allusers {
        width: 240px;
        margin-left: 10px;
    }
}

@media (360px <= width <= 414px) {
       /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-heading h2{
        font-size: 20px;
    }

    .Ad-allauction-table-allusers {
        width: 280px;
        margin-left: 10px;
    }
}

@media (375px <= width <= 414px) {
       /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-heading h2{
        font-size: 20px;
    }

    .Ad-allauction-table-allusers {
        width: 290px;
        margin-left: 10px;
    }
}

@media (414px <= width <= 568px) {
       /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-heading h2{
        font-size: 20px;
    }

    .Ad-allauction-table-allusers {
        width: 330px;
        margin-left: 10px;
    }
}

@media (768px <= width <= 820px) {
       /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-table-allusers {
        width: 640px;
        margin-left: 30px;
    }
}

@media (820px <= width <= 834px) {
        /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-table-allusers {
        width: 540px;
        margin-left: 30px;
    }
}

@media (834px <= width <= 1194px) {
        /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-table-allusers {
        width: 540px;
        margin-left: 30px;
    }
}

@media (800px <= width <= 834px) {
        /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-table-allusers {
        width: 520px;
        margin-left: 30px;
    }
}

@media (884px <= width <= 1024px) {
        /* .Ad-allauction-head {
        width: 90%; 
    } */

    .Ad-allauction-table-allusers {
        width: 610px;
        margin-left: 30px;
    }
}









.xc{
    margin-left: 150px;
}
.table-w1{
    max-height:400px;
    overflow-y: scroll;
    width: 900px;
    margin:20px;
    overflow-x: auto; /* Enable horizontal scrolling */


}
@media screen and (max-width: 769px){
    .xc{
        margin-left: 0px;
    }
    .table-w1{
        width: 150px;
   
    }
}

@media screen and (max-width: 1280px){
    .table-w1{
        width: 350px;
   
    }
}