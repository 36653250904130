.Ad-infodata-main{
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-infodata-full {
    width:100%;
    height: 90vh;
    /* border:1px solid black; */
    /* margin-left: 10px; */
    /* height:80vh; */
    margin-top: 10px;
    /* overflow: hidden; */
    
}

.Ad-infodata-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 85vw; 
}

.Ad-h {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

.Ad-ume{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
}

.Ad-infodata-heading h2{
    font-size: 30px;
}

.Ad-GU-searchcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Ad-GU-quizSearch{
    margin: 10px 0px;
    padding: 7px;
    margin-right: auto;
    width:700px;
    height:40px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 20px;
}

.Ad-table-groupusers {
    max-height:400px;
    overflow-y: scroll;
    width: 950px;
    margin:20px;
    margin-top: 40px;
    margin-left: 140px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
}

.Ad-table-style-Gu{
    width:63vw;
    border: none;
    border-style: none;
    /* border:1px solid black; */
}

.Ad-table-style-Gu .tahe{
    padding: 8px;
    text-align: center;
    border:1px solid white;
}

.Ad-button-infodata{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: -30px;
}

.Ad-button-infodata:hover {
    transform: scale(1.1);
}
.Ad-button-infodata:active{
    border: none;
    outline: none;
}
.Ad-button-infodata:focus{
    border: none;
    outline: none;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-infodata-heading h2{
        font-size: 20px;
    }

    .Ad-ume{
        margin-top: -3px;
        font-size: 15px;
    }

    .Ad-GU-quizSearch{
        width:200px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 230px;
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-infodata-heading h2{
        font-size: 20px;
    }

    .Ad-ume{
        margin-top: -3px;
        font-size: 15px;
    }

    .Ad-GU-quizSearch{
        width:280px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 270px;
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-infodata-heading h2{
        font-size: 20px;
    }

    .Ad-ume{
        margin-top: -3px;
        font-size: 15px;
    }

    .Ad-GU-quizSearch{
        width:300px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 290px;
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-infodata-heading h2{
        font-size: 20px;
    }

    .Ad-ume{
        margin-top: -3px;
        font-size: 15px;
    }

    .Ad-GU-quizSearch{
        width:300px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 320px;
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (768px <= width <= 820px) {

    .Ad-GU-quizSearch{
        width:500px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 600px;
        margin-top: 40px;
        margin-left: 60px;
    }
}

@media (820px <= width <= 834px) {  

    .Ad-infodata-head{
        width: 70vw; 
    }

    .Ad-GU-quizSearch{
        width:500px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 530px;
        margin-top: 40px;
        margin-left: 40px;
    }
}

@media (834px <= width <= 1194px) {

    .Ad-infodata-head{
        width: 70vw; 
    }

    .Ad-GU-quizSearch{
        width:500px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 530px;
        margin-top: 40px;
        margin-left: 50px;
    }
}

@media (820px <= width <= 834px) {}

@media (800px <= width <= 834px) {

    .Ad-infodata-head{
        width: 70vw; 
    }

    .Ad-GU-quizSearch{
        width:500px;
        height:35px;
        margin-top: 10px;
        margin-left: 10px;
    }

    .Ad-table-groupusers {
        width: 530px;
        margin-top: 40px;
        margin-left: 35px;
    }
}











.hj{
    width:100%;
    height: 100vh;
    /* border:1px solid black; */
    /* margin-left: 10px; */
    height:80vh;
    margin-top: 10px;
    /* overflow: hidden; */
}
.bb{
    margin-left: 100px;
    /* border: 1px solid black; */
    width:65vw;
    height:580px;
    padding: 15px;

}
.table-style{
    width:63vw;
    border: none;
    border-style: none;
    /* border:1px solid black; */
}
.table-style .tahe{
    padding: 8px;
    text-align: center;
    border:1px solid white;
}
.courses-head-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 80vw;
    margin-left: -30px;
    /* margin-top: 10px; */

    /* border:1px solid black; */
}
.heading-3 h2{
    font-size: 30px;
}
.ch{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: -30px;
}

.ch:hover {
    transform: scale(1.1);
}
.ch:active{
    border: none;
    outline: none;
}
.ch:focus{
    border: none;
    outline: none;
}
td, th{
    border-bottom:1px solid rgb(190, 184, 184);
    /* border-left: 1px solid rgb(190, 184, 184); */
    /* border-right: 1px solid rgb(190, 184, 184); */
}
th{
    border-top: 1px solid rgb(190, 184, 184);
}

.quizSearch{
    width:700px;
    height:40px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 20px;
}

.table-group{
    max-height:400px;
    overflow-y: scroll;
    width: 950px;
    margin:20px;
    margin-top: 40px;
    margin-left: 140px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
}

@media (300px <= width <= 600px){
    .table-group{
        width: 300px;
        margin:20px;
        margin-top: 40px;
        margin-left: 10px;
    }

    .quizSearch{
        width: 300px;
        margin-left: 10px;
    }

    .heading-3 h2{
        font-size: 20px;
        margin-top: -20px;
    }

    .courses-head-1{
        width: 80vw;
        margin-left: 0px;
    
        /* border:1px solid black; */
    }
}

@media (768px <= width <= 1024px){
    .table-group{
        width: 550px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }

    .quizSearch{
        width: 500px;
    }

    .heading-3 h2{
        font-size: 30px;
        margin-top: -20px;
    }

    .courses-head-1{
        width: 70vw;
        margin-left: -30px;
    
    }
}