.Ad-home-main {
    display: flex;
    height: 100vh;
}

.Ad-home-full {
    width: 100%;
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
    transition: all 0.3s ease;
    width: calc(100% - 250px);
}

.Ad-home-head {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Ad-home-heading h2{
    font-size: 30px;
}

.imt{
    width: 100px;
    height: 60px;
    border-radius: 0px;
    background-color: none;
    /* text-align: center; */
    margin-left: 70px;
}

.imtg-1{
    width: 60px;
    height: 100px;
    border-radius: 50px;
}

.Ad-home-edit{
    background-color: #212188;
    color: whitesmoke;
    border-radius: 10px;
    width:90px;
    height:35px;
    border-radius: 5px;
    color:white;
    border: none;

}

@media (320px <= width <= 375px) {
    .Ad-home-heading h2{
        font-size: 20px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-home-heading h2{
        font-size: 20px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-home-heading h2{
        font-size: 20px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-home-heading h2{
        font-size: 20px;
    }
}

@media (768px <= width <= 820px) {}

@media (820px <= width <= 834px) {
    .Ad-home-head {
        width: 70vw;
    }

    .Ad-home-heading h2{
        font-size: 25px;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-home-head {
        width: 70vw;
    }

    .Ad-home-heading h2{
        font-size: 25px;
    }
}

@media (800px <= width <= 834px) {
    .Ad-home-head {
        width: 70vw;
    }

    .Ad-home-heading h2{
        font-size: 25px;
    }
}

@media (884px <= width <= 1024px) {}











.ADys{
    width:100%;
    display:flex;
    justify-content: space-between;
    /* border:1px solid black; */
}
.fyd{
    /* border:1px solid black; */
    width:100%;
}

.imt{
    width: 100px;
    height: 60px;
    border-radius: 0px;
    background-color: none;
    /* text-align: center; */
    margin-left: 70px;
}

.imtg-1{
    width: 60px;
    height: 100px;
    border-radius: 50px;
}

.tables-style .tahe{
    padding: 8px;
    text-align: center;
    border:1px solid white;
  }

  .table-style .tahe{
    padding: 8px;
    text-align: center;
    border:1px solid white;
  }

.home-edit{
    background-color: #212188;
    color: whitesmoke;
    border-radius: 10px;
    width:90px;
    height:35px;
    border-radius: 5px;
    color:white;
    border: none;

}

.home-edit-1{
    background-color: #212188;
    color: whitesmoke;
    border-radius: 10px;
    width:90px;
    height:35px;
    border-radius: 5px;
    color:white;
    border: none;
    margin-left: -30px;
}

.home-edit-1:hover{
    transform: scale(1.1);
}

.home-edit-1:active{
    border: none;
    outline: none;
}

.home-edit-1:focus{
    border: none;
    outline: none;
}

.home-edit:hover {
    transform: scale(1.1);
}

.home-edit:active{
    border: none;
    outline: none;
}

.home-edit:focus{
    border: none;
    outline: none;
}

.outer-home{
    max-height:400px;
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    overflow-y: scroll;
    width: 970px;
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
}

@media (300px <= width <= 600px){
    .outer-home{
        width: 300px;
        margin-left: 10px;
    }
}

@media (768px <= width <= 1024px){
    .outer-home{
        width: 550px;
        margin-left: 25px;
    }
}