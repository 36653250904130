.Ag-Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}
.p1-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;

}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}

.p1-table-auction th {
    border-collapse: collapse;
    border: none;
}

.p1-table-auction td{
    border-collapse: collapse;
}

.p1-tables-style {
    border-collapse: collapse;
    border: none;
  }
  @media (300px <= width <= 600px){
    
    .p1-table-auction{
        width: auto;
        margin:20px;
        margin-top: 40px;
        
    }
}

@media (768px <= width <= 1024px){
    
    .p1-table-auction{
        width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
  

@media only screen and (max-width: 360px) {
  .p1-table-auction{
    width: auto;
    margin:20px;
    margin-top: 40px;
    
}
}
@media only screen and (max-width: 712px) {
  .p1-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .p1-table-auction{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
  
}
@media only screen and (max-width: 600px) {
  .p1-table-auction{
    width: auto;
    margin:20px;
    margin-top: 40px;
  }

}
@media (min-width: 600px) and (max-width: 1024px) {
    .p1-table-auctio{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
    
  }
@media only screen and (max-width: 320px) {
    .p1-table-auctio{
      width: 320px;
      margin:20px;
      margin-top: 40px;
    }
    
  }