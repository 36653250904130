.ab-1 {
    display: flex;
}
 
.infoshead{
    margin-left: 12px;
}
.totalm{
    width:100%;
}
 
.ab{
    /* margin-right: 10px; */
    margin: 0px;
}
.AI{
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-top: 10px;
}
 
.display-0 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
}
 
.display-110 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 440px;
    padding: 5px;
}
 
.input-110 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}
 
.ab-10{
    display: flex;
}
 
.input-0 {
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }

  .umedash-heading h2{
    font-size: 30px;
    font-weight: 500;
  }

  .samedash-heading h2{
    font-size: 30px;
    font-weight: 500;
  }

  .ume{
    color: black;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 2px;
  }

  .respbutton{
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .ubutton{
    /* border: 1px solid black; */
    margin-top: -40px;
  }

  .ubutton:active{
    border: none;
    outline: none;
  }

  .usavebutton{
    border: none;
    margin-left: 530px;
    width: 90px;
    height: 35px;
    background-color: #19ab58;
    color: white;
    cursor: pointer;
  }

  .me-save{
    width: 90px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: white;
  }

  .me-save:hover{
    transform: scale(1.1);
  }

  .me-save:active{
    border: none;
    outline: none;
  }

  .me-save:focus{
    border: none;
    outline: none;
  }

  .usavebutton:hover{
    transform: scale(1.1);
  }

  .usavebutton:active{
    border: none;
    outline: none;
  }
 
 
@media screen and (max-width: 769px){
 
    .display-0 {
        display: block;
        width: 250px;
    }

    .ume{
        margin-top: 35px;
    }

    .me-save{
        margin-left: 70px;
        margin-bottom: 30px;    
    }

    .umedash-heading h2{
        font-size: 20px;
        margin-top: 30px;
    }

    .samedash-heading h2{
        font-size: 20px;
        margin-top: 25px;
    }
   
    .display-110 {
        display: block;
        width: 250px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 60px;
    }
 
    .ab{
        margin-left: -30px;
    }
     .npxxcd{
        margin-left: 60px;
     }
    .ab-1 {
        display: block;
    }
 
    .ab-10{
        display: block;
    }
 
    .input-0 {
        width: 250px;
        padding: 12px;
        border: none;
        border-radius: 4px;
        box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
        outline: none;
        color: rgb(0, 0, 0);
    }
 
    .input-110 {
        width: 250px;
        padding: 12px;
        border: none;
        border-radius: 4px;
        box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
        outline: none;
        color: rgb(0, 0, 0);
    }

    .totalm{
        overflow-x: hidden;
    }

    .usavebutton{
        border: none;
        margin-left: 150px;
    }

    .ubutton{
        margin-bottom: 10px;
        margin-top: -120px;
        margin-bottom: 20px;
    }
   
}
 
@media (768px <= width <= 1024px) {
    .ab-1 {
        display: block;
    }
 
    .display-0{
        margin-left: 120px;
    }
 
    .input-0 {
        width: 350px;
    }

    .totalm{
        overflow-x: hidden;
    }

    .ume{
        margin-top: 30px;
    }

    .me-save{
        margin-left: -140px;
        margin-bottom: 20px;
    }

    .umedash-heading h2{
        margin-top: 20px;
    }

    .samedash-heading h2{
        margin-top: 25px;
    }

    .usavebutton{
        border: none;
        margin-left: 220px;
    }

    .ubutton{
        margin-bottom: 20px;
        margin-top: -120px;
        margin-bottom: 40px;
    }
}
 