

.Agent-Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}

.Au-information-pp {
    padding: 10px;
    /* border: 1px solid black; */
    /* margin-left: 30px; */
    width: 100vw;
    
}
.Au-info-container {
    width: 100%;
    /* margin-top: 25px; */
    /* border: 1px solid black; */
    
}


  
.All-table-auction{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;

}
th,td{
    border-collapse: collapse;
    /* border:1px solid rgba(82, 80, 80.0.5); */
    padding:10px;
}

.All-table-auction th {
    border-collapse: collapse;
    border: none;
}

.All-table-auction td{
    border-collapse: collapse;
}

.tables-style {
    border-collapse: collapse;
    border: none;
  }
.outer-wrppr{
    /* border:1px solid black; */
    box-shadow:0px 3px 3px  3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:1020px;
    margin-top: 60px;
    margin-left:150px;
    
}
.Au-gtf{
    display:flex;
    gap:3rem;
}
.bob{
    /* width:500px; */
    /* height:500px; */
    /* border:1px solid grey; */
    /* margin:40px; */
    
    /* box-shadow: 3px 3px 3px 3px gray; */
   
}
/* .tables-style{
    width:60vw;
}
.bob{
    width:600px;
} */
.Au-home-edit{
  background-color: #212188;
  color: whitesmoke;
  border-radius: 10px;
  width:90px;
  height:35px;
  border-radius: 5px;
  color:white;
  border: none;

}

.AU-home-edit-1{
  background-color: #212188;
  color: whitesmoke;
  border-radius: 10px;
  width:90px;
  height:35px;
  border-radius: 5px;
  color:white;
  border: none;
  margin-left: -30px;
}

.AU-home-edit-1:hover{
  transform: scale(1.1);
}

.AU-home-edit-1:active{
  border: none;
  outline: none;
}

.AU-home-edit-1:focus{
  border: none;
  outline: none;
}

.AU-home-edit:hover {
  transform: scale(1.1);
}

.AU-home-edit:active{
  border: none;
  outline: none;
}

.AU-home-edit:focus{
  border: none;
  outline: none;
}

.Au-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .Au-popup-inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .Au-popup-inner h3 {
    margin-top: 0;
  }
  
  .Au-popup-inner button {
    margin-top: 20px;
  }

  @media (360px <= width <= 600px){
    
    .All-table-auction{
        width: 550px;
        margin:20px;
        margin-top: 40px;
        margin-left: 15px;
    }
}

@media (768px <= width <= 1024px){
    
    .All-table-auction{
      width: 530px;
        margin:20px;
        margin-top: 50px;
        margin-left: 30px;
    }
}
  

       
@media screen and (max-width: 769px){
    .xc{
        margin-left: 0px;
    }
    .table-w1{
        width: 150px;
   
    }
}

@media screen and (max-width: 1280px){
    .table-w1{
        width: 350px;
   
    }
}
@media only screen and (max-width: 360px) {
  .All-table-auction{
    width: 300px;
    margin-left:20px;
   
}
.Agent-Arrow{
  margin-top:-4px;
  /* margin-left: -10px; */
}

}
@media only screen and (max-width: 393px) {
  .All-table-auction{
    width: 300px;
    margin-left:20px;
   
}
.Agent-Arrow{
  margin-top:-4px;
  /* margin-left: -10px; */
}
}
@media only screen and (max-width: 412px) {
  .All-table-auction{
    width: 300px;
    margin-left:20px;
   
}
.Agent-Arrow{
  margin-top:-4px;
  /* margin-left: -10px; */
}
}
@media only screen and (max-width: 320px) {
  .All-table-auction{
    width: 300px;
    margin-left:20px;
   
}
.Agent-Arrow{
  margin-top:-4;
}
}
@media only screen and (max-width: 712px) {
  .All-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
}
  @media only screen and (max-width: 412px) {
    .All-table-auction{
      width: 300px;
     
  }
  
}
@media only screen and (max-width: 600px) {
  .All-table-auction{
    /* width: 550px; */
    width: auto;
    margin:20px;
    margin-top: 40px;
  }

}
@media (min-width: 360px) and (max-width: 599px) {
  .All-table-auction{
    width: 300px;
    margin:20px;
    margin-top: 40px;
  }
  
}
@media only screen and (max-width: 320px) {
  .All-table-auction{
    width: 250px;
    margin:20px;
    margin-top: 40px;
  }

}