.Ad-profile-main {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Ad-profile-css {
  width:100%;
  height: 100vh;
  /* overflow-y: hidden; */

  flex-grow: 1;
  padding: 10px;
}

.Ad-profile-heading h2{
  font-size: 30px;
}

.Ad-profile-full {
  width: 100vh;
  /* margin-left: -30px; */
}

.Ad-display-001 {
  display: flex;
  flex-direction: column;
  width: 100px;
  border-radius: 10px;
  margin-left: 60px;
  margin-top: -10px;
}

.images-1{
  height: 120px;
  border-radius: 70px;
  width: 120px;
  margin-left: 550px;
}

.Ad-pro {
  display: flex;
}

.Ad-display-pro{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 80px;
  padding: 5px;
}

.Ad-input-pro{
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}

/* Responsive */

@media (320px <= width <= 375px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-profile-heading h2{
    font-size: 20px;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 0px;
    margin-left: 0px;
  }
  
  .Ad-input-pro{
    width: 230px;
    
  }

  .Ad-display-001 {
    margin-left: 10px;
  }
}

@media (360px <= width <= 414px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-profile-heading h2{
    font-size: 20px;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 0px;
    margin-left: 0px;
  }
  
  .Ad-input-pro{
    width: 280px;
    
  }

  .Ad-display-001 {
    margin-left: 30px;
  }
}

@media (375px <= width <= 414px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-profile-heading h2{
    font-size: 20px;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 0px;
    margin-left: 10px;
  }
  
  .Ad-input-pro{
    width: 270px;
  }

  .Ad-display-001 {
    margin-left: 40px;
  }
}

@media (414px <= width <= 568px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-profile-heading h2{
    font-size: 20px;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 0px;
    margin-left: 10px;
  }
  
  .Ad-input-pro{
    width: 310px;
    
  }

  .Ad-display-001 {
    margin-left: 60px;
  }
}

@media (768px <= width <= 820px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-profile-heading h2{
    font-size: 20px;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 10px;
    margin-left: 200px;
  }
  
  .Ad-input-pro{
    width: 310px;
    
  }

  .Ad-display-001 {
    margin-left: 60px;
  }

  .AD-TEXT{
    /* border: 1px solid black; */
    margin-left: 60px;
    font-weight: 500;
  }
}

@media (820px <= width <= 834px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 10px;
    margin-left: 150px;
  }
  
  .Ad-input-pro{
    width: 310px;
    
  }

  .Ad-display-001 {
    margin-left: -10px;
  }

  .AD-TEXT{
    /* border: 1px solid black; */
    margin-left: 100px;
    font-weight: 500;
  }
}

@media (834px <= width <= 1194px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 10px;
    margin-left: 150px;
  }
  
  .Ad-input-pro{
    width: 310px;
    
  }

  .Ad-display-001 {
    margin-left: -10px;
  }

  .AD-TEXT{
    /* border: 1px solid black; */
    margin-left: 100px;
    font-weight: 500;
  }
}

@media (820px <= width <= 834px) {}

@media (800px <= width <= 834px) {
  .Ad-profile-css {
    overflow-x: hidden;
  }

  .Ad-pro {
    display: block;
  }

  .Ad-display-pro{
    margin-top: 10px;
    margin-left: 130px;
  }
  
  .Ad-input-pro{
    width: 310px;
    
  }

  .Ad-display-001 {
    margin-left: -10px;
  }

  .AD-TEXT{
    /* border: 1px solid black; */
    margin-left: 100px;
    font-weight: 500;
  }
}









.hr{
  display:flex;
}

.mm{
  /* border: 1px solid black; */
  width: 100%;
  height: 100vh;
}

.aprofile-css{
  width: 100%;
  height: 100vh;
}

.mtr-2{
  width:100vh;
}

.display-13 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 550px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 60px;
  padding: 5px;
}

.mtr{
  margin-left: 560px;
  margin-top: 5px;
  margin-bottom: 8px;
}

.imt{
  width: 150px;
  height: 150px;
  border:1px solid rgb(227, 194, 194);
  border-radius: 100px;
  margin-left: 520px;
  margin-top: -10px;
  background-color: none;
}

.display-12 {
  display: flex;
  flex-direction: column;
  width: 100px;
  border-radius: 10px;
  margin-left: 300px;
}

.mtr-1{
  margin-left: -40px;

}

.display-000{
  display: flex;
  flex-direction: column;
  width: 100px;
  border-radius: 10px;
  margin-left: 60px;
}

.display-001{
  display: flex;
  flex-direction: column;
  width: 100px;
  border-radius: 10px;
  margin-left: 60px;
  margin-top: -10px;
}

.input-13 {
  width: 300px;
padding: 12px;
border: none;
border-radius: 4px;
box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
outline: none;
color: rgb(0, 0, 0);
}



@media (300px <= width <= 600px) {
  .imt{
      width: 150px;
      height: 150px;
      border:1px solid rgb(227, 194, 194);
      border-radius: 100px;
      margin-left: 120px;
      margin-top: -10px;
      
  }

  .mtr-2{
      width:10vh;
  }

  .mtr{
      margin-left: 155px;
      margin-top: 5px;
      margin-bottom: 8px;
  }

  .display-000{
      display: block;
      /* flex-direction: column; */
      width: 100px;
      border-radius: 10px;
      margin-left: 60px;
  }

  .display-001{
    display: flex;
    flex-direction: column;
    width: 100px;
    border-radius: 10px;
    margin-left: 90px;
  }

  .display-12 {
      display: flex;
      flex-direction: column;
      width: 100px;
      border-radius: 10px;
      margin-left: 60px;
  }

  .display-13 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
  }

  .hk{
      display: block;
  }

  .TEXT{
    /* border: 1px solid black; */
    margin-left: 260px;
    font-weight: 500;
    /* border: 1px solid black; */
  }

  .images-1{
    height: 120px;
    border-radius: 70px;
    width: 120px;
    margin-left: 50px;
}
}

