.Resp-auctiondetails-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Resp-auctiondetails-full {
    margin: 10px;
    width:100%;
    height:auto;
    /* overflow: hidden; */
}

.Resp-auctiondetails-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Resp-auctiondetails-headding h2{
    font-size: 30px;
}

.Resp-table-auctiondata{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
}

th{
    position:sticky;
    top:0px;
    background-color: #394368;
    color:white;
}

th,td{
    border-collapse: collapse;
    padding:10px;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Resp-auctiondetails-heading h2{
        font-size: 20px;
    }
    
    .Resp-table-auctiondata{
        width: 230px;
        margin-left: 10px;
    }
}

@media (360px <= width <= 414px) {
    .Resp-auctiondetails-heading h2{
        font-size: 20px;
    }
    
    .Resp-table-auctiondata{
        width: 270px;
        margin-left: 10px;
    }
}

@media (375px <= width <= 414px) {
    .Resp-auctiondetails-heading h2{
        font-size: 20px;
    }
    
    .Resp-table-auctiondata{
        width: 280px;
        margin-left: 10px;
    }
}

@media (414px <= width <= 568px) {
    .Resp-auctiondetails-heading h2{
        font-size: 20px;
    }
    
    .Resp-table-auctiondata{
        width: 320px;
        margin-left: 10px;
    }
}

@media (768px <= width <= 820px) {
    .Resp-auctiondetails-heading h2{
        font-size: 20px;
    }
    
    .Resp-table-auctiondata{
        width: 580px;
        margin-left: 60px;
    }
}

@media (820px <= width <= 834px) {
    .Resp-table-auctiondata{
        width: 510px;
        margin-left: 40px;
    }

    .Resp-auctiondetails-head {
        width: 70vw; 
    }
}

@media (834px <= width <= 1194px) {
    
    .Resp-table-auctiondata{
        width: 510px;
        margin-left: 40px;
    }

    .Resp-auctiondetails-head {
        width: 70vw; 
    }
}

@media (800px <= width <= 834px) {
    .Resp-table-auctiondata{
        width: 510px;
        margin-left: 30px;
    }

    .Resp-auctiondetails-head {
        width: 70vw; 
    }
}

@media (884px <= width <= 1024px) {
    .Resp-table-auctiondata{
        width: 560px;
        margin-left: 40px;
    }

    .Resp-auctiondetails-head {
        width: 70vw; 
    }
}