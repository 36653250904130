.Ad-detailbiding-main{
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-detailbidind-full {
    margin: 10px;
    width:100%;
    height:auto;
}

.Ad-detailbiding-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 85vw; 
}

.Ad-detailbiding-h {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

.Ad-detailbiding-ume{
    color: black;
    margin-top: 20px;
    margin-left: 4px;
    margin-right: 2px;
}

.Ad-detailbiding-heading h2{
    font-size: 30px;
    margin-top: 10px;
}

.Ad-detailbiding-ch{
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: -30px;
}

.Ad-detailbiding-ch:hover {
    transform: scale(1.1);
}
.Ad-detailbiding-ch:active{
    border: none;
    outline: none;
}
.Ad-detailbiding-ch:focus{
    border: none;
    outline: none;
}

.Ad-button-payout {
    background-color:"#212188";
    margin-left:"10px";
    width:90px;
    height:35px;
    border-radius: 5px;
    border:rgb(243, 63, 63);
    background-color: rgb(243, 63, 63);
    color:white;
    margin-left: -30px;
}

@media (320px <= width <= 375px) {
    .Ad-detailbiding-ume{
        margin-top: 15px;
    }
    
    .Ad-detailbiding-heading h2{
        font-size: 20px;
        margin-top: 10px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-detailbiding-ume{
        margin-top: 15px;
    }
    
    .Ad-detailbiding-heading h2{
        font-size: 20px;
        margin-top: 10px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-detailbiding-ume{
        margin-top: 15px;
    }
    
    .Ad-detailbiding-heading h2{
        font-size: 20px;
        margin-top: 10px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-detailbiding-ume{
        margin-top: 15px;
    }
    
    .Ad-detailbiding-heading h2{
        font-size: 20px;
        margin-top: 10px;
    }
}

@media (768px <= width <= 820px) {
    .Ad-detailbiding-ume{
        margin-top: 15px;
    }
    
    .Ad-detailbiding-heading h2{
        font-size: 20px;
        margin-top: 10px;
    }
}

@media (820px <= width <= 834px) {
    .Ad-detailbiding-head {
        width: 70vw; 
    }
}

@media (834px <= width <= 1194px) {
    .Ad-detailbiding-head {
        width: 70vw; 
    }
}

@media (820px <= width <= 834px) {
    .Ad-detailbiding-head {
        width: 70vw; 
    }
}

@media (800px <= width <= 834px) {
    .Ad-detailbiding-head {
        width: 70vw; 
    }
}