.cpayin-9{
    margin-top: 20px;
    margin-right: 20px;
    /* margin-left: 25px; */
    width: 250px;
    height: 40px;
    /* padding: 12px; */
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
    /* overflow-x: scroll; */
   
}
 
@media only screen and (max-width: 677px) {
    .cpayin-9{
        width: 150px;
    }
}