@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 
.signinheading{
  border: 1px solid black;
  margin-left: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
  width: 50%;
}
 
.login_input {
  border: none;
  border-radius: 20px;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 28px;
  font-weight: 700;
  width: 100%;
  transition: .2s;
  /* margin-bottom: 10px; */
}
 
.login_input:active,
.login_input:focus,
.login_input:hover {
  outline: none;
  border-bottom-color: #6A679E;
}
 
.login__icon{
  position: absolute;
  top: 28px;
  /* color: #7875B5; */
  color: #243678;
  margin-left: 5px;
}
 
.login__lock{
  position: absolute;
  top: 28px;
  /* color: #7875B5; */
  color: #243678;
  margin-left: 5px;
}
 
.login__field{
  padding: 20px 0px;
  position: relative;
  /* margin-top: 20px; */
}
 
.login__submit{
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 10px 20px;
  border-radius: 26px;
  border: 1px solid #D4D3E8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  /* color: #4C489D; */
  color: #243678;
  box-shadow: 0px 2px 2px #243678;
  /* box-shadow: 0px 2px 2px #5C5696; */
  cursor: pointer;
  transition: .2s;
}
 
.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  /* border-color: #6A679E; */
  border-color: #243678;
  outline: none;
}
 
.button__icon {
  font-size: 22px;
  margin-left: auto;
  color: #243678;
  /* color: #7875B5; */
}

.signup-dob {
  margin-left: 20px;
  /* border: 1px solid black; */
  margin-bottom: -20px;
}
 
.pp-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}
 
.pp-forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
 
.signup__div{
  display: flex;
  gap: 8rem;
}
 
.signup__div1{
  display: flex;
  gap: 8rem;
}
 
.signup__heading{
  /* border: 1px solid black; */
  margin-left: 100px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
  width: 80%;
}
 
.signup__field{
  padding: 16px 0px;
  position: relative;
  margin-left: -40px;
}
 
.signup__input{
  border: none;
  border-radius: 20px;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 12px;
  padding-left: 38px;
  font-weight: 700;
  width: 120%;
  transition: .2s;
}
 
.signup__input:active,
.signup__input:focus,
.signup__input:hover {
  outline: none;
  border-bottom-color: #243678;
}
 
.signup__submit{
  background: #fff;
  margin-top: -50px;
 
  padding: 8px 20px;
  border-radius: 26px;
  border: 1px solid #D4D3E8;
  display: flex;
  align-items: center;
  width: 60%;
  /* color: #4C489D; */
  color: #243678;
  box-shadow: 0px 2px 2px #243678;
  /* box-shadow: 0px 2px 2px #5C5696; */
  cursor: pointer;
  transition: .2s;
}
 
.signup__submit:active,
.signup__submit:focus,
.signup__submit:hover {
  /* border-color: #6A679E; */
  border-color: #243678;
  outline: none;
}
 
.save__button{
  border: none;
  font-size: 14px;
  font-weight: 700;
  background-color: #fff;
  text-transform: uppercase;
}
 
.signin__button{
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 5px ;
  background-color: none;
  text-decoration: underline;
  cursor: pointer;
}
 
.signin__button:hover{
  transform: scale(1.1);
}
 
.signin__button:active {
  border: none;
  outline: none;
}
 
.social__text{
  margin-top: 10px;
}
 
.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%; /* Adjust this to move the form to the right */
  width: 50%; /* Adjust this to change the width of the form */
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}
 
.ss-container form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  flex-direction: column;
  padding: 0rem 5rem ;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
 
.ss-container form.sign-up-form {
  opacity: 0;
  z-index: 1;
}
 
.ss-container form.sign-in-form {
  z-index: 2;
}
 
.title {
  font-size: 2.2rem;
  color: #444;
  font-weight: 700;
  margin-bottom: 10px;
  font-family:'Times New Roman', Times, serif;
}
 
.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0; /* Change this to modify input background color */
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}
 
.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
  width: 140%;
  border: none;
  /* border: 1px solid black; */
  margin-left: 30px;
  margin-top: 10px;
}
 
.social-media {
  display: flex;
  justify-content: center;
}
 
.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}
 
.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}
 
.btn {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}
 
.btn:hover {
  background-color: #4d84e2;
}
 
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
 
.pp-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 1900px;
  top: -10%;
  right: 56%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg,#122361 0%, #5f75c4 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}
 

 
.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}
 
.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}
 
.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}
 
.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}
 
.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}
 
.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}
 
.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}
 

 
.buttons-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-around;
  width: 100%;
  z-index: 5;
}
 
.left-panel-btn {
  left: 5%;
}
 
.right-panel-btn {
  right: 5%;
}
 
 
/* ANIMATION */
 
.pp-container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 48%;
}
 

 
.pp-container.sign-up-mode .signin-signup {
  left: 25%;
}
 
.pp-container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}
 
.pp-container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

 
.pp-container.sign-up-mode .left-panel {
  pointer-events: none;
}
 
.pp-container.sign-up-mode .right-panel {
  pointer-events: all;
}
 

.btn-signup {
  /* width: 150px; */
  /* background-color: #5995fd; */
  border: none;
  /* outline: none; */
  /* height: 49px; */
  /* border-radius: 49px; */
  /* color: #fff; */
  text-transform: uppercase;
  font-weight: 600;
  margin-left:5px;
  text-decoration: underline;
  cursor: pointer;
  /* background-color: #fff; */
  /* border: 1px solid black; */
  transition: 0.5s;
  /* transition: background-color 0.3s, border 0.3s;  */
}
 
.btn-signup:hover{
  transform: scale(1.1);
}
 
.btn-signup:active {
 border: none;
 outline: none;
}
 
 
.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}
 
 
 
@media (320px <= width <= 375px) {
  .pp-container:before {
    right: 100%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg,#122361 0%, #5f75c4 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }
  .input-field{
   
    height:75px;
  }

  .login__field{
    padding: 10px 0px;
  }
 
  .login__icon{
    position: absolute;
    top: 20px;
    /* color: #7875B5; */
    color: #243678;
    margin-left: 5px;
  }

  .login__submit{
    font-size: 14px;
    margin-top: 10px;
    padding: 10px 20px;
    width: 100%;
    height: 40px;
  }
  
  .forget-pass{
    font-size: 14px;
  }
 
  .pp-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 1%;
  }
 
  .title {
    font-size: 1.2rem;
    color: #444;
    font-weight: 700;
    margin-top: -5px;
    margin-bottom: 0px;
    font-family:'Times New Roman', Times, serif;
  }
 
  .signinheading{
    border: 1px solid black;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 91%;
    height: 400px;
  }
  .signup__div{
    display: block;
    margin-left: 20px;
    margin-right: 30px;
    /* flex-flow: column; */
    /* gap: 1rem; */
  }

  .signup__field{
    padding-top: 10px;
    padding-bottom: 0px;
    margin-left: -20px;
  }
 
  .social__text{
    margin-top: 0px;
    margin-left: -35px;
    font-size: 11px;
  }
 
  .signin__button{
    margin-left: 0px ;
    font-size: 14px;
  }
 
  .social-text{
    margin-left: 0px;
    font-size: 13px;
    width: 180%;
  }
 
  .btn-signup {
    margin-left: 10px ;
   
  }
 
  .signin__hompage{
    margin-top: -20px;
    font-size: 12px;
    margin-left: -10px;
  }
 
  
  .signup__div1{
    display: block;
    /* flex-flow: column;
    gap: 1rem; */
  }
   
  .signup__heading{
    /* border: 1px solid black; */
    margin-left: 100px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 60%;
    height:430px;
  }
  .signup__input{
    padding: 11px;
    border-bottom: 2px solid #D1D1D4;
    padding-left: 38px;
    font-weight: 700;
    width: 120%;
    height: 40px;
  }
 
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }
 
  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }
 
  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
 
  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }
 
  .right-panel {
    grid-row: 3 / 4;
  }
 
  .left-panel {
    grid-row: 1 / 2;
  }
 
  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }
 
  .panel h3 {
    font-size: 1.2rem;
  }
 
  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }
 
  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }
 
  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
 
  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

 
  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
 
  .signup__submit{
    background: #fff;
    margin-top: -110px;
    margin-bottom: 10px;
    margin-left: 0px;
    height: 30px;
    width: 120px;
  }
  .save__button{
    width:220px;
    margin-top: 10px;
    font-size: 12px;
    margin-left: -10px;
    /* margin-right: -50px; */
  }
}

@media (360px <= width <= 414px) {
  .pp-container:before {
    right: 100%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg,#122361 0%, #5f75c4 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }
  .input-field{
   
    height:75px;
  }

  .login__field{
    padding: 20px 0px;
  }
 
  .login__icon{
    position: absolute;
    top: 28px;
    /* color: #7875B5; */
    color: #243678;
    margin-left: 5px;
  }

  .login__submit{
    font-size: 14px;
    margin-top: 30px;
    padding: 10px 20px;
    width: 100%;
  }
  
  /* .forget-pass{
    font-size: 14px;
  } */
 
  .pp-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 1%;
  }
 
  .title {
    font-size: 2.2rem;
    color: #444;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family:'Times New Roman', Times, serif;
  }
 
  .signinheading{
    border: 1px solid black;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 91%;
    height: 500px;
  }
  .signup__div{
    display: block;
    margin-left: 20px;
    margin-right: 30px;
  }
 
  .social__text{
    margin-top: 10px;
    margin-left: -35px;
  }
 
  .signin__button{
    margin-left: 0px ;
    font-size: 12px;
  }
 
  .social-text{
    margin-left: 60px;
    font-size: 15px;
    width: 180%;
  }
 
  .btn-signup {
    margin-left: 10px ;
   
  }
 
  .signin__hompage{
    margin-top: -10px;
    margin-left: -10px;
  }
 
  .signup__div{
    display: block;
    flex-flow: column;
    gap: 1rem;
  }
  .signup__div1{
    display: block;
    /* flex-flow: column;
    gap: 1rem; */
  }
   
  .signup__heading{
    /* border: 1px solid black; */
    margin-left: 110px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 70%;
    height:560px;
  }
  .signup__input{
    padding: 11px;
    border-bottom: 2px solid #D1D1D4;
    padding-left: 38px;
    font-weight: 700;
    width: 130%;
  }
 
  .signup__field{
    padding-top: 20px;
    padding-bottom: 0px;
    margin-left: -50px;
  }
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }
 
  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }
 
  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
 
  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }
 
  .right-panel {
    grid-row: 3 / 4;
  }
 
  .left-panel {
    grid-row: 1 / 2;
  }
 
  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }
 
  .panel h3 {
    font-size: 1.2rem;
  }
 
  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }
 
  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }
 
  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
 
  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

 
  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
 
  .signup__submit{
    background: #fff;
    margin-top: -100px;
    margin-bottom: 10px;
    width: 150px;
  }
  .save__button{
    width:220px;
    margin-top: 10px;
    /* margin-right: -50px; */
  }
}

@media (375px <= width <= 414px) {
  .pp-container:before {
    right: 100%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg,#122361 0%, #5f75c4 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }
  .input-field{
   
    height:75px;
  }

  .login__field{
    padding: 20px 0px;
  }
 
  .login__icon{
    position: absolute;
    top: 28px;
    /* color: #7875B5; */
    color: #243678;
    margin-left: 5px;
  }

  .login__submit{
    font-size: 14px;
    margin-top: 30px;
    padding: 10px 20px;
    width: 100%;
  }
  
  /* .forget-pass{
    font-size: 14px;
  } */
 
  .pp-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 1%;
  }
 
  .title {
    font-size: 2.2rem;
    color: #444;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family:'Times New Roman', Times, serif;
  }
 
  .signinheading{
    border: 1px solid black;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 91%;
    height: 500px;
  }
  .signup__div{
    display: block;
    margin-left: 20px;
    margin-right: 30px;
  }
 
  .social__text{
    margin-top: 10px;
    margin-left: -35px;
  }
 
  .signin__button{
    margin-left: 0px ;
    font-size: 13px;
  }
 
  .social-text{
    margin-left: 60px;
    font-size: 15px;
    width: 180%;
  }
 
  .btn-signup {
    margin-left: 10px ;
   
  }
 
  .signin__hompage{
    margin-top: -10px;
    margin-left: -10px;
  }
 
 
  .signup__div1{
    display: block;
    /* flex-flow: column;
    gap: 1rem; */
  }
   
  .signup__heading{
    /* border: 1px solid black; */
    margin-left: 115px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 70%;
    height:600px;
  }
  .signup__input{
    padding: 11px;
    border-bottom: 2px solid #D1D1D4;
    padding-left: 38px;
    font-weight: 700;
    width: 130%;
  }
 
  .signup__field{
    padding-top: 15px;
    padding-bottom: 0px;
    margin-left: -50px;
  }
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }
 
  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }
 
  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
 
  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }
 
  .right-panel {
    grid-row: 3 / 4;
  }
 
  .left-panel {
    grid-row: 1 / 2;
  }
 
  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }
 
  .panel h3 {
    font-size: 1.2rem;
  }
 
  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }
 
  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }
 
  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
 
  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

 
  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
 
  .signup__submit{
    background: #fff;
    margin-top: -100px;
    margin-bottom: 10px;
    width: 150px;
  }
  .save__button{
    width:220px;
    margin-top: 10px;
    /* margin-right: -50px; */
  }
}

@media (414px <= width <= 568px) {
  .pp-container:before {
    right: 100%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg,#122361 0%, #5f75c4 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }
  .input-field{
   
    height:75px;
  }

  .login__field{
    padding: 20px 0px;
  }
 
  .login__icon{
    position: absolute;
    top: 28px;
    /* color: #7875B5; */
    color: #243678;
    margin-left: 5px;
  }

  .login__submit{
    font-size: 14px;
    margin-top: 30px;
    padding: 10px 20px;
    width: 100%;
  }
  
  /* .forget-pass{
    font-size: 14px;
  } */
 
  .pp-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 1%;
  }
 
  .title {
    font-size: 2.2rem;
    color: #444;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family:'Times New Roman', Times, serif;
  }
 
  .signinheading{
    border: 1px solid black;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 91%;
    height: 500px;
  }
  .signup__div{
    display: block;
    margin-left: 20px;
    margin-right: 30px;
  }
 
  .social__text{
    margin-top: 10px;
    margin-left: -35px;
  }
 
  .signin__button{
    margin-left: 0px ;
    font-size: 14px;
  }
 
  .social-text{
    margin-left: 120px;
    font-size: 15px;
    width: 180%;
  }
 
  .btn-signup {
    margin-left: 10px ;
   
  }
 
  .signin__hompage{
    margin-top: -10px;
    margin-left: -10px;
  }
 
  .signup__div{
    display: block;
    flex-flow: column;
    gap: 1rem;
  }
  .signup__div1{
    display: block;
    /* flex-flow: column;
    gap: 1rem; */
  }
   
  .signup__heading{
    /* border: 1px solid black; */
    margin-left: 125px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 75%;
    height:610px;
  }
  .signup__input{
    padding: 11px;
    border-bottom: 2px solid #D1D1D4;
    padding-left: 38px;
    font-weight: 700;
    width: 130%;
  }
 
  .signup__field{
    padding-top: 15px;
    padding-bottom: 0px;
    margin-left: -50px;
  }
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }
 
  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }
 
  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
 
  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }
 
  .right-panel {
    grid-row: 3 / 4;
  }
 
  .left-panel {
    grid-row: 1 / 2;
  }
 
  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }
 
  .panel h3 {
    font-size: 1.2rem;
  }
 
  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }
 
  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }
 
  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }
 
  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

 
  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
 
  .signup__submit{
    background: #fff;
    margin-top: -100px;
    margin-bottom: 10px;
    width: 150px;
  }
  .save__button{
    width:220px;
    margin-top: 10px;
    /* margin-right: -50px; */
  }
}

@media (768px <= width <= 820px) {}

@media (820px <= width <= 834px) {}

@media (834px <= width <= 1194px) {}

@media (800px <= width <= 834px) {}

@media (884px <= width <= 1024px) {}
 
@media (428px <= width <= 926px){
  .signup__heading{
    /* border: 1px solid black; */
    margin-left: 127px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 80%;
    height:610px;
  }
 
  .social-text{
    margin-left: 140px;
  }
}
 
@media (768px <= width <= 1024px) {
  form {
    padding: 0 1.5rem;
  }
 
  .pp-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 50%;
  }
 
  .signup__heading{
    margin-left: 35px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 110%;
    height:700px;
    margin-top: 200px;
  }
 
  /* .signup__div{
    display: block;
    margin-left: 20px;
    margin-right: 30px;
  } */
 
  .signup__div{
    display: block;
    flex-flow: column;
    gap: 1rem;
  }
 
  .signup__input{
    padding: 11px;
    border-bottom: 2px solid #D1D1D4;
    padding-left: 38px;
    font-weight: 700;
    width: 95%;
  }
 
  .signup__field{
    padding-top: 13px;
    margin-left: 20px;
  }
 
  .signup__submit{
    /* margin-top: -100px; */
    margin-bottom: 10px;
    margin-top: -60px;
    padding-top: 0px;
    width: 150px;
  }
  .save__button{
    width:220px;
    margin-top: 10px;
    /* margin-right: -50px; */
  }
 
  .signinheading{
    border: 1px solid black;
    margin-left: -50px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgb(3, 3, 71);
    width: 100%;
    height: 500px;
  }
 
  .pp-container:before {
    right: 50%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg,#122361 0%, #5f75c4 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }
  .social-text {
    margin-left: 80px;
    margin-top: 10px;
  }
 
  /* .image {
    display: none;
  } */
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container {
    padding: 1.5rem;
  }
 
  .container:before {
    bottom: 72%;
    left: 50%;
  }
 
  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}