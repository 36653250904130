.Resp-payin-main {
    display: flex;
    height: 100vh;
    width: 100%;
}
  
.Resp-payin-full {
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}
  
.Resp-payin-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}
  
.Resp-payin-heading h2{
    font-size: 30px;
}


@media (320px <= width <= 375px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 80vw; 
    }
      
    .Resp-payin-heading h2{
        font-size: 15px;
    }
}

@media (360px <= width <= 414px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 80vw; 
    }
      
    .Resp-payin-heading h2{
        font-size: 15px;
    }
}

@media (375px <= width <= 414px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 80vw; 
    }
      
    .Resp-payin-heading h2{
        font-size: 15px;
    }
}

@media (414px <= width <= 568px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 80vw; 
    }
      
    .Resp-payin-heading h2{
        font-size: 15px;
    }
}

@media (768px <= width <= 820px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 80vw; 
    }
}

@media (820px <= width <= 834px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 70vw; 
    }
    
}

@media (834px <= width <= 1194px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 70vw; 
    }
}

@media (800px <= width <= 834px) {
    .Resp-payin-full {
        overflow: auto;
        overflow-x: hidden;
    }
      
    .Resp-payin-head {
        width: 70vw; 
    }
}

@media (884px <= width <= 1024px) {}