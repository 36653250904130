.fp_forgetboxouter {
    /* background: linear-gradient(to bottom right, #8cb9c3, #6889bb, #78b1bf, #7cb2c0); */
     background-image: url('../images/newimageforget.avif');
    /* Unique gradient background */
    background-size: cover; /* This makes the background image cover the entire container */
    background-repeat: no-repeat; /* This prevents the background image from repeating */
    background-position: center; 
    height: 100vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Arial', sans-serif;

  }
 
 
  .fp_back{
    color: black;
    font-size: 18px;
    text-decoration: none;
  }
  .fp_back:hover{
    text-decoration: none;
  }
  .fp_back .zoom-effect-p {
    transition: transform 0.3s ease; /* Add transition for smooth zoom effect */
  }
  .fp_back:hover .zoom-effect-p {
    transform: scale(1.1); /* Apply zoom effect on hover */
  }
  .fp_forgetpass {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
   
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 350px;
    text-align: center;
    color: #333;
  }
 
  .fp_forgetpass .lo-inp {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
 
  .fp_forgetpass .loinp-but {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background: #05144c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s;
  }
 
  .fp_forgetpass .loinp-but:hover {
    background:   #05144c;
  }
 
  .fp_forgetpass .loinp-but:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
 
  .fp_forgetpass p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
 
  .fp_forgetpass a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
  }
 
  .fp_forgetpass a:hover {
    text-decoration: underline;
  }
 
  .fp_forgetpass .lo-inp::placeholder {
    color: #999;
  }
 
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .fp_forgetpass {
      width: 90%;
    }
  }
 