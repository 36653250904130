.admin-editmemberdetail-main {
    display: flex;
    height: 100vh;
}

.Ad-editmemberdetails-full {
    width: 100%;
    flex-grow: 1;
    padding: 10px;
    /* overflow-y: auto; */
    transition: all 0.3s ease;
    width: calc(100% - 250px);
}

.Ad-editmemberdetails-head {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Ad-editmemberdetails-heading h2{
    font-size: 30px;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-editmemberdetails-heading h2{
        font-size: 20px;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (360px <= width <= 414px) {
    .Ad-editmemberdetails-heading h2{
       font-size: 20px;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (375px <= width <= 414px) {
    .Ad-editmemberdetails-heading h2{
        font-size: 20px;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (414px <= width <= 568px) {
    .Ad-editmemberdetails-heading h2{
        font-size: 20px;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (768px <= width <= 820px) {
    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (820px <= width <= 834px) {
    .Ad-editmemberdetails-heading h2{
        font-size: 25px;
    }

    .Ad-editmemberdetails-head {
        width: 70vw;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-editmemberdetails-heading h2{
        font-size: 25px;
    }

    .Ad-editmemberdetails-head {
        width: 70vw;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (800px <= width <= 834px) {
    .Ad-editmemberdetails-heading h2{
        font-size: 25px;
    }

    .Ad-editmemberdetails-head {
        width: 70vw;
    }

    .Ad-editmemberdetails-full {
        overflow-y: auto;
    }
}

@media (884px <= width <= 1024px) {}