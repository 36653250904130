.Ad-paymentcollectiondetails-main {
    display: flex;
    /* width: 100%; */
    height: 100vh;
}

.Ad-paymentcollectiondetails-mm {
    width:100%;
    height: 100vh;
    overflow-y: hidden;
}

.Ad-paymentcollectiondetails-full {
    margin: 10px;
    /* width:100%; */
    height:auto;
}

.Ad-paymentcollectiondetails-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-paymentcollectiondetails-heading h2{
    font-size: 30px;
}

@media (320px <= width <= 375px) {
    .Ad-paymentcollectiondetails-heading h2{
        font-size: 15px;
    }
}

@media (360px <= width <= 414px) {
    .Ad-paymentcollectiondetails-heading h2{
        font-size: 18px;
    }
}

@media (375px <= width <= 414px) {
    .Ad-paymentcollectiondetails-heading h2{
        font-size: 18px;
    }
}

@media (414px <= width <= 568px) {
    .Ad-paymentcollectiondetails-heading h2{
        font-size: 18px;
    }
}

@media (768px <= width <= 820px) {
}

@media (820px <= width <= 834px) {

    .Ad-paymentcollectiondetails-head {
        width: 70vw; 
    }
}

@media (834px <= width <= 1194px) {

    .Ad-paymentcollectiondetails-head {
        width: 70vw; 
    }
}

@media (800px <= width <= 834px) {

    .Ad-paymentcollectiondetails-head {
        width: 70vw; 
    }
}

@media (884px <= width <= 1024px) {}