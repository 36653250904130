.super-dashboard-main {
  width: 100%;
  display: flex;
  height: 100vh;
}

.super-dashboard-full {

  width: 100%;
  /* flex-grow: 1; */
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  /* width: calc(100% - 250px); */
  height: 100vh;
  /* margin: 5px; */
  /* margin-left: 5px; */
}

.super-dashboard-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 80vw;
}

.super-dashboard-heading h2{
  font-size: 30px;
}

.super-dashboard-cards-1 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 50px;
  width:75vw;
  /* border:1px solid black; */
}

.super-dashboard-cards-1 .super-dashboard-card {
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 130px;
  width: 250px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
  margin-left: 20px;
}

.super-dashboard-cards-1 .super-dashboard-card p .super-dashboard-tip {
  font-size: 1em;
  font-weight: 700;
}

.super-dashboard-cards-1 .red {
  background-color: #f43f5e;
}

.super-dashboard-cards-1 .blue {
  background-color: #3b82f6;
}

.super-dashboard-cards-1 .green {
  background-color: #22c55e;
}

.super-dashboard-cards-1 .purple {
  background-color: #9722c5;
}

.super-dashboard-second-part{
  /* border:1px solid black; */
  margin: 20px;
  margin-left: 0px;
  display: flex;
  gap:1rem;
  width:75vw;
}

.super-dashboard-chartdata{
  margin-top: 10px;
  width:600px;
}

.super-dashboard-chartdata h4{
  margin-left:50px;
}

.super-dashboard-container-chart{
  width:500;
  height:350;
 
}

/* Responsive */

@media (320px <= width <= 375px) {
  .super-dashboard-heading h2{
    font-size: 20px;
  }

  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 240px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .super-dashboard-second-part{
    margin-left: -30px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (360px <= width <= 414px) {}

@media (375px <= width <= 414px) {
  .super-dashboard-heading h2{
    font-size: 20px;
  }

  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 240px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .super-dashboard-second-part{
    margin-left: -30px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (414px <= width <= 568px) {
  .super-dashboard-heading h2{
    font-size: 20px;
  }

  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 35px;
  }

  .super-dashboard-second-part{
    margin-left: 0px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (768px <= width <= 820px) {

  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 200px;
  }

  .super-dashboard-second-part{
    margin-left: 160px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (820px <= width <= 834px) {
  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .super-dashboard-head {
    width: 70vw;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 140px;
  }

  .super-dashboard-second-part{
    margin-left: 100px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (834px <= width <= 1194px) {
  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .super-dashboard-head {
    width: 70vw;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 140px;
  }

  .super-dashboard-second-part{
    margin-left: 100px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (800px <= width <= 834px) {
  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .super-dashboard-head {
    width: 70vw;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .super-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 140px;
  }

  .super-dashboard-second-part{
    margin-left: 100px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (884px <= width <= 1024px) {
  .super-dashboard-full {
    overflow: auto;
    overflow-x: hidden;
  }

  .super-dashboard-head {
    width: 70vw;
  }
  
  .super-dashboard-cards-1 {
    display: block;
  }

  .super-dashboard-cards-1 .Ad-dashboard-card {
    width: 280px;
    margin-top: 20px;
    margin-left: 180px;
  }

  .super-dashboard-second-part{
    margin-left: 140px;
    display:'flex';
    flex-direction: column;
  }

  .super-dashboard-chartdata h4{
    margin-left:50px;
    font-size: 20px;
  }

  .super-dashboard-container-chart{
    width:100px;
    height:200;
    margin-left: -20px;
  }
}

@media (1024px <= width <= 1280px) {
  .super-dashboard-second-part{
    margin-left: -25px;
  }
}