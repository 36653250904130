.lg-contact-info{
    display:flex;
    margin-top:120px;
}
 
.lg-contact-textt{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 600;
    font-size: 15px;
    margin-top: 20px;
 
}
.lg-containerr{
    margin-top: 120px;;
}
.lg-contact-1{
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    /* margin-top: 30px; */
    text-align: center;
    margin-right: 0px;
    /* margin-left: 40px; */
    font-size: 39px;
}
.lg-contact-textt1{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 600;
    font-size: 15px;
    margin-left: 20px;;
color:rgb(206, 146, 146);
}
.lg-text-section1{
    display:flex;
    gap:5rem;
    margin-top:20px;
}
.lg-cards{
    border:1px solid rgb(247, 245, 245);
    box-shadow: 2px 5px 5px 2px rgb(219, 211, 211);
    border-radius: 5px;
    width:350px;
    color:black;
    margin-top: 40px;
    height:200px;
    font-family: 'Times New Roman', Times, serif;
   
}
.lg-text-head{
    font-size: 17px;
    font-weight: 600;
    margin-left: 20px;
   
    margin-top: 30px;
}
.lg-cont{
    margin-left: 130px;
}
.lg-para-text{
    font-weight: 400;
    margin: 20px;
 
}
.lg-text-section20{
    margin-left: 200px;
}
.lg-head-text{
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    margin-left: 20px;
    margin-top:-30px;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .lg-contact-info{
        display: block;
        margin-top: 40px;
    }
    .lg-cont{
        margin-left: 130px;
    }

    .lg-cont h6{
        margin-left: -50px;
    }

    .lg-contact-textt1{
        margin-left: -20px;
        margin-top: 5px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -210px;
        margin-top: 50px;
    }
    .lg-cards{
        width:270px;
        margin-left:-10px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (360px <= width <= 414px) {
    .lg-contact-info{
        display: block;
        margin-top: 40px;
    }
    .lg-cont{
        margin-left: 130px;
    }

    .lg-cont h6{
        margin-left: -20px;
    }

    .lg-contact-textt1{
        margin-left: 10px;
        margin-top: 5px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 0px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -210px;
        margin-top: 50px;
    }
    .lg-cards{
        width:300px;
        margin-left:0px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (375px <= width <= 414px) {
    .lg-contact-info{
        display: block;
        margin-top: 40px;
    }
    .lg-cont{
        margin-left: 130px;
    }

    .lg-cont h6{
        margin-left: -20px;
    }

    .lg-contact-textt1{
        margin-left: 10px;
        margin-top: 5px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -210px;
        margin-top: 50px;
    }
    .lg-cards{
        width:300px;
        margin-left:0px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (414px <= width <= 568px) {
    .lg-contact-info{
        display: block;
        margin-top: 40px;
    }
    .lg-cont{
        margin-left: 130px;
    }

    .lg-cont h6{
        margin-left: 0px;
    }

    .lg-contact-textt1{
        margin-left: 30px;
        margin-top: 5px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -210px;
        margin-top: 50px;
    }
    .lg-cards{
        width:300px;
        margin-left:20px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (768px <= width <= 820px) {
    .lg-contact-info{
        display: flex;
        margin-top: 60px;
    }
    .lg-cont{
        margin-left: 130px;
        margin-top: 150px;
    }

    .lg-cont h6{
        margin-left: -60px;
    }

    .lg-contact-textt1{
        margin-left: -30px;
        margin-top: 5px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -90px;
        margin-top: 0px;
    }
    .lg-cards{
        width:300px;
        margin-left:100px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (820px <= width <= 834px) {}

@media (834px <= width <= 1194px) {
    .lg-contact-info{
        display: flex;
        margin-top: 60px;
    }
    .lg-cont{
        margin-left: 130px;
        margin-top: 150px;
    }

    .lg-cont h6{
        margin-left: -60px;
        font-size: 16px;
    }

    .lg-contact-textt1{
        margin-left: -30px;
        margin-top: 5px;
        font-size: 16px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -90px;
        margin-top: 0px;
    }
    .lg-cards{
        width:300px;
        margin-left:120px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (800px <= width <= 834px) {
    .lg-contact-info{
        display: flex;
        margin-top: 60px;
    }
    .lg-cont{
        margin-left: 130px;
        margin-top: 150px;
    }

    .lg-cont h6{
        margin-left: -60px;
        font-size: 16px;
    }

    .lg-contact-textt1{
        margin-left: -30px;
        margin-top: 5px;
        font-size: 16px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -90px;
        margin-top: 0px;
    }
    .lg-cards{
        width:300px;
        margin-left:100px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (884px <= width <= 1024px) {
    .lg-contact-info{
        display: flex;
        margin-top: 60px;
    }
    .lg-cont{
        margin-left: 130px;
    }

    .lg-cont h6{
        margin-left: -60px;
    }

    .lg-contact-textt1{
        margin-left: -30px;
        margin-top: 5px;
    }

    .lg-contact-1{
        text-align: center;
        margin-right: 10px;
    }
    .lg-text{
        display:block;
    }
    .lg-text-section1{
        margin-left:-200px;
        display: block;
     
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    .lg-head-text{
        text-align: center;
        margin-left: -90px;
        margin-top: 0px;
    }
    .lg-cards{
        width:300px;
        margin-left:120px;
    }
    .lg-contact-textt{
        font-size: 16px;
     
    }
}

@media (1024px <= width <= 1280px) {
    .lg-cards{
        width:350px;
        margin-left:-50px;
    }
}







/* @media (768px <= width <= 1024px) {
    .lg-text-section1{
        margin-left:-40px;
        display: block;
    }
    .lg-cards{
        width:300px;
    }
    .lg-text-head{
        font-size: 17px;
        margin-left: 20px;
       
        margin-top: 20px;
    }
    .lg-para-text{
        margin-left: 12px;
        margin-right: 10px;
        margin-top: 20px;
     
    }
    
} */