.collectingagent-dash{
    width: 100%;
    height: 100vh;
    display: flex;
}

.CA-dash-f{
    width:100%;
    height: 100vh;
    overflow-y: hidden;

    flex-grow: 1;
    padding: 10px;
    /* overflow-y: auto; */
    /* transition: all 0.3s ease; */
    /* width: calc(100% - 250px);  */
}

.CA-courses-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    /* width: 80vw;  */
}

.CA-h2-heading h2{
    font-size: 30px;
}

.CA-table-dashboard{
    max-height:400px;
    overflow-y: scroll;
    width: 980px;
    /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
    /* border: 1px solid black; */
    margin:20px;
    margin-top: 50px;
    margin-left: 150px;
    overflow-x: auto;
    border: none;
    border-collapse: collapse;
}

.CA-table-style{
    width:63vw;
    border: none;
    border-style: none;
    /* border:1px solid black; */
}

.CA-tables-style .tahe{
    padding: 8px;
    text-align: center;
    border:1px solid white;
}

/* Responsive */

@media (320px <= width <= 375px){
    .CA-h2-heading h2{
        font-size: 20px;
    }

    .CA-table-dashboard{
        width: 230px;
    }
}

@media (360px <= width <= 414px){
    .CA-h2-heading h2{
        font-size: 20px;
    }

    .CA-table-dashboard{
        width: 270px;
    }
}

@media (375px <= width <= 414px){
    .CA-h2-heading h2{
        font-size: 20px;
    }

    .CA-table-dashboard{
        width: 290px;
    }
}

@media (414px <= width <= 568px){
    .CA-h2-heading h2{
        font-size: 20px;
    }

    .CA-table-dashboard{
        width: 320px;
    }
}

@media (300px <= width <= 600px) {
    .CA-table-dashboard{
        max-height:400px;
        /* width: 300px; */
        margin:0px;
        margin-top: 50px;
        margin-left: 10px;
    }
}
@media (768px <= width <= 1024px) {}