.Ad-history-main {
    width: 100%;
    height: 100vh;
    display: flex;
}

.Ad-history-full {
    width: 100%;
    /* flex-grow: 1; */
    padding: 5px;
    /* overflow-x: hidden; */
    transition: all 0.3s ease;
    /* width: calc(100% - 250px); */
    height: 100vh;
    /* margin: 5px; */
    /* margin-left: 5px; */
}

.Ad-history-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    width: 80vw; 
}

.Ad-history-heading h2{
    font-size: 30px;
}

.Ad-book {
    border: 1px solid rgb(239, 235, 235);
    /* border: 1px solid black; */
    margin: 20px;
    /* background-color: whitesmoke; */
}

.Ad-bord{
    /* border:1px solid black; */
    display: flex;
    margin-left: 0px ;
    gap:1rem;
    /* background-color: rgb(238, 230, 230); */
    background-color: #d9d5d5;
    width: 1230px;
    height: 80px;
}

.Ad-do{
    margin-top: 36px;
    margin-left: 30px;
    width: 150px;
    height: 44px;
    background-color: #212188;
    color: whitesmoke;
    border: 1px solid white;
}

.Ad-doo{
    margin-left: 30px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
}

.Ad-bye{
    display: flex;
    margin-top: 60px;
}

.Ad-history-vgt{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 30px;
    padding: 10px;
    width:380px;
}

.Ad-history-vg{
    width: 300px;
    height:39px;
    border:none;
    border-radius: 4px;
    margin-top: -2px;
    margin-bottom: 0px;
    outline: none;
    color: rgb(0,0,0);
    box-shadow: 2px 2px 7px  0 rgb(0, 0, 0, 0.2);
}

.Ad-history-src{
    width: 100px;
    height: 30px;
    margin-left: 550px;
    border-radius: 5px;
    margin-top: 80px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: whitesmoke;
}

.Ad-history-src:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.Ad-history-src:active{
    border: none;
    outline: none;
}

.Ad-history-table-groupid{
    max-height:230px;
    overflow-y: scroll;
    width: 1190px;
    margin:20px;
    overflow-x: auto;
}

/* Responsive */

@media (320px <= width <= 375px) {
    .Ad-history-heading h2{
        font-size: 16px;
    }

    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 240px;
    }

    .Ad-do{
        margin-top: 44px;
        margin-left: 15px;
        width: 100px;
        font-size: 11px;
        height: 35px;
    }
    
    .Ad-doo{
        margin-left: 10px;
        width: 100px;
        height: 35px;
        font-size: 12px;
        margin-top: 42px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 20px;
    }

    .Ad-history-src{
        margin-left: 70px;
    }
    
    .Ad-history-vg{
        width: 200px;
    }

    .Ad-history-table-groupid{
        width: 240px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (360px <= width <= 414px) {
    .Ad-history-heading h2{
        font-size: 16px;
    }

    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 290px;
    }

    .Ad-do{
        margin-top: 44px;
        margin-left: 15px;
        width: 125px;
        font-size: 13px;
        height: 35px;
    }
    
    .Ad-doo{
        margin-left: 10px;
        width: 125px;
        height: 35px;
        font-size: 13px;
        margin-top: 42px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 30px;
    }

    .Ad-history-src{
        margin-left: 70px;
    }
    
    .Ad-history-vg{
        width: 200px;
    }

    .Ad-history-table-groupid{
        width: 280px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (375px <= width <= 414px) {
    .Ad-history-heading h2{
        font-size: 16px;
    }

    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 300px;
    }

    .Ad-do{
        margin-top: 44px;
        margin-left: 15px;
        width: 130px;
        font-size: 13px;
        height: 35px;
    }
    
    .Ad-doo{
        margin-left: 10px;
        width: 130px;
        height: 35px;
        font-size: 14px;
        margin-top: 42px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 40px;
    }

    .Ad-history-src{
        margin-left: 100px;
    }
    
    .Ad-history-vg{
        width: 200px;
    }

    .Ad-history-table-groupid{
        width: 300px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (414px <= width <= 568px) {
    .Ad-history-heading h2{
        font-size: 16px;
    }

    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 340px;
    }

    .Ad-do{
        margin-top: 43px;
        margin-left: 15px;
        width: 150px;
        font-size: 14px;
        height: 38px;
    }
    
    .Ad-doo{
        margin-left: 10px;
        width: 150px;
        height: 35px;
        font-size: 14px;
        margin-top: 42px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 60px;
    }

    .Ad-history-src{
        margin-left: 120px;
    }
    
    .Ad-history-vg{
        width: 200px;
    }

    .Ad-history-table-groupid{
        width: 340px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (768px <= width <= 820px) {

    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 690px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 160px;
    }

    .Ad-history-src{
        margin-left: 280px;
    }
    
    .Ad-history-vg{
        width: 300px;
    }

    .Ad-history-table-groupid{
        width: 680px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (820px <= width <= 834px) {
    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-head {
        width: 70vw; 
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 590px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 120px;
    }

    .Ad-history-src{
        margin-left: 230px;
    }
    
    .Ad-history-vg{
        width: 300px;
    }

    .Ad-history-table-groupid{
        width: 680px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (834px <= width <= 1194px) {
    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-head {
        width: 70vw; 
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 600px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 120px;
    }

    .Ad-history-src{
        margin-left: 230px;
    }
    
    .Ad-history-vg{
        width: 300px;
    }

    .Ad-history-table-groupid{
        width: 600px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (800px <= width <= 834px) {
    .Ad-book {
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        margin: 10px;
        /* background-color: whitesmoke; */
    }

    .Ad-history-head {
        width: 70vw; 
    }

    .Ad-history-full {
        overflow-x: hidden;
    }

    .Ad-bord{
        width: 570px;
    }

    .Ad-bye{
        display: block;
        margin-top: 60px;
    }

    .Ad-history-vgt{
        margin-left: 120px;
    }

    .Ad-history-src{
        margin-left: 230px;
    }
    
    .Ad-history-vg{
        width: 300px;
    }

    .Ad-history-table-groupid{
        width: 560px;
        margin:0px;
        overflow-x: auto;
    }
}

@media (884px <= width <= 1024px) {}










.bye{
    display: flex;
    margin-top: 60px;
}
 
.vgt{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 30px;
  padding: 10px;
  width:380px;
}
 .ADI{
    display:flex;
    width:85vw;
    justify-content: space-between;
    /* border:1px solid black; */
 }
.book{
    border: 1px solid rgb(239, 235, 235);
    /* border: 1px solid black; */
    margin: 20px;
    /* background-color: whitesmoke; */
}


.dos{
    margin-left: 10px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
}
.doos{
    margin-top: 36px;
    width: 150px;
    height: 44px;
    margin-left: 30px;
    /* background-color: white; */
    border: 1px solid white;
    background-color: #212188;
    color: whitesmoke;
}
 
.vt{
 width: 300px;
 border: none;
 border-radius: 4px;
 box-shadow: 2px 2px 7px  0 rgb(0, 0, 0, 0.2);
 outline: none;
 color: rgb(0, 0, 0);
}
 
.vg{
    width: 300px;
    height:39px;
    border:none;
    border-radius: 4px;
    margin-top: -2px;
    margin-bottom: 0px;
    outline: none;
    color: rgb(0,0,0);
    box-shadow: 2px 2px 7px  0 rgb(0, 0, 0, 0.2);
}
 
.src{
    width: 100px;
    height: 30px;
    margin-left: 550px;
    border-radius: 5px;
    margin-top: 100px;
    border: none;
    outline: none;
    background-color: #19ab58;
    color: whitesmoke;
}

.src:hover{
    transform: scale(1.1);
    border: none;
    outline: none;
}

.src:active{
    border: none;
    outline: none;
}
 
.doo{
    margin-left: 30px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
}
 
.do{
    margin-top: 36px;
    margin-left: 30px;
    width: 150px;
    height: 44px;
    background-color: #212188;
    color: whitesmoke;
    border: 1px solid white;
}
 
/************************** Transdate******************/
 
.bord{
    /* border:1px solid black; */
    display: flex;
    margin-left: 0px ;
    gap:1rem;
    /* background-color: rgb(238, 230, 230); */
    background-color: #d9d5d5;
    width: 1230px;
    height: 80px;
}

.bord-1{
    /* border:1px solid black; */
    display: flex;
    margin-left: 0px ;
    gap:1rem;
    /* background-color: rgb(238, 230, 230); */
    background-color: #d9d5d5;
    width: 1240px;
    height: 80px;
}

.doos{
    margin-top: 36px;
    width: 150px;
    height: 44px;
    margin-left: 30px;
    /* background-color: white; */
    border: 1px solid white;
    background-color: #212188;
    color: whitesmoke;
}
 
.dos{
    margin-left: 30px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
}
 
/* .doos{
    margin-top: 36px;
    width: 150px;
    height: 44px;
    margin-left: 30px;
    border: 1px solid white;
    background-color: #212188;
    color: whitesmoke;
}
 
.dos{
    margin-left: 10px;
    width: 150px;
    height: 44px;
    margin-top: 36px;
    color: black;
    border: 1px solid white;
    background-color: white;
} */
 
 .veg{
    width: 300px;
    height:39px;
    border:none;
    border-radius: 4px;
    margin-top: -2px;
    margin-bottom: 0px;
    margin-left: 70px;
    outline: none;
    color: rgb(0,0,0);
    box-shadow: 2px 2px 7px  0 rgb(0, 0, 0, 0.2);
}
 
   .vgtd{
    display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 120px;
  padding: 10px;
  width:380px;
   }
 
  .poor{
      /* margin-left: 420px; */
      display: flex;
      margin-top: 50px;
      /* border: 1px solid black; */
      /* display: flex; */
  }
 
   .vf{
    width:100px;
    margin-left: 170px;
 }

.table-byuser{
    max-height:400px;
    overflow-y: scroll;
    width: 1190px;
    margin:20px;
    overflow-x: auto;
}

.table-groupid{
    max-height:230px;
    overflow-y: scroll;
    width: 1190px;
    margin:20px;
    overflow-x: auto;
}
 
 
 
@media (300px <= width <= 600px) {
    .bye{
        display: block;
        margin-top: 60px;
    }

    .table-byuser{
        width: 280px;
    }

    .table-groupid{
        width: 280px;
    }
 
    .vgt{
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 10px;
        padding: 5px;
        width:300px;
      }
 
    .src{
        width: 70px;
        height: 30px;
        margin-left: 130px;
        margin-top: 40px;
        border-radius: 5px;
        background-color: #19ab58;
        color: whitesmoke;
    }

    .vt{
        width: 250px;
    }

    .vg{
        width: 250px;
    }
 
    .book{
        border: 1px solid rgb(239, 235, 235);
        /* border: 1px solid black; */
        width: 320px;
        margin-left: 5px;
    }
 
    .doo{
        margin-left: 10px;
        width: 120px;
        height: 34px;
        margin-top: 17px;
        color: black;
        border: 1px solid white;
        background-color: white;
    }
   
    .do{
        margin-top: 17px;
        margin-left: 10px;
        width: 140px;
        height: 34px;
        background-color: #212188;
        color: whitesmoke;
        border: 1px solid white;
    }
 
    /**********Transdate********/
 
    .poor{
        display: block;
        margin-left: -50px;
        margin-top: 80px;
        /* margin-right: 10px; */
    }
 
    .vf{
        /* width:100px; */
        margin-left: -50px;
    }
 
    .veg{
        width: 250px;
        height:39px;
        border-radius: 4px;
        margin-bottom: 0px;
        margin-left: -50px;
        outline: none;
        color: rgb(0,0,0);
        box-shadow: 2px 2px 7px  0 rgb(0, 0, 0, 0.2);
    }
 
    .bord{
        /* border:1px solid rgb(17, 8, 8); */
        display: flex;
        margin-left:0px ;
        gap:3rem;
        width: 320px;
        height: 50px;
    }

    .doos{
        margin-top: 17px;
        width: 120px;
        height: 34px;
        margin-left: -25px;
        background-color: #212188;
        border: 1px solid white;
    }
   
    .dos{
        margin-left: 10px;
        width: 140px;
        height: 34px;
        margin-top: 17px;
        color: black;
        border: 1px solid white;
        background-color: white;
    }
}

@media (768px <= width <= 1024px) {
    .vgt{
        margin-left: 10px;
        padding: 10px;
        width:230px;
      }

      .table-byuser{
        width: 520px;
    }

    .table-groupid{
        width: 520px;
    }
 
      .vt{
        width: 200px;
       }
 
       .vg{
        width: 200px;
    }

    
 
 
   /************************** Transdate******************/
 
   .bord{
        border:1px solid white;
        width: 580px;
    }
 
    .veg{
        width: 200px;
        margin-left: 50px;
    }
   
    .vgtd{
      margin-left: 42px;
      width:300px;
    }
 
    .vf{
        width:100px;
        margin-left: 100px;
    }
 
    .src{
        margin-left: 220px;
    }
}
