 
.tabs2 .nav-pills
.nav-link
{  
    background-color
    :  #060606;  
color
: white;
border-radius: 15px;
 
margin-right: 15px;
margin-bottom: 10px;
}
 
 
.tabs2 .nav-pills
.nav-link.active
{  
background-color
:  #060606;  
color
: white;
font-weight: 500;
}
 
.lg-sect-texte {
    border: 1px solid rgb(205, 197, 197);
    width: 80%;
    background-image: linear-gradient(to bottom right, #060708, #2196F3);
    /* Adjust the gradient colors and direction as needed */
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    height: 280px;
    color:white;
    padding-top: 40px;
    text-align: center;
    margin-left: 10%;
    box-shadow: 2px 4px 2px 1px grey;
}

.lg-card_whyus{
    margin: 50px;
}
 
.lg-why{
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}
@media (300px <= width <= 600px)  {
    /* Styles for medium devices (laptops) */
    .flex-row{
        display:block;
        text-align: center;
        margin-left: 20px;
    }
    .lg-text-section12{
        border:1px solid black;
        width:300px;
        height:400px;
        margin-left: 40px;
    }
    .lg-sect-texte {
        width: 90%;
        height: 360px;
        /* color:rgb(216, 115, 115); */
        padding-top: 40px;
        text-align: center;
        margin-left: 5%;
        box-shadow: 2px 4px 2px 1px grey;
    }
    .lg-card_whyus{
        margin-top: 30px;
        margin-left: 15px;
        margin-right: 15px;
        color: whitesmoke;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .flex-row{
        justify-content: center;
        display:flex;
    }
}
 