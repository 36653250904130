
.info-input-22 {
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
.Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}.info-courses1-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
/* .info-sidebar{
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    width:13vw;
    position: fixed;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
} */
.info{
    display: flex;
    width:100%;
    height:100vh;
}

.informationcss{
  width: 100%;
  height: 100vh;
}

.information{
    padding: 10px;
    /* border:1px solid black; */
    margin-left: 30px;
    width:100%;
}



.info-head{
    display: flex;
    align-items: center;
    /* width:100%; */
    /* border:1px solid black; */
    justify-content: space-between;
}
.info-container{
  width:75vw;
  margin-top: 25px;
  /* border:1px solid black; */

}
.ag-but-de {
  justify-content: right;
  align-items: right;
  text-align: right;
  margin-right: 80px;
}
.ag-but-delete{
  width:100px;
  height:30px;
  border-radius: 10px;
  background-color:rgb(243, 63, 63) ;
  border:none;
  color:White;
  margin-right: -35px;

}
.alluserscss{
  width: 100%;
  height: 100vh;
}
.table-allusers{
   max-height:400px;
    overflow-y: scroll;
    width: 870px;
    margin:20px;
    margin-top: 100px;
    margin-left: 140px;
    overflow-x: auto;
    box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
}
.ag-but-de{
  justify-content: right;
  align-items: right;
  text-align: right;
  margin-right: 80px;
}
.ag-but-delete:hover{
  transform: scale(1.1);

}
.ag-but-delete:active{
  border: none;
  outline: none;

}
.ag-but-delete:focus{
  border: none;
  outline: none;

}
.re{
  width:100px;
  height:30px;
  border-radius: 10px;
  background-color:rgb(243, 63, 63) ;
  border:none;
  color:White;
  margin-right: -35px;
}
.re:hover{
  transform: scale(1.1);
}
.re:active{
  border: none;
  outline: none;
}
.re:focus{
  border: none;
  outline: none;
}
.ntr{
  display:flex;
}
.vv{
  /* margin-right: 20px; */
  /* border:1px solid black; */
  justify-content: right;
  align-items: right;
  text-align: right;
  margin-right: 80px;
}

.ph{
  display:flex;
  gap:3rem;
}

  .info-display-22 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
  }
  
  
  
  .info-display-full-22 {
    margin: 0px;
    margin-left: 0px;
  }
  
  
.info-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 120%;
  /* border: 1px solid black; */
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  /* background-color: cadetblue; */
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.info-popupmessage{
  /* border: 1px solid black; */
  /* background-color: cadetblue; */
  text-align: center;
}

.info-popup :hover{
  /* transform: scale(1.1); */
}

.close{
  /* border: 1px solid black; */
}

.info-popup-inner {
  padding: 20px;
  box-shadow:0px 0px 3px rgb(115, 132, 209);
  /* border: 1px solid black; */
  background-color: cadetblue;
  border-radius: 5px;
  text-align: center;
}

.info-popup-inner h3 {
  margin-top: 0;
}

.info-popup-inner button {
  margin-top: 20px;
}

.info-popup-close{
  /* border: 1px solid black; */
  color: whitesmoke;
  background-color: #05144c;
  width: 60px;
  height: 30px;
  border: none;
  /* box-shadow:0px 0px 3px rgb(213, 208, 208); */

}

.info-popup-close:hover{
  transform: scale(1.1);
  border: none;
}

  @media screen and (max-width:768px){
    .ph{
        display:block;
        margin-left: 6%;
    }
    
    .information{
      margin-left: 0px;
    }
    .input-22{
      width:250px;
    }
    .phs{
      display:block;
    }
    .ag-but-de{  
      margin-right: 60px;
    }
    
  }
  @media screen and (max-width: 1280px){
    .ph{
      display:block;
  }
  .phs{
    display:block;
  }

  .vv{
    /* margin-right: 20px; */
    /* border:1px solid black; */
    
    margin-right: 120px;
  }
  
  }
  
  .ph {
    display: flex;
    gap: 3rem;
  }
  @media screen and (min-width: 766px) and (max-width: 840px)
  {
    .ph{
      display:block;
      margin-left: 10%;
  }
  .phs{
    display:block;
  }

  .vv{
    /* margin-right: 20px; */
    /* border:1px solid black; */
    
    margin-right: 120px;
  }
  
  }
  @media only screen and (min-width: 320px) and (max-width: 359px)
  {
  

    .Arrow{
      margin-top: 130px;
    }
  

}
@media screen and (max-width:360px){
  .info-input-22{
    width: 230px;
    
  }

}

@media screen and (max-width: 1280px) {
  .ph {
      display: block;
  }
}
@media screen and (max-width: 768px) {
  .ph {
      display: block;
      margin-left: 6%;
  }
}
.ph {
  display: flex;
  gap: 3rem;
}
@media screen and (max-width: 1280px) {
  .ph {
      display: block;
  }
}
@media screen and (max-width: 769px) {
  .ph {
      display: block;
  }
}

