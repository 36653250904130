* {
  padding: 0%;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #f8f7f2;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
}

header {
  position:fixed;
  z-index: 1000; /* Ensure the navbar stays on top of other elements */
  top: 0;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 2rem;
  background-color:#05144c;
  color: var(--textColor);
    /* padding-left: 25px; */
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
}
 
nav a:hover {
  color: var(--secondaryColor);
}

header .lg-nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
 
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}
.lg-yaa{
  margin-top:40px;
  width:200px;
  height:150px;
  margin-left: 20px;
}
.lg-linked-text{
  text-decoration: none;
}

.tiger a {
  transition: transform 0.3s ease; /* Add transition property */
}
.tiger ul p a{
    color:white;
    text-decoration: none;
    transition: transform 0.3s ease; /* Add transition property */
    font-family:'Times New Roman', Times, serif;
}
 
.tiger a:hover {
    color: rgb(7, 7, 7);
    transform: scale(1.1);
   
    padding-left:4px;
    border-radius: 3px;
   
    background-color: rgb(243, 241, 241);
}
 
  .tiger-1 {
    font-family: 'Times New Roman', Times, serif;
    color: white;
    font-size: 13px;
    width: 50px;
    height: 20px;
    display: inline-block; /* Added for proper positioning */
    text-decoration: none; /* Remove underline */
  }
 
  .tiger-11{
    font-family:'Times New Roman', Times, serif;
    color:white;
    font-size: 13px;
    width:220px;
 
    height:50px;
  }
  .tiger-11:hover{
    height:30px;
  }
  .tiger-12{
    font-family:'Times New Roman', Times, serif;
    color:white;
     font-size: 13px;
     width:110px;
     height:20px;
  }
  .tiger ul{
    display:flex;
    margin-top: 40px;
    margin-left:50px;
  }
 
 
  .lg-text {
   
    margin-bottom: 200px;
    margin-left: 35px;
    font-size: 13px;
    color: rgb(241, 233, 233);
    font-family: "Times New Roman", Times, serif;
  }
  .lg-logo-10{
    display:block;
  }
  .lg-sign-btn {
    margin-top: 0px;
    display: flex;
    margin-left: 50px;
  }
  .lg-icon{
    font-size: 20px;
    color:white;
    font-weight: 1060;
    margin-left:0px;
    margin-top: 0px;
    text-decoration: none;
    background: none;
  }
  .lg-icons{
    font-size: 20px;
    color:white;
    margin-top: 5px;
    font-weight: 1000;
    text-decoration: none;
    background-color: none;
  }
  .lg-sign-in {
 
    width: 80px;
    height: 30px;
    color:white;
    margin-left: 1px;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 18px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 600;
    background-color: #05144c;
    border: none;
    cursor: pointer;
  }

  .lg-sign-in:active{
    border: none;
    outline: none;
  }

  .lg-signin-login-1:hover{
    transform: scale(1.1);
  }
 
  .lg-yee{
    font-family:'Times New Roman', Times, serif;
    color:white;
    font-size: 13px;
    width:50px;
    height:20px;
 
  }
  .lg-yee:hover{
    color:orange;
  }
  .lg-yee-1{
    font-family:'Times New Roman', Times, serif;
   color:white;
    font-size: 13px;
    width:110px;
    height:20px;
   
  }
  .lg-yee-1:hover{
    color:orange;
  }
  .lg-yee-2{
    font-family:'Times New Roman', Times, serif;
    color:white;
    font-size: 13px;
    width:130px;
    height:20px;
 
  }
  .lg-yee-2:hover{
    color:orange;
  }
 
 
  .lg-latha{
 
  width:90%;
  height:30px;
    /* margin-left:10%; */
    margin-top:0px;
    font-weight: 600;
 
  }
 
 
  @keyframes eyeMove {
    0%, 10% {
      background-position: 0px 8px;
    }
    13%, 40% {
      background-position: -5px 0px;
    }
    43%, 70% {
      background-position: 5px 0px;
    }
    73%, 90% {
      background-position: 0px 8px;
    }
    93%, 100% {
      background-position: 0px 8px;
    }
    }
   
    @keyframes blink {
    0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
      height: 28px;
    }
    11%, 21%, 41%, 61%, 71%, 91%, 99% {
      height: 9px;
    }
    }
 
    @media (320px <= width <= 375px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }

    @media (360px <= width <= 414px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (375px <= width <= 414px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (414px <= width <= 568px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (768px <= width <= 820px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (820px <= width <= 834px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (834px <= width <= 1194px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (800px <= width <= 834px) {
      header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: #012d6a;
        transition: 1s;
      }
    
      header .lg-nav-btn {
        visibility: visible;
        opacity: 1;
        color:white;
      }
    
      header .responsive_nav {
        transform: translateY(100vh);
      }
     
      nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    
      header {
        height: 101px;
        margin-top: -1px;
      }
     
      .tiger ul{
        display: block;
        text-align: center;
        margin-left:0px;
      }
      .lg-yee{
        display:flex;
        font-size:18px;
      }
      .lg-sign-btn{
        /* display: none; */
       margin-left: 10px;;
       
      }
      .lg-sign-in{
        margin-top:5px;
        margin-left: -10px;
        background-color: #012d6a;
      }
    
      header .lg-yaa{
        width:120px;
        height:100px;
        margin-left: -10px;
        margin-top: 20px;
      }
      header .lg-text{
        margin-top: -10px;
      }
    }
    
    @media (884px <= width <= 1024px) {}
 

 
  /* @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap"); */
 
