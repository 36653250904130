.input-2 {
    width: 350px;
    padding: 5px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
  }
  .i-2{
    width: 350px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
    height:40px;

  }
  .sasq{
    font-size: larger;
    background-color: rgba(5, 20, 76,0.5);
  }
  .bgfs{
    /* border:1px solid black; */
    height:auto;
    text-align: center;
    margin-top: 10px;
  }
  .bcx{
    /* border:1px solid black; */
    margin-top: 50px;
  }
  .cfsx{
    border:1px solid white;
    box-shadow:0px 0px 3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:fit-content;
    margin-left:150px;
    margin-top: 50px;
    /* width:70vw; */
    

  }
  
  .table-wrap{
    max-height:400px;
    overflow-y: scroll;
    width: 900px;
    margin:20px;
    overflow-x: auto; /* Enable horizontal scrolling */
}
  .display-2 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 300px;
    padding: 5px;
  }
  .vwq{
    border:1px solid white;
    text-align: center;
    margin-left: 430px;
    padding:20px;
    width:400px;
    box-shadow:0px 0px 3px rgb(213, 208, 208);
    border-radius: 5px;
    margin-top: 50px;
  }
  .display-full2 {
    margin: 5px;
    margin-left: 5px;
  }
  
  .info-23{
      margin-left: 10px;
      margin-top: 5px;
      width:100%;
  }
  .vgt-durat{
    display:flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .cft{
    margin-top: 50px;
    border: none;
    outline: none;
  }

  .cft:hover{
    border: none;
    outline: none;
  }

  .cft:active{
    border: none;
    outline: none;
}

  .button-3 {
    background-color: royalblue;
    width: 150px;
    padding: 10px 0px 10px 0px;
    transition: none; 
    border-radius: 5px;
    margin-left: 150px;/* Remove transition effect */
  }
  
  .button-3:hover {
    /* Remove hover effect */
    background-color: royalblue;
  }

  @media screen and (max-width: 769px){
    .vwq{
      /* border:1px solid black; */
      width:10px;
      margin-left: 10px;
      box-shadow:none;
      display: none;
    }
   
    .cfsx{
      margin-left: 0px;
     }
   
    .gym-2{
      width:220px;
    }
    .table-wrap{
      width:200px;
    }
 
    .i-2{
      width: 200px;
      border: none;
      border-radius: 4px;
      box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
      outline: none;
      color: rgb(0, 0, 0);
      height:40px;
    }
 
  }
  @media screen and (max-width: 1280px){
    .vwq{
      margin-left: 170px;
      width:250px;
      padding-left: 0px;
    }
    .table-wrap{
       width:400px;
       /* margin-left: 0px; */
    }
    .cfsx{
     margin-left: 60px;
    }
    
  
  }
  

  