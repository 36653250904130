.Pay-mm {
  /* border: 1px solid black; */
  width: 100%;
  height: 100vh;
}
.Arrow{
  color: black;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 2px;
}.Pay-vgtdf {
  display: flex;
  flex-direction: column;
  width:345px;
  text-align: center;
  margin-bottom: 5%;
}

.Pay-input {
  width: 300px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: rgb(0, 0, 0);
}
.Pay-mpcdf{
  width: 90px;
  height: 35px;
  border-radius: 5px;
  margin-top: 30px;
  border: none;
  outline: none;
  background-color: #19ab58;
  color: white;
  align-items: center;
  /* transform: scale(1.1); */
}

    .Pay-display-44{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 450px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 60px;
    padding: 5px;
    }
    .Pay-courses-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 20px;
    }
    .Pay-display-002 {
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        width: 350px;
        border-radius: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 420px;
        padding: 5px;
    }
    
    
    
    .bgfs{
    
    text-align: center;
    margin-top: 10px;
    }
    
    .table-wrap{
    max-height:400px;
    overflow-y: scroll;
    width: 1240px;
    margin:20px;
    overflow-x: auto;
    }
    
    .tables-style{}
    
    .sasq{
    font-size: larger;
    background-color: rgba(5, 20, 76,0.5);
    }
    
    @media only screen and (max-width: 677px) {
        .Pay-display-44,
        .Pay-display-002 {
          width: 100%;
          margin-left: 0;
        }
      
        .image {
          display: block;
        }
      }
      @media only screen and (max-width: 800px) {
        .Pay-display-44,
        .Pay-display-002 {
          width: 100%;
          margin-left: 0;
        }
      
        .image {
          display: block;
        }
      }
      @media only screen and (max-width: 820px) {
        .Pay-display-44,
        .Pay-display-002 {
          width: 100%;
          margin-left: 0;
        }
      
        .image {
          display: block;
        }
      }
      @media only screen and (max-width: 1024px) {
        .Pay-display-44,
        .Pay-display-002 {
          width: 100%;
          margin-left: 0;
        }
      
        .image {
          display: block;
        }
      }
      @media only screen and (max-width: 360px) {
        .book-info-23 {
       margin-right: 50%;
            width: 100%;
           
        }
        .book-pure{
            width: 100%;
        }
    }
    @media only screen and (max-width: 360px) {
      .fs-table{
        width: 250px;
        
        margin-right: -3%;
       
    }
    }
    @media only screen and (max-width: 712px) {
      .fs-table{
        width: 550px;
        margin-left:20%;
        margin-top: 40px;
      }
    }
      @media only screen and (max-width: 412px) {
        .fs-table{
          width: 300px;
         
      }
      
    }
    @media only screen and (max-width: 600px) {
      .fs-table{
        width: 550px;
        margin-left:15%;
        margin-top: 40px;
      }
    
    }
    .p-table-auction{
      max-height:400px;
      overflow-y: scroll;
      width: 980px;
      /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
      box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
      /* border: 1px solid black; */
      margin:20px;
      margin-top: 50px;
      margin-left: 150px;
      overflow-x: auto;
      border: none;
      border-collapse: collapse;
  
  }
  th,td{
      border-collapse: collapse;
      /* border:1px solid rgba(82, 80, 80.0.5); */
      padding:10px;
  }
  
  .p-table-auction th {
      border-collapse: collapse;
      border: none;
  }
  
  .p-table-auction td{
      border-collapse: collapse;
  }
  
  .p-tables-style {
      border-collapse: collapse;
      border: none;
    }
    @media (300px <= width <= 600px){
      
      .fs-table{
          width: 550px;
          margin:20px;
          margin-top: 40px;
          margin-left: 15px;
      }
  }
  
  @media (768px <= width <= 1024px){
      
      .fs-table{
          width: 650px;
          margin:20px;
          margin-top: 50px;
          margin-left: 30px;
      }
      .Pay-input{
        width: 400px;
        align-items: center;
        
      }
      .Pay-mpcdf{
        
        margin-left: 25%;
      }
  }
    
  
  @media only screen and (max-width: 360px) {
    .fs-table{
      width: 250px;
      margin:20px;
      margin-top: 40px;
      margin-left: 15px;
  }
  }
  @media only screen and (max-width: 712px) {
    .fs-table{
      width: 300px;
      margin:20px;
      margin-top: 40px;
    }
  }
    @media only screen and (max-width: 412px) {
      .fs-table{
        width: 330px;
        margin:20px;
        margin-top: 40px;
      }
    
  }
  @media only screen and (max-width: 600px) {
    .fs-table{
      width: 550px;
      margin:20px;
      margin-top: 40px;
    }
  
  }
  @media (min-width: 300px) and (max-width: 600px) {
    .Pay-mm {
        width: 100%;
        overflow-x: hidden;
        /* overflow: hidden; */
    }
}