.collectingagent-doup{
  width: 100%;
  height: 100vh;
  display: flex;
}

.CA-doup-f{
  width:100%;
  height: 100vh;
  /* overflow-y: hidden; */

  flex-grow: 1;
  padding: 10px;
  /* overflow-y: auto; */
  /* transition: all 0.3s ease; */
  /* width: calc(100% - 250px);  */
}

.CA-doup-heading h2{
  font-size: 30px;
}

.CA-table-doup{
  max-height:400px;
  overflow-y: scroll;
  width: 980px;
  /* box-shadow:3px 0px 10px 3px rgb(213, 208, 208); */
  box-shadow:3px 0px 10px 3px rgb(244, 239, 239);
  /* border: 1px solid black; */
  margin:20px;
  margin-top: 50px;
  margin-left: 150px;
  overflow-x: auto;
  border: none;
  border-collapse: collapse;
}

.CA-outer-pay{
  border:1px solid white;
  box-shadow:0px 0px 3px rgb(213, 208, 208);
  border-radius: 5px;
  max-width:fit-content;
  margin-top: 100px;
  margin-left:280px;
}

.CA-table-pay{
  max-height:400px;
  overflow-y: scroll;
  width: 540px;
  margin:20px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Responsive */
@media (320px <= width <= 375px) {
  .CA-doup-heading h2{
    font-size: 20px;
  }

  .CA-outer-pay{
    margin-left: 0px;
    max-width: none;
    margin-top: 40px;
    width: 250px;
    /* border:1px solid black; */
  }

  .CA-table-pay{
    max-width: 210px;
  }
}

@media (360px <= width <= 414px){
  .CA-doup-heading h2{
    font-size: 20px;
  }

  .CA-outer-pay{
    margin-left: 0px;
    max-width: none;
    margin-top: 40px;
    width: 300px;
    /* border:1px solid black; */
  }

  .CA-table-pay{
    max-width: 260px;
  }
}

@media (390px <= width <= 414px) {
  .CA-doup-heading h2{
    font-size: 20px;
  }

  .CA-outer-pay{
    margin-left: 0px;
    max-width: none;
    margin-top: 40px;
    width: 320px;
    /* border:1px solid black; */
  }

  .CA-table-pay{
    max-width: 280px;
  }
}

@media (414px <= width <= 568px){
  .CA-doup-heading h2{
    font-size: 20px;
  }

  .CA-outer-pay{
    margin-left: 0px;
    max-width: none;
    margin-top: 40px;
    width: 340px;
    /* border:1px solid black; */
  }

  .CA-table-pay{
    max-width: 300px;
  }
}

@media (300px <= width <= 600px) {
  .CA-outer-pay{
    margin-left: 0px;
    max-width: none;
    /* width: 310px; */
    /* border:1px solid black; */
  }

  .CA-table-pay{
    /* max-width: 270px; */
  }
}







.table-pay{
    max-height:400px;
    overflow-y: scroll;
    width: 540px;
    margin:20px;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  .outer-pay{
    border:1px solid white;
    box-shadow:0px 0px 3px rgb(213, 208, 208);
    border-radius: 5px;
    max-width:fit-content;
    margin-top: 100px;
    margin-left:280px;
    
  }

  .cadash-heading h2{
    font-size: 30px;
    font-weight: 500;
    margin-top: 20px;
  }

  @media screen and (max-width: 769px) {
    .outer-pay{
        margin-left: 0px;
        max-width: none;
        width: 300px;
        /* border:1px solid black; */
      }
      .table-pay{
         /* border:1px solid black; */
         max-width: 240px;
    
      }

      .detailuserpayment{
        overflow: hidden;
      }

      .cadash-heading h2{
        font-size: 20px;
        margin-top: 20px;
      }
  }

  @media screen and (max-width: 1280px) {

    .detailuserpayment{
        overflow: hidden;
      }
      
      .outer-pay{
        
        margin-left:50px;
        
      }
  }