
.agent-container {
  display: flex;
  height: 100vh;
  
}



.sidebar.closed {
  width: 0;
  overflow: hidden;
}

.Ag-userprofile-p {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  transition: all 0.3s ease;
  width: calc(100% - 250px); /* Adjusted width when sidebar is open */
}

.Ag-userprofile-p.sidebar-closed {
  width: 100%; /* Full width when sidebar is closed */
}

.Ag-courses {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.Ag-courses-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Ag-cards-1 {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.Ag-card-red, .Ag-card-blue, .Ag-card-green, .Ag-card-purple {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.Ag-card-red { background-color: red; }
.Ag-card-blue { background-color: blue; }
.Ag-card-green { background-color: green; }
.Ag-card-purple { background-color: purple; }

.Ag-second-part {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.Ag-chartdata {
  flex: 1;
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 10px;
}
/* Responsive styles */
@media (max-width: 1024px) {
 
  
  
  
}



@media (max-width: 480px) {
 

 
  .Ag-userprofile-p {
    width: 100%;
  }

  .Ag-courses {
    padding: 10px;
  }

  .Ag-card-red, .Ag-card-blue, .Ag-card-green, .Ag-card-purple {
    padding: 10px;
    flex: 1 1 100%;
  }

  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left:-10px;
  }
}
/* Base styles remain the same */

.Ag-chartdata {
  flex: 1;
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}



@media (max-width: 360px) {
  .Ag-userprofile-p {
    width: calc(100% - 200px);
  }
  .Ag-dash-heading h2{
    font-size: smaller;
  

  }
.ag-h4{
  font-size: smaller;
  text-align: center;
}
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left: 0; /* Adjusted from -10px to 0 */
    overflow-x: hidden;
  }

  .Ag-chartdata {
    width: 100%; /* Ensure charts take full width on smaller screens */
    margin-bottom: 20px; /* Adjust margin for consistency */
  }

  .Ag-container-chart {
    width: 100%; /* Ensure chart containers take full width */
    height: 350px; /* Set a fixed height for consistency, adjust as needed */
    /* margin-right: 10%; */
  }
}

@media (max-width: 480px) {
  .Ag-userprofile-p {
    width: 100%;
  }

  .Ag-courses {
    padding: 10px;
  }

  .Ag-card-red,
  .Ag-card-blue,
  .Ag-card-green,
  .Ag-card-purple {
    padding: 10px;
    flex: 1 1 100%;
  }

  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left: -30%; /* Adjusted from -10px to 0 */

  }

  .Ag-chartdata {
    width: 100%; /* Ensure charts take full width on smaller screens */
    margin-bottom: 20px; /* Adjust margin for consistency */
  }

  .Ag-container-chart {
    width: 100%; /* Ensure chart containers take full width */
    height: 350px; /* Set a fixed height for consistency, adjust as needed */
  }
}
@media (max-width: 370px) {
  
.ag-h4{
  font-size: smaller;
  text-align: center;
}
}
@media only screen and (max-width: 390px) {
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left: -30%; 

  }
  .ag-h4{
    font-size: smaller;
    text-align: center;
  }
}
@media only screen and (max-width: 414px) {
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left: -29%; 

  }
  
}
@media only screen and (max-width: 393px) {
  
  .Ag-dash-heading h2{
    font-size: 18px;
  

  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left:-24%; 

  }
}
@media only screen and (max-width:412px) {
  
  .Ag-dash-heading h2{
    font-size: 18px;
  

  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left:-24%; 

  }
}

@media only screen and (max-width:414px) {
  
  .Ag-dash-heading h2{
    font-size: smaller;
  
  
  }
  .ag-h4{
    font-size: smaller;
    text-align: center;
  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left:-24%; 

  }
}

@media only screen and (max-width:428px) {
  
  .Ag-dash-heading h2{
    font-size: smaller;
  
  
  }
  .ag-h4{
    font-size: smaller;
    text-align: center;
  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    margin-left:-24%; 

  }
}
/* @media (max-width: 820px) {
  .Ag-userprofile-p {
    width: calc(100% - 150px);
  }
  
  .Ag-userprofile-p.sidebar-closed {
    width: 100%;
  }
  
  .Ag-cards-1 {
    flex-direction: column;
    gap: 10px;
    width: 300px;
    align-items: center;
    
  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}



@media (max-width: 834px) {
  .Ag-userprofile-p {
    width: calc(100% - 150px);
  }
  
  .Ag-userprofile-p.sidebar-closed {
    width: 100%;
  }
  
  .Ag-cards-1 {
    flex-direction: column;
    gap: 10px;
    width: 300px;
    align-items: center;
    
  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}


@media (max-width: 820px) {
  .Ag-userprofile-p {
    width: calc(100% - 150px);
  }
  
  .Ag-userprofile-p.sidebar-closed {
    width: 100%;
  }
  
  .Ag-cards-1 {
    flex-direction: column;
    gap: 10px;
    width: 300px;
    align-items: center;
    
  }
  .Ag-second-part {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}



 */

 @media (max-width: 900px) {
  .Ag-cards-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;
   
  }
 
 
 
}



