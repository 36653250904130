.collectingagent-profile {
    width: 100%;
    height: 100vh;
    display: flex;
}

.CA-userprofile-f{
    width:100%;
    height: 100vh;
    /* overflow-y: hidden; */
  
    flex-grow: 1;
    padding: 10px;
}

.CA-profile-heading h2{
    font-size: 30px;
}

.AD-TEXT{
    /* border: 1px solid black; */
    margin-left: 930px;
    font-weight: 500;
    /* border: 1px solid black; */
}

.AD-edit{
    text-decoration: underline;
    font-weight: 500;
}

.CA-profile-full{
    width: 100vh;
    /* margin-left: -30px; */
}

.CA-profile-001{
    display: flex;
    flex-direction: column;
    width: 100px;
    border-radius: 10px;
    margin-left: 60px;
    margin-top: -10px;
}

.CA-pro{
    display: flex;
}

.CA-display-pro{
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 550px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 80px;
    padding: 5px;
}

.CA-input-pro{
    width: 300px;
    padding: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
    outline: none;
    color: rgb(0, 0, 0);
}

/* Responsive */

@media (320px <= width <= 375px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 0px;
    }

    .CA-input-pro{
        width: 230px;
    }

    .CA-profile-001{
        margin-left: 10px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 10px;
        font-weight: 500;
    }
}

@media (360px <= width <= 414px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 30px;
    }

    .CA-input-pro{
        width: 230px;
    }

    .CA-profile-001{
        margin-left: 40px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 60px;
        font-weight: 500;
    }
}

@media (375px <= width <= 414px){
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 30px;
    }

    .CA-input-pro{
        width: 230px;
    }

    .CA-profile-001{
        margin-left: 40px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 70px;
        font-weight: 500;
    }
}

@media (414px <= width <= 568px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 60px;
    }

    .CA-input-pro{
        width: 230px;
    }

    .CA-profile-001{
        margin-left: 60px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 100px;
        font-weight: 500;
    }
}

@media (768px <= width <= 820px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 200px;
    }

    .CA-input-pro{
        width: 310px;
    }

    .CA-profile-001{
        margin-left: 60px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 450px;
        font-weight: 500;
    }
}

@media (820px <= width <= 834px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 150px;
    }

    .CA-input-pro{
        width: 310px;
    }

    .CA-profile-001{
        margin-left: -10px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 350px;
        font-weight: 500;
    }
}

@media (834px <= width <= 1194px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 150px;
    }

    .CA-input-pro{
        width: 310px;
    }

    .CA-profile-001{
        margin-left: -10px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 350px;
        font-weight: 500;
    }
}

@media (800px <= width <= 834px) {
    .CA-profile-heading h2{
        font-size: 20px;
    }

    .CA-userprofile-f{
        overflow-x: hidden;
    }

    .CA-pro{
        display: block;
    }

    .CA-display-pro{
        margin-top: 10px;
        margin-left: 150px;
    }

    .CA-input-pro{
        width: 310px;
    }

    .CA-profile-001{
        margin-left: -10px;
    }

    .AD-TEXT{
        /* border: 1px solid black; */
        margin-left: 350px;
        font-weight: 500;
    }
}